import { ContactReportContent, ContactReportType } from '../types'
import React from 'react'
import { RenderAnomaly } from '../../../Anomalies/Anomaly/RenderAnomaly'

/***
 * Metodo Render che mostra i campi reported ma senza segnalare anomalia
 * @param obj
 * @param element
 * @param fieldKeyGroup
 * @param defaultRender
 * @constructor
 */
export function RenderCompleteUpdates(
  obj: ContactReportType,
  element: ContactReportContent,
  fieldKeyGroup: string,
  defaultRender?: (obj: ContactReportType, element: ContactReportContent) => React.ReactNode
) {
  const renderFn = defaultRender ? defaultRender(obj, element.defaultValue) : element.defaultValue

  if (element.update) {
    return <RenderAnomaly value={renderFn} field={fieldKeyGroup as string} />
  }
  return <>{renderFn}</>
}
