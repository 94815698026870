import { IRequestExpiring } from '@nic/nic-api/types/Darwin/types'
import { Darwin } from '@nic/nic-api/types'

export { formatDate } from './DateUtils'
export { hasRole } from './AuthUtils'

export function isIRequestExpiring(
  value: IRequestExpiring | Darwin.Request
): value is IRequestExpiring {
  const typecheck = value as IRequestExpiring
  return typecheck?.expirationDays !== undefined
}
