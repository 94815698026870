import { useContext } from 'react'
import { RequestContext } from '../Contexts/RequestContext'

/***
 * Ritorna le informazioni delle richiesta se già caricata
 */
export function useRequestInfo() {
  const {
    stateReqById: { data: request },
  } = useContext(RequestContext)
  const hasExpirationDays =
    request?.status && ['pending', 'registrarUpdated'].includes(request?.status as string)

  return { hasExpirationDays, request }
}
