import { Darwin } from '@nic/nic-api/types'
import { Divider } from 'antd'
import React from 'react'
import { PrintAnomalyList } from '../PrintAnomalyList'
import { isArray } from 'lodash'
import { PrintCustomUnorderedList } from '@nic/ui-antd'

/**
 * Stampa il valore dell'anomalia modificata
 * @param anomaly
 * @param initialValue
 * @param t
 * @constructor
 */
export function PrintEditedAnomalyDescriptions(
  anomaly?: Darwin.Anomaly,
  initialValue?: string,
  t?: any
) {
  if (anomaly && anomaly.codes)
    return (
      <>
        {PrintAnomalyList(anomaly.codes, t)}
        <Divider />
        <b>{t('initial')}:</b>
        <br />
        {isArray(initialValue) ? PrintCustomUnorderedList(initialValue) : initialValue}
      </>
    )
  return (
    <>
      <b>{t('initial')}:</b>
      <br />
      {initialValue}
    </>
  )
}
