import React from 'react'
import { HorizontalLayout, VerticalLayout } from '@nic/ui-comps'
import { RegistryProfileProps } from '../RegistryProfile/RegistryProfile'
import { RequestTabType } from '../../../../index'
import { RegistrarRegistrantCard } from '../../../../../Cards/RegistrarRegistrantCard/RegistrarRegistrantCard'
import { ResourceSummaryRequestCard } from '../../../../../Cards/ResourceSummaryRequestCard'
import { CircolarValidityRequestCard } from '../../../../../Cards/ValidityRequestCard'

export type RegistrarProfileProps = RegistryProfileProps & {
  onChangeTab?: (index: RequestTabType) => void
}
/***
 *  * Visualizzazione nel caso di profilo Registrar
 * @param isRegistrarUpdatedStatus
 * @param onChangeTab
 * @param hasExpirationDays
 * @constructor
 */

export const RegistrarProfile: React.FC<RegistrarProfileProps> = ({
  isRegistrarUpdatedStatus,
  onChangeTab,
  hasExpirationDays,
}) => (
  <HorizontalLayout
    style={{
      alignItems: 'stretch',
      justifyContent: 'space-between',
      padding: '0 10px 0 10px',
      gap: 10,
    }}
  >
    <RegistrarRegistrantCard />
    <VerticalLayout
      style={{
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        width: '450px',
      }}
    >
      <ResourceSummaryRequestCard onSelectCounter={onChangeTab} />
      {hasExpirationDays && <CircolarValidityRequestCard paused={isRegistrarUpdatedStatus} />}
    </VerticalLayout>
  </HorizontalLayout>
)
