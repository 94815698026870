import React, { Dispatch, useContext } from "react";
import { PaperLayout } from "@nic/ui-comps";
import { PaperTitle } from "../../../../../Layout/PaperTitle";
import { DocumentUpload, DocumentUploadFile } from "@nic/ui-antd";
import {
  buildDarwinDocumentUploadFileDataNode,
  openFile,
} from "../../../../../Documents/utils";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  DarwinHelpers,
  putProxiedDocument,
  RequestDocumentDeleteService,
} from "@nic/nic-api";
import { Alert, message } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import { VariationContext } from "../../../../../Providers/VariationProvider";
import { RequestContext } from "../../../../../Contexts/RequestContext";
import { useTranslation } from "@nic/commons";
import { Darwin, NicScope } from "@nic/nic-api/types";

/**
 * Riferimenti - https://stackoverflow.com/questions/58128062/using-customrequest-in-ant-design-file-upload
 * @constructor
 */
export function DocumentStep() {
  /*****
   * TODO - cose da fare
   *
   * - Migliorare il componente, semplificare, togliere sporcizia
   * - Portare la logica di gestione Providers e quant'altro fuori dove ci stanno le altre degli altri step cosi da avere un solo punto di gestione
   *
   *
   */

  const t = useTranslation();
  const {
    dispatch,
    state: { documents, hasRequiredDocs },
  } = useContext(VariationContext);

  const { stateReqById, scope, dispatchReqDocuments, stateReqDocuments } =
    useContext(RequestContext);

  const requestId = stateReqById.data?.id as number;

  const handlingOnUploadProgress = (
    event: any,
    onProgress?: (event: any) => void,
    onSuccess?: (body: any, xhr?: XMLHttpRequest) => void
  ) => {
    // const percent = Math.floor((event.loaded / event.total) * 100)

    //setProgress(percent)
    /*
            if (percent === 100) {
              setTimeout(() => setProgress(0), 1000)
            }
            */

    // console.log('onProgress')
    onProgress && onProgress({ percent: (event.loaded / event.total) * 100 });
    onSuccess &&
      onSuccess((a: any) => {
        // console.log('Ehi onSuccess', a)
        DarwinHelpers.documents(
          dispatchReqDocuments,
          { requestId: requestId },
          scope as NicScope
        );
      });
  };
  //console.log('Progress: ', progress, ' defaultFileList: ', defaultFileList)

  const handleOnChange = (uplProps: UploadChangeParam<any>) => {
    // console.log('handleOnChange uploadListFiles', uplProps)
    //Using Hooks to update the state to the current filelist
    // setDefaultFileList(uplProps.fileList)
    //filelist - [{uid: "-1",url:'Some url to file'}]
  };

  const handleOnRemoveDocument = (
    file: UploadFile<Darwin.DarwinUploadedFileResponse>
  ) => {
    // console.log('onRemove', file)
    if (file.status === "done") {
      RequestDocumentDeleteService(file.uid, scope as NicScope)
        .then((resp) => {
          // console.log('Deleted', resp)

          file.response &&
            message.success(
              t("documentStep.docSuccessfullyRemoved", {
                docId: file.response.id,
              })
            );

          // Ricarica il contesto con la lista documenti aggiornata
          DarwinHelpers.documents(
            dispatchReqDocuments,
            { requestId: requestId },
            scope as NicScope
          );
          // loadDocument(stateReqDocuments, stateReqById, dispatch)
        })
        .catch((e) => {
          message.error(e.response.data.message);
          // alert(JSON.stringify(e))
          console.error(JSON.stringify(e));
        });
    } else {
      DarwinHelpers.documents(
        dispatchReqDocuments,
        { requestId: requestId },
        scope as NicScope
      );
    }
  };

  console.log(
    "buildDarwinDocumentUploadFileDataNode",
    buildDarwinDocumentUploadFileDataNode(
      t,
      stateReqDocuments.data?.elements || []
    )
  );
  // console.log('docs', documents)

  return (
    <PaperLayout>
      <PaperTitle showIcon title={"Documenti Richiesti"} />
      <div style={{ marginBottom: "10px" }}>
        {hasRequiredDocs ? (
          <Alert
            description={t("documentStep.docsReached")}
            type="success"
            showIcon
          />
        ) : (
          <Alert
            description={t("documentStep.docsNotReached")}
            type="warning"
            showIcon
          />
        )}
      </div>
      <DocumentUpload
        docs={documents}
        onRemove={handleOnRemoveDocument}
        renderUploadButtonLabel={(docType) => (
          <>
            {t("uploadDocument")} - <b>{`${t(docType)}`}</b>
          </>
        )}
        customRequest={(opt, note, type) =>
          uploadDocument(
            dispatch,
            opt,
            requestId,
            note,
            type,
            handlingOnUploadProgress,
            (a: any) => {
              // Logica OnSucceedUplaod
              // console.log('Ehi onSuccess', a)
              DarwinHelpers.documents(
                dispatchReqDocuments,
                { requestId: requestId },
                scope as NicScope
              );
            }
          )
        }
        onPreview={(file) => {
          // console.log('FFF', file)
          openFile(
            "registrar",
            file.response.id,
            file.type as "pdf" | "png",
            file.fileName
          );
        }}
        renderUploadLabel={(docType) => (
          <>
            <b>{`${t(docType)}`}</b>
          </>
        )}
        uploadProps={{
          accept: ".pdf,.jpg,.jpeg",
          // customRequest: (options) =>
          //   uploadDocument(dispatch, options, requestCtx.id, '', handlingOnUploadProgress),
          onChange: handleOnChange,
        }}
      />
    </PaperLayout>
  );
}

const handlingAxiosError = (error: any) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.debug("Error", error.message);
  }
  console.log(error.config);
};

export interface UploadRequestOption<T> {
  onProgress?: (event: any) => void;
  onError?: (event: any | ProgressEvent, body?: T) => void;
  onSuccess?: (body: T, xhr?: XMLHttpRequest) => void;
  data?: Record<string, unknown>;
  filename?: string;
  file: Exclude<any, File | boolean> | RcFile;
  withCredentials?: boolean;
  action: string;
  headers?: any;
  method: any;
}

const uploadDocument = async (
  dispatch: Dispatch<any>,
  options: UploadRequestOption<Darwin.DarwinUploadedFileResponse>,
  id: number,
  note?: string,
  docType?: Darwin.DocType,
  onUploadProgress?: (
    event: any,
    onProgress?: (event: any) => void,
    onSuccess?: (body: any, xhr?: XMLHttpRequest) => void
  ) => void,
  // TODO aggiunto per celerità perchè onUploadProgress.onSuccess non funziona e va capito perchè
  // rimuovere il prima possibile
  onSucceedUpload?: (body: any, xhr?: XMLHttpRequest) => void
) => {
  const { onSuccess, onError, file, onProgress } = options;

  const documentData: Darwin.DarwinUploadedFileResponse = {
    requestId: id,
    documentType: docType,
    note: note,
  };
  const document = new Blob([JSON.stringify(documentData)], {
    type: "application/json",
  });

  const fmData = new FormData();

  fmData.append("file", file);
  fmData.append("document", document, "document.json");

  const config: AxiosRequestConfig = {
    headers: { "content-type": "multipart/form-data" },
    onUploadProgress: (event: any) =>
      onUploadProgress && onUploadProgress(event, onProgress, onSuccess),
  };

  // console.log('fmtData', fmData.values())
  console.debug("file", file.data);
  console.debug("document", JSON.stringify(document));
  console.debug("uploadDocument, Options passate", options);
  console.debug("documentData", documentData);

  putProxiedDocument(fmData, config)
    .then((res: AxiosResponse<Darwin.DarwinUploadedFileResponse>) => {
      dispatch({
        type: "DOCUMENT_UPLOAD_SUCCEED",
        payload: {
          docType: docType,
          fileList: [
            {
              uid: res.data.id,
              size: res.data.fileSize,
              name: `${res.data.fileName} ${
                res.data.note !== undefined ? " - " + res.data.note : ""
              }`,
              fileName: res.data.fileName,
              lastModifiedDate: res.data.created,
              url: "http://#",
              status: "done",
              response: res.data,
              linkProps: { target: "_blank", rel: "noopener noreferrer" },
              type: res.data.fileType,
            },
          ],
        } as DocumentUploadFile<Darwin.DarwinUploadedFileResponse>,
      });
      onSuccess && onSuccess(res.data);
      onSucceedUpload && onSucceedUpload(res.data);
    })
    .catch((err) => {
      handlingAxiosError(err);
      console.error("eeee", JSON.stringify(err));
      dispatch({
        type: "DOCUMENT_UPLOAD_FAILED",
        payload: {
          docType: docType,
          fileList: [
            {
              error: err.response.data,
              status: "error",
              fileName: file.fileName,
              name: file.name,
              // @ts-ignore
              uid: `${docType}-${crypto.randomUUID()}`,
              // name: res.data.fileName,
              // fileName: res.data.fileName,
              // lastModifiedDate: res.data.created,
              // // url : string,
              // status: 'done',
              // response: res,
              // // error : any,
              // type: res.data.fileType,
            },
          ],
        } as DocumentUploadFile<Darwin.DarwinUploadedFileResponse>,
      });
      message.error(
        `${err.response.status} - ${JSON.stringify(err.response.data)}`
      );
      onError && onError(null, err);
    });
};
