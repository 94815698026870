import { RequestToAssignToUserService } from '@nic/nic-api'
import { NicScope } from '@nic/nic-api/types'
import React, { useCallback } from 'react'
import { printAxiosError } from '../../../Utils/common'
import { notification } from 'antd'
import { PrintSuccesMsg } from '../../Feedback/PrintSuccesMsg'

export function useAssignRequestToUser() {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<any>()
  const [response, setResponse] = React.useState<any>()
  const handleAssignRequestToUser = useCallback(
    async (requestId: number, userId: number, scope: NicScope) => {
      setLoading(true)
      // your api request logic in here, bellow only show example
      try {
        const { data, status } = await RequestToAssignToUserService(requestId, scope, userId)
        if (data && status === 200) {
          setResponse(data)
          notification.success({
            message: PrintSuccesMsg('Richiesta assegnata ad un nuovo utente'),
            placement: 'top',
          })
        }
      } catch (error) {
        notification.error({ message: printAxiosError(error), placement: 'top' })
        setError(error)
      }
      setLoading(false)
    },
    []
  )

  return { loading, response, error, handleAssignRequestToUser }
}
