import React from 'react'
import { useHistory } from 'react-router'
import { AuthContext } from '@nic/kc-auth'

export const RealmSwitcher: React.FC = () => {
  const { keycloak } = React.useContext(AuthContext)
  const history = useHistory()

  React.useEffect(() => {
    if (keycloak !== undefined) {
      const { realm } = keycloak
      history.push(`${realm}/dashboard`)
    }
  }, [history, keycloak])

  return <></>
}
