import React, { useMemo } from 'react'
import {
  DarwinHelpers,
  IGetRequestsParams,
  requestsCountInitialState,
  requestsCountReducer,
} from '@nic/nic-api'
import { Darwin, NicScope } from '@nic/nic-api/types'
import { IAssignmentParam } from './RequestCounter'

export function useRequestCounter(
  scope: NicScope,
  status?: Darwin.RequestStatus,
  assignmentView?: IAssignmentParam,
  registrarId?: number
) {
  const [stateCountReq, dispatchCountReq] = React.useReducer(
    requestsCountReducer,
    requestsCountInitialState
  )

  const params = useMemo(() => {
    const _params: IGetRequestsParams = { status: status, registrarId: registrarId }
    if (assignmentView?.param) {
      return { ..._params, [assignmentView.param]: assignmentView.value }
    }
    return _params
  }, [registrarId, status, assignmentView?.value, assignmentView?.param])
  // console.log('Counter params:', JSON.stringify(params))
  React.useEffect(() => {
    DarwinHelpers.requestsCount(dispatchCountReq, params, scope)
  }, [params, status, registrarId, scope])

  return stateCountReq
}
