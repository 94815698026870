import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from '@nic/commons'
import { Darwin } from '../../../../nic-react-monorepo/packages/nic-api/types'

export type DarwinRequestStatus = Darwin.RequestStatus & 'all'
const StatusColor: Record<DarwinRequestStatus, string> = {
  processingCancelled: 'magenta',
  all: 'blue',
  completed: 'green',
  pending: 'cyan',
  processing: 'red',
  processingExpired: 'red',
  registrarUpdated: 'orange',
  rejected: 'red',
  toBeReworked: 'red',
  submitted: 'blue',
  // expiring: "magenta",
  expired: 'volcano',
  cancelled: 'red',
}

interface StatusViewI {
  status: Darwin.RequestStatus | 'toBeReworked'
  plural?: boolean
  icon?: React.ReactNode
  link?: boolean
}

const StatusView: React.FC<StatusViewI> = (props) => {
  const { status } = props
  const t = useTranslation()
  return (
    <Tag color={`${StatusColor[status as DarwinRequestStatus]}`}>
      {t(props.plural ? `requestsStatus.${status}` : `status.${status}`)}
      {props.icon && <span style={{ marginLeft: 5 }}>{props.icon}</span>}
    </Tag>
  )
}
export default StatusView
