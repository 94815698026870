import React from 'react'
import { Button } from 'antd'
import { Div, HorizontalLayout } from '@nic/ui-comps'

interface RealmDashboardI {}

export const RealmDashboard: React.FC<RealmDashboardI> = () => {
  return (
    <Div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 500,
      }}
    >
      <Div textAlign={'center'} marginBottom={'30px'}>
        <h1>Scegliere il profilo con cui entrare</h1>
        <h3>Solo a fini di sviluppo</h3>
      </Div>
      <HorizontalLayout style={{ gap: 20, alignItems: 'stretch' }}>
        <Button
          style={{ background: '#ffd600' }}
          onClick={() => {
            window.location.replace('/registrar/dashboard')
          }}
        >
          Registrar
        </Button>

        <Button
          type={'primary'}
          onClick={() => {
            window.location.replace('/registry/dashboard')
          }}
        >
          Registry
        </Button>
      </HorizontalLayout>
    </Div>
  )
}
