import { JBillingLink, Registrar } from '@nic/ui-antd'
import { NicScope } from '@nic/nic-api/types'
import { useTranslation } from '@nic/commons'
import { getWindowRunConfig } from '../../darwinConfig'

export function RegistrarJBillingLinK(props: { registrarId: number; scope?: NicScope }) {
  const t = useTranslation()

  return (
    <Registrar
      id={props.registrarId}
      render={(data) =>
        props.scope === 'registry' ? (
          <JBillingLink
            contractId={data.contractId}
            description={
              <>
                {data.name} ( {data.id} ){' '}
              </>
            }
            host={getWindowRunConfig().JBILLING_HOST}
            tag
            tooltip={t('registryJBillingLink.tooltip', { name: data.name })}
          />
        ) : (
          data.name
        )
      }
      scope={props.scope}
    />
  )
}
