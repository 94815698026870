import { addDays, subDays } from '@nic/commons'

export function useRequestTimeline(
  creationDate: string | undefined,
  duration: number,
  remainingDays: number | undefined
) {
  const percentage =
    remainingDays && remainingDays > 0 ? ((duration - remainingDays) / duration) * 100 : 100
  const now = new Date()

  function calEndDate() {
    if (remainingDays) {
      return remainingDays >= 0
        ? addDays(new Date(), remainingDays)
        : subDays(new Date(), Math.abs(remainingDays))
    } else {
      return addDays(new Date(), duration)
    }
  }

  return { endDate: calEndDate(), remainingDays, percentage, now }
}
