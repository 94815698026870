import React, { useState } from 'react'
import { ReadsValidationStatus } from '@nic/nic-api/types/Darwin/types'
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  HourglassOutlined,
} from '@ant-design/icons'
import { Badge } from 'antd'
import { NicScope } from '@nic/nic-api/types'
import { camelToDash, useTranslation } from '@nic/commons'
import { ModalReadsValidationStatus } from './ModalReadsValidationStatus'

/***
 * Indica lo stato di validazione dei dati aggiornati del contatto
 * @param children
 * @param status
 * @param scope
 * @param requestId
 * @constructor
 */
export const ReadsValidationStatusBadge: React.FC<{
  status?: ReadsValidationStatus
  scope: NicScope
  requestId: number
}> = ({ children, status, requestId, scope }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const t = useTranslation()
  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const color = () => {
    switch (status) {
      case 'succeeded':
        return '#008001'
      case 'failed':
        // return isHover ? '#f7797d' : 'red'
        return 'orange'
      default:
        return 'grey'
    }
  }

  const text = () => {
    switch (status) {
      case 'succeeded':
        return t('contactUpdateValidation.succeeded')
      case 'failed':
        return t('contactUpdateValidation.failed')
      default:
        return t('contactUpdateValidation.pending')
    }
  }

  const icon = () => {
    switch (status) {
      case 'succeeded':
        return <CheckCircleOutlined />
      case 'failed':
        return <ExclamationCircleOutlined />
      default:
        return <HourglassOutlined />
    }
  }

  const handleOnClick = () => {
    status === 'failed' && showModal()
  }

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <div className={camelToDash(ReadsValidationStatusBadge.name)}>
      <Badge.Ribbon
        text={
          <div>
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleOnClick}
              style={{
                cursor: status === 'failed' ? 'pointer' : '',
                textDecoration: status === 'failed' ? 'underline' : '',
                color: isHover && status === 'failed' ? 'darkRed' : '',
                fontSize: '.8rem',
                fontWeight: '700',
              }}
            >
              {text()} {icon()}
            </div>
          </div>
        }
        color={color()}
      >
        {children}
      </Badge.Ribbon>
      {scope && requestId && (
        <ModalReadsValidationStatus
          scope={scope}
          requestId={requestId}
          width={800}
          title={t('contactUpdateValidation.modalTitle')}
          visible={isModalOpen}
          onOk={handleOk}
          cancelButtonProps={{ style: { display: 'none' } }}
        />
      )}
    </div>
  )
}
