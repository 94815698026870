import React, { useContext } from 'react'
import App from './App'
import { RealmDashboard } from './Components/RealmSwitcher/RealmDashboard'
import { LanguageContext } from '@nic/commons/build/i18n/LanguageContext'
import { setAllAxiosInstancesAcceptLanguageHeader } from '@nic/nic-api'

interface MainI {}

export const Main: React.FC<MainI> = () => {
  //const [conf, setConf] = React.useState<string>('/registry/keycloak.json')
  const [comp, setComp] = React.useState(<></>)
  const { pathname } = window.location
  const lang = useContext(LanguageContext)
  React.useEffect(() => {
    const isRegistry = window.location.pathname.startsWith('/registry')
    const isRegistrar = window.location.pathname.startsWith('/registrar')
    // setta il valore di default del locale
    setAllAxiosInstancesAcceptLanguageHeader(lang.locale)

    if (lang.locale) {
      if (isRegistry) {
        setComp(<App conf={'/registry/keycloak.json'} locale={lang.locale} />)
      } else {
        if (isRegistrar) {
          setComp(<App conf={'/registrar/keycloak.json'} locale={lang.locale} />)
        } else {
          setComp(<RealmDashboard />)
        }
      }
    }
  }, [pathname, lang.locale])

  return <>{comp}</>
}
