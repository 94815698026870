import React from 'react'
import { Table } from 'antd'
import { LanguageContext } from '@nic/commons'
import { TablePaginationConfig } from 'antd/lib/table/interface'

type ISimpleRenderFunction = (args: any) => React.ReactElement<any>
type IFullRenderFunction = (text: any, record: any, index: any) => React.ReactElement<any>

type IRenderFunc = ISimpleRenderFunction | IFullRenderFunction

// /**
//  * IPagination usato per definire l'attributo opzionale per la definizione della paginazione della tabella
//  */
// interface IPagination {
//   total: number // totalElements
//   pageSize: number // size
//   current: number // number
// }

interface IDatatable<T> {
  data: T[]
  page?: TablePaginationConfig
  datatablePrefix?: string
  view?: string[]
  render?: Record<string, IRenderFunc>
  loading?: boolean
  changePageHandler?: (pageSize: number, current: number) => void
  rowHandler?: (record: any, index?: number) => void
}

type Pick<T, U extends keyof T> = { [K in U]: T[K] }

function pick<T, U extends keyof T>(obj: T, keys: U[]): Pick<T, U> {
  return keys.reduce((acum: any, key: keyof T) => {
    // eslint-disable-next-line no-sequences
    return (acum[key] = obj[key]), acum
  }, {})
}

/**
 * Componente che permette di wrappare la Table di antd
 *
 * Parametri:
 *
 * **data**: unico parametro obbligatorio, deve essere un array anche vuoto.
 *
 * **view**: _opzionale_, se non presente verranno prese tutte le colonne, altrimenti verranno selezionate le colonne presenti nell'array di stringa
 *
 * **page**: _opzionale_, struttura dati  contenenti di tipo IPagination
 *
 * **render**: _opzionale_, array contenente le regole per renderizzare gli elementi delle celle
 *
 * **datatablePrefix**: _opzionale_, stringa utilizzata per spedificare la traduzione del header delle colonne della
 * tabella. Default __datatable__
 *
 * **changePageHandler**: _opzionale_, handler per la gestione del cambio pagina, o numero di pagine max visualizzato
 *
 * **loading**: _opzionale_, booleano utilizzato nel caso di Promise per caricare i dati.
 *
 * **rowHandler**: _opzionale_,che se specificato permette di definire una handler che viene invocato quando viene _cliccata_ una riga della tabella
 *
 * @param {any} data array dei dati che verranno rappresentati in forma tabellare
 * @param {any} view opzionale
 * @param {any} page
 * @param {any} render
 * @param {any} datatablePrefix
 * @param {any} changePageHandler
 * @param {any} loading
 * @param {any} rowHandler
 * @returns {any}
 * @constructor
 */

export function Datatable<T>({
  data,
  view,
  page,
  datatablePrefix,
  render,
  changePageHandler,
  loading,
  rowHandler,
}: React.PropsWithChildren<IDatatable<T>>) {
  const { t, locale } = React.useContext(LanguageContext)

  const [columns, setColunms] = React.useState<Array<Record<string, string>>>([])
  const [dataSource, setDataSource] = React.useState<Array<T> | undefined>(undefined)
  const prefix = datatablePrefix ?? 'datatable'

  // const showSizeSelector: boolean = changePageHandler !== undefined;

  React.useEffect(() => {
    const buildColumns = (_view: string[]) => {
      let res: Record<string, any>[] = []
      _view.forEach((v) => {
        res.push({
          title: t(`${prefix}.${v}`),
          dataIndex: v,
          key: v,
          render: render !== undefined ? render[v] : undefined,
        })
      })
      return res
    }

    const builsDataSource = (_data: T[], keys: (keyof T)[]) => {
      type Keys = typeof keys[number]
      type Selected = Pick<T, Keys>
      let res: any[] = []
      _data.forEach((d: Selected, index: number) => {
        res.push({
          key: `${index}`,
          ...pick(d, keys),
        })
      })
      return res
    }

    // @ts-ignore
    const cols: string[] = view ?? (data.length === 0 ? [] : Object.keys(data[0]))
    setColunms(buildColumns(cols))
    setDataSource(builsDataSource(data, cols as (keyof T)[]))
  }, [data, view, locale, t, prefix, render])

  return (
    <Table
      dataSource={dataSource as object[] | undefined}
      loading={loading}
      columns={columns}
      scroll={{ x: true }}
      pagination={{
        ...page,
        showSizeChanger: changePageHandler !== undefined,
        onShowSizeChange: (current: number, pageSize: number) => {
          changePageHandler && changePageHandler(current, pageSize)
        },
      }}
      onChange={(args: any) => {
        //console.log('Args: ', args)
        changePageHandler && changePageHandler(args.pageSize, args.current)
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: () => rowHandler && rowHandler(record, rowIndex),
        }
      }}
    />
  )
}

export class ColumnBuilder {
  private _innerColums: string[] = []

  constructor() {
    this._innerColums = []
  }

  setColumn(name: string, condition?: boolean) {
    if (condition === undefined || condition) {
      this._innerColums.push(name)
    }
    return this
  }

  build() {
    return this._innerColums
  }
}
