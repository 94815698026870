import React, { createContext, ReactNode, useContext, useState } from 'react'

interface UserContextProps {
  selectedUsers: UserSelected[]
  addSelectedUser: (user: UserSelected) => void
}

export interface UserSelected {
  id: number
  username: string
}
const UserContext = createContext<UserContextProps | undefined>(undefined)

const SelectUserProvider: React.FC<ReactNode> = ({ children }) => {
  const [selectedUsers, setSelectedUsers] = useState<UserSelected[]>([])

  const addSelectedUser = (user: UserSelected) => {
    setSelectedUsers((prevUsers) => {
      if (prevUsers.find((obj) => obj.id === user.id)) {
        return [...prevUsers.slice(0, 2)]
      }
      return [user, ...prevUsers.slice(0, 2)]
    })
  }

  return (
    <UserContext.Provider value={{ selectedUsers, addSelectedUser }}>
      {children}
    </UserContext.Provider>
  )
}

const useSelectUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

export { SelectUserProvider, useSelectUserContext }
