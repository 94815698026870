import { Input, Modal } from 'antd'
import React, { useContext } from 'react'
import { Registrar } from '@nic/ui-antd'
import { RequestContext } from '../../Contexts/RequestContext'
import { RequestPostRejectService } from '@nic/nic-api'
import { useTranslation } from '@nic/commons'
import { ErrorAlert } from '../../ErrorAlert/ErrorAlert'

const { TextArea } = Input

export function VariationProposalRegistryRejectModal(props: {
  showModalReject: boolean
  setShowModalReject: (value: ((prevState: boolean) => boolean) | boolean) => void
  onOk: () => void
}) {
  const [note, setNote] = React.useState<string>()
  const { stateReqById, id } = useContext(RequestContext)
  const t = useTranslation()
  // TODO Capire perche non funziona il RegistryVariationProvider dato andrebbe usato quello a favore
  // dei 3 useState sotto con i tra valori (loading, rejectSucess, rejectFail}
  const [loading, setLoading] = React.useState<boolean>()
  const [rejectSuccess, setRejectSuccess] = React.useState()
  const [rejectFail, setRejectFail] = React.useState<any>()

  React.useEffect(() => {
    setRejectFail(undefined)
  }, [rejectSuccess])

  const handleOnSubmit = async () => {
    setLoading(true)
    try {
      const rej = await RequestPostRejectService(id, note)
      setRejectSuccess(rej)
      setLoading(false)
      props.onOk()
    } catch (e) {
      setLoading(false)
      setRejectFail(e)
    }
  }

  return (
    <Modal
      title={<b>{t('variationProposalRegistryRejectModal.modalTitle')}</b>}
      visible={props.showModalReject}
      destroyOnClose
      onOk={handleOnSubmit}
      okButtonProps={{ loading, disabled: rejectFail }}
      onCancel={() => {
        setRejectFail(undefined)
        props.setShowModalReject(!props.showModalReject)
      }}
    >
      <p>
        {`${t('variationProposalRegistryRejectModal.p1.p1')} `}
        <b>{`${t('variationProposalRegistryRejectModal.p1.bold1')} `}</b>
        {`${t('variationProposalRegistryRejectModal.p1.p2')} `}
        <b>
          {stateReqById?.data?.registrarId && (
            <Registrar
              id={stateReqById?.data?.registrarId}
              scope={'registry'}
              render={(r) => r.name}
            />
          )}
        </b>
      </p>
      {`${t('variationProposalRegistryRejectModal.addNote')}:`}
      <TextArea rows={4} onChange={(e) => setNote(e.target.value)} />
      {rejectFail && (
        <div style={{ marginTop: 10 }}>
          <ErrorAlert error={rejectFail} />
        </div>
      )}
    </Modal>
  )
}
