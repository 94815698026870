import React from 'react'
import { Darwin, NicScope } from '@nic/nic-api/types'
import { useRequestCounter } from './useRequestCounter'
import { Counter } from './Counter'

export type AssignmentParam = 'registryAssignation' | 'registrarAssignation'
export type AssignmentValue = 'all' | 'assignedToMe' | 'assignedTo'

export interface IAssignmentParam {
  param: AssignmentParam
  value: AssignmentValue
}

interface IRequestCounter {
  status: Darwin.RequestStatus
  assignmentView?: IAssignmentParam
  title: string
  icon?: React.ReactElement<any>
  redirect: string
  scope: NicScope
  registrarId?: number
  color?: string
  backgroundColor?: string
  className?: string
  tip?: string
}

export const RequestCounter: React.FC<IRequestCounter> = ({
  status,
  assignmentView,
  registrarId,
  redirect,
  icon,
  title,
  color, //= '#3f8600',
  scope = 'registrar',
  backgroundColor,
  className,
  tip,
}) => {
  const state = useRequestCounter(scope, status, assignmentView, registrarId)

  return (
    <Counter
      title={title}
      redirect={redirect}
      value={state?.data?.count}
      error={state?.error}
      loading={state?.loading}
      color={color}
      icon={icon}
      backgroundColor={backgroundColor}
      tip={tip}
      className={className}
    />
  )
}
