import { Div, HorizontalLayout } from '@nic/ui-comps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { PaperLayoutTitled } from '../../../../../Various/PaperLayoutTitled/PaperLayoutTitled'

export interface ContactDetailCardProps
  extends ContactDetailCardIconBoxStyleProps,
    ContactDetailCardDescriptionProp {
  className?: string
}

export interface ContactDetailCardDescriptionProp {
  /** Titolo della sezione contatto **/
  contactTitle: string
  /** Sottotitolo della sezione contatto **/
  contactSubTitle: string
  /** titolo della card **/
  cardTitle?: string
  /** Sezione extra da mostrare sotto la sezione IconBox **/
  extra?: React.ReactNode
}

export interface ContactDetailCardIconBoxStyleProps {
  /** lato visualizzazione icona */
  iconSide?: 'left' | 'right'
  /** style componente */
  style?: React.CSSProperties
  /** background Card */
  backgroundColor?: string
  /** colore icona */
  color?: string
  /** dimensione icona */
  iconFontSize?: string
}

/***
 * Card utilizzata per mostrare il dettaglio del contatto.
 * Mostra un titolo, un sottotitolo, un'icona destra o sinistra
 *
 * @param props
 * @constructor
 */
export const ContactDetailCard: React.FC<ContactDetailCardProps> = (props) => {
  const iconFontSize = props.iconFontSize || '5em'

    console.log(ContactDetailCard.name,props)

  /* Icon da mostrare nella card */
  const IconBlock = () => (
    <Div style={{ textAlign: 'center', color: props.color }}>
      <FontAwesomeIcon icon={faIdCard as IconProp} style={{ fontSize: iconFontSize }} />
      <Div>
        <b>{props.contactTitle}</b>
      </Div>
      <Div>{props.contactSubTitle}</Div>
      {props.extra && <Div>{props.extra}</Div>}
    </Div>
  )

  return (
    <PaperLayoutTitled
      className={props.className}
      style={{
        width: '100%',
        backgroundColor: props.backgroundColor,
        // textAlign: props.iconSide || 'left',
        ...props.style,
      }}
      // headStyle={{ backgroundColor: 'white' }}
      title={props.cardTitle}
    >
      <HorizontalLayout
        style={{
          alignItems: 'flex-start',
          justifyContent: 'start',
          // justifyContent: props.iconSide === 'right' ? 'end' : 'start',
          columnGap: '5em',
        }}
      >
        <div className={'contact-detail-card-content'} style={{width:'100%'}}>
          {/*  Dettaglio del contatto*/}
          <div className={'contact-detail'} style={{width:'100%'}}>{props.children}</div>
          <div className={props.iconSide === 'right' ? 'icon-block-right ' : 'icon-block-left'}>
            <IconBlock />
          </div>
        </div>
      </HorizontalLayout>
    </PaperLayoutTitled>
  )
}
