import { Divider, Space } from 'antd'
import { NicScope } from '@nic/nic-api/types'
import { PrintAnomalyValue } from '@nic/ui-antd'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { AnomalyPopOver } from './AnomalyPopOver'
import { ShowHint } from './ShowHint'
import { camelToDash } from '@nic/commons'

export interface RenderAnomalyProp {
  /** nome del campo da valutare*/
  field: string
  /** valore del campo da valutare */
  value: React.ReactNode | string | []
  /** stile del report anomalia */
  anomalyCssStyle?: React.CSSProperties
  /** Scope nic */
  scope?: NicScope
  /** Hint dell'anomalia se presente */
  anomalyHint?: React.ReactNode
  /** update */
  update?: string
  /** titolo del popover */
  popOverTitle?: string
  /** contenuto del popOver */
  popOverContent?: React.ReactNode
  fontAwesomeIcon?: IconDefinition
  iconColor?: string
  /** Segnala se il valore riportato corrisponde a quello di un'anomalia**/
  isAnomaly?: boolean
}

/*
{
field: 'regcode',
value: '11111111',
anomalyCssStyle?: {}
scope?: 'registry'
anomalyHint?: 'qualche hint'
update?: '111222221'
popOverTitle?: 'Titolo '
popOverContent?: {}
fontAwesomeIcon?: {}}
iconColor?: string
isAnomaly?: boolean
}
*/

/***
 * Renderizza un'anomalia
 * @param props
 * @constructor
 */
export function RenderAnomaly(props: RenderAnomalyProp) {
  const hasHintToShow = (props.anomalyHint && !props.update && props.scope === 'registry') || false
  return (
    <div className={camelToDash(RenderAnomaly.name)}>
      <div
        style={{
          padding: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...props.anomalyCssStyle,
        }}
      >
        {/** Stampa il valore dell'anomalia **/}
        <Space direction={'vertical'}>
          <PrintAnomalyValue
            initialValue={props.value}
            update={props.update}
            hint={props.anomalyHint}
            scope={props.scope}
            style={{ ...props.anomalyCssStyle }}
          />
        </Space>
        {/** Aggiunge il popOver con le indicazione dell'anomalia **/}
        {props.popOverContent && (
          <div style={{ display: 'inline' }}>
            <Divider type={'vertical'} />
            <AnomalyPopOver
              content={props.popOverContent}
              title={props.popOverTitle}
              icon={props.fontAwesomeIcon}
              isAnomaly={props.isAnomaly}
            />
          </div>
        )}
      </div>

      {/** Aggiunge un hint sotto il valore dell'anomalia  **/}
      {hasHintToShow && <ShowHint anomalyHint={props.anomalyHint} />}
    </div>
  )
}
