import {
  ContactDetailCard,
  ContactDetailCardProps,
} from '../Base/Card/ContactDetailCard/ContactDetailCard'
import React from 'react'
import { KeyValueBoxProps } from './KeyValueDetailCard'
import { ContactReportType } from '../types'
import { Alert, Empty, Skeleton, Tag, Tooltip } from 'antd'
import { AxiosError } from 'axios'
import { useTranslation } from '@nic/commons'
import { RegistrantBox, RegistrantBoxProps } from './RegistrantBox'
import { Flex } from '@nic/ui-comps'
import { printAxiosFetchError } from '../../../ErrorAlert/ErrorAlert'

function printBestError(error: AxiosError) {
  if (error.response?.config) {
    return `URL: ${error.response?.config.baseURL}/${error.response?.config.url}`
  }
}

export type RegistrantDetailCardProps = {
  loading?: boolean
  error?: any
}
export const RegistrantDetailCard: React.FC<
  ContactDetailCardProps &
    KeyValueBoxProps<ContactReportType> &
    RegistrantDetailCardProps &
    RegistrantBoxProps
> = (props) => {
  const t = useTranslation()

  const RegistrantBoxMemoized = React.memo(RegistrantBox)

  const Content = () => {
    if (props.error) {
      if ((props.error as AxiosError)?.response?.status === 404) {
        return (
          <Flex direction={'row'} justify={'flex-end'}>
            <div style={{ float: 'right' }}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`${(props.error as AxiosError)?.response?.data.code} - ${
                  (props.error as AxiosError)?.response?.data.message
                }`}
              />
            </div>
          </Flex>
        )
      }
      return (
        <Alert
          message={
            <>
              {t('someThingWrong')}:{' '}
              <Tooltip
                title={`${printAxiosFetchError(props.error)} - ${printBestError(props.error)}`}
              >
                <Tag>{t('details')}</Tag>
              </Tooltip>
            </>
          }
          type="error"
          showIcon
        />
      )
    }

    if (props.loading) {
      return (
        <div style={{ width: 400 }}>
          <Skeleton active />
        </div>
      )
    }
    return <RegistrantBoxMemoized data={props.data} rows={props.rows} />
  }
  return (
    <ContactDetailCard {...(props as ContactDetailCardProps)}>
      <Content />
    </ContactDetailCard>
  )
}
