import {
  IGetRequestsExpiringParams,
  IGetRequestsParams,
} from '../../../../nic-react-monorepo/packages/nic-api/src'
import { DefaultOptionType } from 'antd/lib/select'
import React from 'react'
import { useRouter, useTranslation } from '@nic/commons'
import { Select, SelectProps } from 'antd'
import { HorizontalLayout, PaperLayout } from '@nic/ui-comps'
import { useHistory } from 'react-router'
import { stringify } from 'qs'

interface IExpiringDaysSelect {
  initialValue: IGetRequestsParams & IGetRequestsExpiringParams
  onChange?: (value: string | number, option: DefaultOptionType | DefaultOptionType[]) => void
}

/**
 * Selettore per determinare la scelta dei giorni di scadenza tra 0 e 30 il default è 7
 * @param initialValue
 * @param onChange
 * @constructor
 */
const ExpiringDaysSelect: React.FC<IExpiringDaysSelect> = ({ initialValue, onChange }) => {
  const t = useTranslation()
  const options: SelectProps['options'] = []
  for (let i = 0; i < 31; i++) {
    options.push({
      value: i,
      label: i,
    })
  }
  return (
    <HorizontalLayout style={{ justifyContent: 'flex-start', columnGap: '10px', rowGap: '0px' }}>
      {t('expirationDays')}
      <Select
        defaultValue={initialValue.days ?? '7'}
        options={options}
        onChange={onChange}
      ></Select>
    </HorizontalLayout>
  )
}

export function RequestsExpiringDays() {
  const { query } = useRouter()
  const t = useTranslation()
  let history = useHistory()
  const [search, setSearch] = React.useState<IGetRequestsParams & IGetRequestsExpiringParams>(query)
  const handleOnChange = (value: any) => {
    const a = search
    a.days = parseInt(value as string, 10)
    history.push(
      `${history.location.pathname}${stringify(a, {
        addQueryPrefix: true,
      })}`
    )
    setSearch(a)
  }

  if (history.location.pathname.includes('requests_expiring')) {
    return (
      <PaperLayout asCard={true}>
        <HorizontalLayout
          style={{ justifyContent: 'space-between', columnGap: '10px', rowGap: '0px' }}
        >
          <HorizontalLayout
            style={{ justifyContent: 'flex-start', columnGap: '10px', rowGap: '0px' }}
          >
            <h1>{t('RequestsExpiring')} </h1>
          </HorizontalLayout>
          <ExpiringDaysSelect initialValue={search} onChange={handleOnChange}></ExpiringDaysSelect>
        </HorizontalLayout>
      </PaperLayout>
    )
  }

  return <></>
}
