import { Registrar } from '@nic/ui-antd'
import { Link } from 'react-router-dom'
import {
  IRegistrarsListRow,
  useRequestsRegistrars,
} from '../../Components/Hooks/useRequestsRegistrars'
import { Div, PaperLayout } from '@nic/ui-comps'
import { Table } from 'antd'
import { PaperTitle } from '../../Components/Layout/PaperTitle'
import React from 'react'
import { ColumnGroupType, ColumnType } from 'antd/es/table'
import { LayoutContext } from '../../Context/LayoutContext'
import { Breadcrumb } from '../../Components/Breadcrumb/Breadcrumb'
import { useTranslation } from '@nic/commons'

export function RegistrarsAnomalies() {
  const registrarsList = useRequestsRegistrars()
  const layout = React.useContext(LayoutContext)
  const t = useTranslation()
  React.useEffect(() => {
    const init = () =>
      layout.setLayoutElement(
        'BREADCRUMB',
        <Breadcrumb
          elements={[
            { label: t('breadcrumb.dashboard'), path: `/registry/dashboard` },
            { label: t('breadcrumb.registrars'), path: `/registry/registrars` },
          ]}
        />
      )
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Div textAlign={'center'} width={'100%'}>
      <PaperLayout asCard={true}>
        <PaperTitle title={t('requestPerRegistrar')} />
        {/*  TODO: valorizzare props.userView  con il valore della sezione reame recuperata dal path */}
        <RegistrarsAnomaliesTable registrars={registrarsList as IRegistrarsListRow[]} />
      </PaperLayout>
    </Div>
  )
}

export function RegistrarsAnomaliesTable(props: { registrars: IRegistrarsListRow[] }) {
  const t = useTranslation()

  const columns: (ColumnGroupType<any> | ColumnType<any>)[] = [
    {
      title: 'Registrar',
      dataIndex: 'registrarId',
      key: 'registrarId',
      width: '60px',
      align: 'center',
      render: (r: number) => <Link to={'/registry/registrars/' + r}>{r}</Link>,
    },
    {
      title: 'Registrar',
      key: 'registrarId',
      render: (r: number, record: any) => (
        <Registrar id={record.registrarId} render={(v) => <span>{v.name}</span>} />
      ),
    },
    {
      title: t('Requests'),
      dataIndex: 'count',
      key: 'count',
      width: '20px',
      align: 'center',
      render: (count: number, record: any) => (
        <Link to={'/registry/registrars/' + record.registrarId}>{count}</Link>
      ),
    },
  ]

  return <Table dataSource={props.registrars} columns={columns} />
}
