import React, { useCallback } from 'react'
import { useRouter } from '@nic/commons'
import {
  DarwinHelpers,
  getRequestReferenceExtendedContactHelper,
  requestReferenceContactInitialState,
  requestReferenceContactReducer,
  requestContactAnomaliesReducer,
  requestContactAnomaliesInitialState,
} from '@nic/nic-api'
import { AuthContext } from '@nic/kc-auth'
import { Darwin, Domains, IFetchState, NicScope } from '@nic/nic-api/types'

export const ReferenceProvider: React.FC = ({ children }) => {
  const { match } = useRouter()
  const { keycloak } = React.useContext(AuthContext)
  const [id] = React.useState<number>((match.params as { id: number }).id)
  const scope = keycloak?.realm as NicScope

  const [stateReferenceDarwin, dispatchRefDarwin] = React.useReducer(
    requestReferenceContactReducer,
    requestReferenceContactInitialState
  )
  // @ts-ignore
  const [stateContactAnomalies, dispatchContactAnomalies] = React.useReducer(
    requestContactAnomaliesReducer,
    requestContactAnomaliesInitialState
  )

  const loadContact = useCallback((_requestId: number, _scope: NicScope) => {
    getRequestReferenceExtendedContactHelper(dispatchRefDarwin, _requestId, _scope)
  }, [])

  const loadAnomalies = useCallback((_darwinId: number, _scope: NicScope) => {
    DarwinHelpers.getContactAnomalies(
      dispatchContactAnomalies,
      _darwinId as number,
      { all: true },
      _scope as NicScope
    )
  }, [])

  React.useEffect(() => {
    loadContact(id, scope as NicScope)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, scope, loadContact])

  React.useEffect(() => {
    stateReferenceDarwin.data?.id && loadAnomalies(stateReferenceDarwin.data?.id, scope)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, loadAnomalies, stateReferenceDarwin.data?.id])

  console.log('ReferenceContext', 'called', stateReferenceDarwin.data)
  return (
    <ReferenceContext.Provider
      value={{
        stateReferenceDarwin,
        stateContactAnomalies,
        loadAnomalies: (darwinId: number, _scope: NicScope) => loadAnomalies(darwinId, _scope),
        loadContact: (requestId: number, _scope: NicScope) => loadContact(requestId, _scope),
      }}
    >
      {children}
    </ReferenceContext.Provider>
  )
}

export interface IRequestContext {
  stateReferenceDarwin: IFetchState<Darwin.ReferenceContact & Domains.Contact, any>
  stateContactAnomalies: IFetchState<Darwin.Anomalies, any>
  loadAnomalies: (darwinId: number, scope: NicScope) => void
  loadContact: (requestId: number, scope: NicScope) => void
}

export const ReferenceContext = React.createContext<IRequestContext>({
  stateReferenceDarwin: requestReferenceContactInitialState,
  stateContactAnomalies: requestContactAnomaliesInitialState,
  loadAnomalies: () => {},
  loadContact: () => {},
})
