import { Divider } from 'antd'
import { VerticalLayout } from '@nic/ui-comps'
import React from 'react'
import { useTranslation } from '@nic/commons'
import { useRequestTimeline } from '../useRequestTimeline'
import {
  DaysLeft,
  IRequestProgress,
  RequestProgressLabel,
  RequestProgressView,
  TimeLabel,
} from '../index'

export function CircleRequestProgress(props: IRequestProgress) {
  const t = useTranslation()

  const { percentage, endDate } = useRequestTimeline(
    props.creationDate,
    props.duration,
    props.expireDays
  )
  const isPaused = props.paused

  const remainingDays = props.expireDays || 0

  return (
    <>
      <Divider />
      <RequestProgressView
        remainingDays={remainingDays}
        isPaused={isPaused || false}
        percentage={percentage}
        isOverLabel={`${t('requestProgress.over')}`}
        type={'circle'}
      />
      <DaysLeft
        label={`${t('requestProgress.daysLeft')}`}
        remainingDays={remainingDays}
        isPaused={isPaused || false}
      />
      <Divider />
      <VerticalLayout style={{ rowGap: 20 }}>
        <TimeLabel
          label={`${t('requestProgress.open')}`}
          date={props.creationDate}
          color={'lightBlue'}
          isOver={false}
          hideTime={true}
        />
        {props.paused ? (
          <RequestProgressLabel label={`${t('requestProgress.closed')}`}>
            <div className={'time-content'}>{`${t('requestProgress.reloading')}`}</div>
          </RequestProgressLabel>
        ) : (
          <TimeLabel
            label={`${t('requestProgress.closed')}`}
            date={endDate.toString()}
            color={'burlywood'}
            isOver={!(percentage < 100)}
            hideTime={true}
          />
        )}
      </VerticalLayout>
    </>
  )
}
