import { PaperTitle } from "../Layout/PaperTitle";
import React from "react";
import { PaperLayout } from "@nic/ui-comps";
import { Can, useTranslation } from "@nic/commons";
import { EditRequestButton } from "../Buttons/EditRequestButton";
import { DeleteRequestButton } from "../Buttons/DeleteRequestButton";
import { Divider, Space } from "antd";

export const RequestManagementCard: React.FC = () => {
  const t = useTranslation();
  return (
    <PaperLayout asCard={false}>
      <PaperTitle showIcon title={t("cardRequestManagement.title")} />
      <Divider />
      <Space
        direction="horizontal"
        style={{
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
          // marginTop: 40,
        }}
      >
        <Can I={"Edit"} a={"Request"}>
          <EditRequestButton />
        </Can>
        <Can I={"Delete"} a={"Request"}>
          <DeleteRequestButton />
        </Can>
      </Space>
    </PaperLayout>
  );
};
