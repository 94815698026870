import React from 'react'
import { useTranslation } from '@nic/commons'
import { Alert } from 'antd'
import { Flex } from '@nic/ui-comps'
import { EventTypeIcon } from './const'
import { EventType } from './EventType'
import { EventMessage } from './EventMessage'
import { UserTag } from './UserTag'
import { IRequestEvent } from '@nic/nic-api/types/Darwin/types'
import { EventTypeAlertType, EventTypeColor } from '../Status/const'
import TimeTag from '../Various/TimeTag/TimeTag'

export const DarwinEvent: React.FC<{ event: IRequestEvent }> = ({ event }) => {
  const t = useTranslation()
  // @ts-ignore
  const eColor = EventTypeColor[event.type] ?? 'gray'
  return (
    <Alert
      // @ts-ignore
      type={EventTypeAlertType[event.type]}
      icon={EventTypeIcon[event.type]}
      message={
        <Flex
          direction={'row'}
          justify={'space-between'}
          wrap={'wrap'}
          style={{ rowGap: '5px', columnGap: '5px' }}
        >
          <TimeTag color={eColor} created={event.created} fontWeight={700} />
          <EventType type={event.type} color={eColor} />
        </Flex>
      }
      description={
        <Flex direction={'column'} justify={'flex-start'} align={'flex-start'}>
          <EventMessage message={event.message} color={eColor} t={t} />
          <UserTag user={event.user} color={eColor} />
        </Flex>
      }
    />
  )
}
