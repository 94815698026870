import React from 'react'
import { Space } from 'antd'
import { Link } from 'react-router-dom'

interface IBreacrumbItem {
  label: string
  path: string
}

interface IBreadcrumb {
  elements: IBreacrumbItem[]
}

export const Breadcrumb: React.FC<IBreadcrumb> = ({ elements }: IBreadcrumb) => {
  return (
    <Space split={<span>{'>'}</span>}>
      {elements.map((e: IBreacrumbItem, i: number) => (
        <Link key={i} to={e.path}>
          {e.label}
        </Link>
      ))}
    </Space>
  )
}
