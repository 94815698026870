import { Domains, NicScope } from '@nic/nic-api/types'
import { IDCCustomRow } from '@nic/ui-comps'
import React from 'react'
import { stdDateHelper } from '../../../Utils/DateUtils'
import { useTranslation } from '@nic/commons'
import { Skeleton } from 'antd'
import { Contact, DomainCard, MidaLink, Registrar } from '@nic/ui-antd'
import { getWindowRunConfig } from '../../../darwinConfig'

export function DomainDetail(props: { data?: Domains.Domain; scope?: NicScope }) {
  const t = useTranslation()
  const midaHosts = getWindowRunConfig().MIDA_HOST
  const rows: IDCCustomRow<keyof Domains.Domain, Domains.Domain>[] = [
    {
      hide: !(props.scope === 'registry'),
      index: 'id',
      render: (record) => (
        <MidaLink host={midaHosts} tag path={`/domains/${record.id}`} description={record.id} />
      ),
    },
    {
      label: `${t('domainDetail.domain')}`,

      index: 'domain',
      render: (record, value) => (
        <a href={`http://${value}`} rel="noopener noreferrer" target="_blank">
          {value}
        </a>
      ),
    },
    {
      label: `${t('domainDetail.darwinStatus')}`,
      index: 'darwinStatus',
      render: (record, value) => <>{value}</>,
    },
    {
      label: `${t('domainDetail.status')}`,
      index: 'status',
      render: (record, value) => <>{value}</>,
    },
    // {
    //   label: `${t('domainDetail.all')}`,
    //   index: 'all',
    //   render: (record, value) => <>{JSON.stringify(record)}</>,
    // },
    // {
    //   label: `${t('domainDetail.signed')}`,
    //   index: 'signed',
    //   render: (_, value) => <>{value ? 'SI' : 'NO'}</>,
    // },
    {
      label: `${t('domainDetail.registrantId')}`,

      index: 'registrantId',
      render: (record, value) =>
        props.scope === 'registry' ? (
          record.registrantId ? (
            <MidaLink
              host={midaHosts}
              tag
              path={`/contacts/${record.registrantId}`}
              description={
                <Contact id={value} scope={props.scope} render={(d) => <>{d.contactId}</>} />
              }
            />
          ) : (
            <></>
          )
        ) : value ? (
          <Contact id={value} scope={props.scope} render={(d) => <>{d.contactId}</>} />
        ) : (
          <div style={{ width: 400 }}>
            <Skeleton active />
          </div>
        ),
    },
    {
      index: 'adminId',
      label: `${t('domainDetail.adminId')}`,
      render: (record, value) =>
        props.scope === 'registry' ? (
          record.adminId ? (
            <MidaLink
              host={midaHosts}
              tag
              path={`/contacts/${record.adminId}`}
              description={
                <Contact id={value} scope={props.scope} render={(d) => <>{d.contactId}</>} />
              }
            />
          ) : (
            <></>
          )
        ) : (
          <Contact id={value} scope={props.scope} render={(d) => <>{d.contactId}</>} />
        ),
    },
    {
      label: `${t('domainDetail.created')}`,
      index: 'created',
      render: (_, value) => <>{stdDateHelper(value)}</>,
    },
    {
      label: `${t('domainDetail.lastupdate')}`,

      index: 'lastupdate',
      render: (_, value) => <>{stdDateHelper(value)}</>,
    },
    {
      label: `${t('domainDetail.expire')}`,

      index: 'expire',
      render: (_, value) => <>{stdDateHelper(value)}</>,
    },
    {
      label: t('domainDetail.registrar'),
      index: 'registrarId',
      render: (record, value) => <Registrar id={value} render={(d) => <>{d.name}</>} />,
    },
    {
      label: `${t('domainDetail.registrarCreated')}`,
      index: 'registrarIdCreated',
      render: (record, value) => <Registrar id={value} render={(d) => <>{d.name}</>} />,
    },
  ]

  return (
    <DomainCard
      data={props.data}
      dataRows={rows}
      valueWidth={'200px'}
      labelWidth={'200px'}
      labelBold={true}
      rowGap={'5px'}
      height={'180px'}
    />
  )
}
