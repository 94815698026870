import React from 'react'
import { Darwin, IFetchState } from '@nic/nic-api/types'
import { camelToDash } from '@nic/commons'
import { RegistrantSelectedData } from '../../RegistrantLoader/RegistrantSelectedData'
import { FactoryContactCard } from '../../DataDisplay/RegistrantContactCards/FactoryContactCard'
import { HorizontalLayout, PaperLayout } from '@nic/ui-comps'
import { PaperTitle } from '../../Layout/PaperTitle'
import { IDarwinContactExtended } from '../../ContactListFullDetailed/ContactListFullDetailed'

export type SelectedRegistrantCardProps = {
  hasAdditionalContactCard: boolean
  additionalContactDetailCard: React.ReactNode
  selectedContact: IDarwinContactExtended
  stateContactAnomalies: IFetchState<Darwin.Anomalies, any>
  contactDetailTitle: React.ReactNode | undefined
}

export function SelectedRegistrantCard({
  selectedContact,
  stateContactAnomalies,
  contactDetailTitle,
  additionalContactDetailCard,
  hasAdditionalContactCard,
}: SelectedRegistrantCardProps) {
  const BoxContactDetail = () => (
    <div
      className={camelToDash(BoxContactDetail.name)}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {selectedContact && (
        <RegistrantSelectedData
          contactData={selectedContact}
          stateContactAnomalies={stateContactAnomalies}
        >
          {(data) => (
            <FactoryContactCard type={'selected'} {...data} contactId={selectedContact.contactId} />
          )}
        </RegistrantSelectedData>
      )}
    </div>
  )

  return (
    <PaperLayout>
      {contactDetailTitle && <PaperTitle showIcon title={contactDetailTitle} />}
      <HorizontalLayout
        style={{
          columnGap: 10,
          alignItems: 'stretch',
          padding: '0 10px 0 10px',
          justifyContent: 'center',
        }}
      >
        <BoxContactDetail />
        {hasAdditionalContactCard ? additionalContactDetailCard : <></>}
      </HorizontalLayout>
    </PaperLayout>
  )
}
