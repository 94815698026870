import { PaperLayout } from '@nic/ui-comps'
import { ContactsTwoTone, LinkOutlined } from '@ant-design/icons'
import React, { useContext } from 'react'
import { useTranslation } from '@nic/commons'
import { RequestContext } from '../Contexts/RequestContext'
import { PaperTitle } from '../Layout/PaperTitle'
import { Counter } from '../Counters/Counter'

type ResourceType = 'contacts' | 'domains'

export function ResourceSummaryRequestCard(props: {
  onSelectCounter?: (index: ResourceType) => void
}) {
  const t = useTranslation()
  const { stateReqContactsCount, stateReqDomainsCount } = useContext(RequestContext)

  const domainCount = stateReqDomainsCount.data?.count || 0
  const contactCount = stateReqContactsCount.data?.count || 0

  const handleOnSelectCounter = (index: ResourceType) => {
    props.onSelectCounter && props.onSelectCounter(index)
  }

  return (
    <PaperLayout asCard={false}>
      <PaperTitle showIcon title={t('cardReqSummaryResources.title')} />
      <Counter
        loading={false}
        value={contactCount}
        title={t('cardReqSummaryResources.contactsCounter')}
        icon={<ContactsTwoTone style={{ fontSize: '2.2rem' }} />}
        redirect={'#'}
        disable={!(contactCount > 1)}
        onClick={() => handleOnSelectCounter('contacts')}
        color={'#3f8600'}
        asCard={false}
        type={'noLink'}
      />
      <Counter
        loading={false}
        value={domainCount}
        title={t('cardReqSummaryResources.domainsCounter')}
        onClick={() => handleOnSelectCounter('domains')}
        icon={<LinkOutlined style={{ fontSize: '2.2rem' }} />}
        redirect={`#`}
        color={'#3f8600'}
        asCard={false}
        type={'noLink'}
      />
    </PaperLayout>
  )
}
