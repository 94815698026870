import React from 'react'
import { useTranslation } from '@nic/commons'
import { PaperLayout } from '@nic/ui-comps'
import { Button, Divider, Space, Tooltip } from 'antd'
import { LikeOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Darwin } from '@nic/nic-api/types'
import { PaperTitle } from '../Layout/PaperTitle'

export const VariationProposalByRegistrarCard = (props: {
  anomalies?: Darwin.Anomalies
  /** disabilità il solo bottone procedi **/
  disabledEditButton?: boolean
  /** gestisce l'evento del click del bottone Procedi **/
  onEdit?: (edit: boolean) => void
  disabledButtons?: boolean
  reasons?: string[]
}) => {
  const t = useTranslation()

  const handleOnProcessClick = () => {
    props.onEdit && props.onEdit(true)
  }

  // console.log('VariationProposalByRegistrarCard', props)
  return (
    <PaperLayout asCard={false}>
      <PaperTitle showIcon title={t('cardVariationProposalRegistrar.title')} />
      <ul>
        <li>
          <b>{t('cardVariationProposalRegistrar.process')}</b> -{' '}
          {t('cardVariationProposalRegistrar.processDescription')}
        </li>
      </ul>
      <Divider />
      <div style={{ textAlign: 'center' }}>
        <Space direction="horizontal" style={{ marginBottom: 40 }} size={'large'}>
          <Button
            type="primary"
            shape="round"
            size={'large'}
            disabled={props.disabledEditButton || props.disabledButtons}
            icon={<LikeOutlined />}
            onClick={handleOnProcessClick}
          >
            {t('cardVariationProposalRegistrar.process')}
          </Button>
          {props.reasons &&
            props.reasons.length > 0 &&
            (props.disabledEditButton || props.disabledButtons) && (
              <Tooltip
                placement="topLeft"
                title={
                  <ul>
                    {props.reasons?.map((r) => (
                      <li>{r}</li>
                    ))}
                  </ul>
                }
              >
                <Button type={'link'}>
                  <QuestionCircleOutlined />
                </Button>
              </Tooltip>
            )}
        </Space>
      </div>
    </PaperLayout>
  )
}
