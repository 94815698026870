import { DefinitionRowType } from './KeyValueDetailCard/KeyValueDetailCard'
import { ContactReportContent, ContactReportType } from './types'
import React from 'react'
import { Country, ProvinceDecoder } from '@nic/ui-antd'
import { stdDateHelper } from '../../../Utils/DateUtils'
import { Space } from 'antd'
import { PrintConsentForPublish, PrintEntityType, StreetsDecoder } from './helpers'

/***
 * Configurazione  delle righe per la stampa del dettaglio del contatto
 * @param customRenderFn
 * @param t
 */
export function contactDetailRows(
  customRenderFn?: (
    /** Oggetto completo da utilizzare se necessario **/
    obj: ContactReportType,
    /** Elemento corrente che corrisponde alla chiave definite in fieldKey **/
    element: ContactReportContent,
    /** campo fieldKey **/
    fieldKey: string,
    defaultRender?: (obj: ContactReportType, value: any) => React.ReactNode
  ) => React.ReactElement,
  t?: any
): DefinitionRowType<ContactReportType>[] {
  const handleOnBaseRow = (
    obj: ContactReportType,
    element: ContactReportContent,
    field: any
  ): React.ReactElement => {
    if (customRenderFn) {
      return customRenderFn(obj, element, field as string, (_obj, value) => {
        return <div>{value}</div>
      })
    } else {
      return <>{element.defaultValue}</>
    }
  }

  const handleOnNationalityRow = (
    obj: ContactReportType,
    element: ContactReportContent,
    field: any
  ): React.ReactElement => {
    if (customRenderFn) {
      return customRenderFn(obj, element, field as string, (_obj, value) => (
        <Country
          id={value}
          render={(country) => (
            <>
              {country.code.toUpperCase()} - <i>{country.name}</i>
            </>
          )}
        />
      ))
    } else {
      return <>{element.defaultValue}</>
    }
  }

  const handleOnStateRow = (
    obj: ContactReportType,
    element: ContactReportContent,
    field: any
  ): React.ReactElement => {
    if (customRenderFn) {
      return customRenderFn(obj, element, field as string, (_obj, value) =>
        _obj.country?.defaultValue.toLowerCase() === 'it' ? (
          <ProvinceDecoder provinceCode={value.toUpperCase()} />
        ) : (
          <>{value}</>
        )
      )
    } else {
      return <>{element.defaultValue}</>
    }
  }

  const handleOnBooleanRow = (
    obj: ContactReportType,
    element: ContactReportContent,
    field: any
  ): React.ReactElement => {
    if (customRenderFn) {
      return customRenderFn(obj, element, field as string, (_obj, value) =>
        PrintConsentForPublish(value)
      )
    } else {
      return <>{element.defaultValue}</>
    }
  }

  const handleOnEntityTypeRow = (
    obj: ContactReportType,
    element: ContactReportContent,
    field: any
  ): React.ReactElement => {
    if (customRenderFn) {
      return customRenderFn(obj, element, field as string, (_obj, value) => PrintEntityType(value))
    } else {
      return <>{element.defaultValue}</>
    }
  }

  const handleOnDateRow = (
    obj: ContactReportType,
    element: ContactReportContent,
    field: any
  ): React.ReactElement => {
    if (customRenderFn) {
      return customRenderFn(obj, element, field as string, (_obj, value) => (
        <>{stdDateHelper(value)}</>
      ))
    } else {
      return <>{stdDateHelper(element.defaultValue)}</>
    }
  }

  const handleStreetsRow = (
    obj: ContactReportType,
    element: ContactReportContent,
    field: any
  ): React.ReactElement => {
    if (customRenderFn) {
      return customRenderFn(obj, element, field as string, (_obj, value) =>
        StreetsDecoder({ streets: value })
      )
    } else {
      return <Space direction={'vertical'}>{element.defaultValue.map((v: string) => v)}</Space>
    }
  }

  return [
    {
      index: 'contactId',
      label: t(`label.contactId`),
      render: handleOnBaseRow,
    },
    {
      index: 'name',
      label: t(`label.name`),
      render: handleOnBaseRow,
    },
    {
      index: 'org',
      label: t(`label.org`),
      render: handleOnBaseRow,
    },
    {
      index: 'regcode',
      label: t(`label.regcode`),
      render: handleOnBaseRow,
    },
    {
      index: 'schoolCode',
      label: t(`label.schoolCode`),
      render: handleOnBaseRow,
    },
    {
      index: 'nationality',
      label: t(`label.nationality`),
      render: handleOnNationalityRow,
    },
    {
      index: 'entityType',
      label: t(`label.entityType`),
      render: handleOnEntityTypeRow,
    },
    {
      index: 'city',
      label: t(`label.city`),
      render: handleOnBaseRow,
    },
    {
      index: 'streets',
      label: t(`label.streets`),
      render: handleStreetsRow,
    },

    {
      index: 'state',
      label: t(`label.state`),
      render: handleOnStateRow,
    },
    {
      index: 'postalCode',
      label: t(`label.postalCode`),
      render: handleOnBaseRow,
    },
    {
      index: 'country',
      label: t(`label.country`),
      render: handleOnNationalityRow,
    },
    {
      index: 'voice',
      label: t(`label.voice`),
      render: handleOnBaseRow,
    },
    {
      index: 'fax',
      label: t(`label.fax`),
      render: handleOnBaseRow,
    },
    {
      index: 'email',
      label: t(`label.email`),
      render: handleOnBaseRow,
    },
    {
      index: 'consentForPublish',
      label: t(`label.consentForPublish`),
      render: handleOnBooleanRow,
    },
    {
      index: 'created',
      label: t(`label.created`),
      render: handleOnDateRow,
    },
    {
      index: 'lastupdate',
      label: t(`label.lastupdate`),
      render: handleOnDateRow,
    },
  ]
}
