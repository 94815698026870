import React from 'react'
import { Div, Flex } from '@nic/ui-comps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface IPaperTitle {
  title: React.ReactNode
  showIcon?: boolean
  actions?: React.ReactNode
  style?: React.CSSProperties
}

export const PaperTitle: React.FC<IPaperTitle> = (props) => {
  return (
    <Flex
      direction={'row'}
      justify={'space-between'}
      alignContent={'center'}
      style={{ marginBottom: '20px' }}
    >
      <Div fontWeight={'700'} fontSize={'1.2rem'} style={props.style}>
        {props.showIcon && (
          <FontAwesomeIcon
            icon={faAngleRight as IconProp}
            style={{
              color: '#005CBC',
              marginRight: 5,

              // textShadow: '-1px -1px 0 #999, 1px -1px 0 #999, -1px 1px 0 #999, 1px 1px 0 #999',
            }}
          />
        )}
        {props.title}
      </Div>
      {props.actions}
    </Flex>
  )
}
