import { Div, Flex, IPackageJson, LogoRegistro, Version } from '@nic/ui-comps'
import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import packageJson from '../package.json'
import { faCopyright } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'

export function Footer() {
  return (
    <Flex direction={'column'} justify={'center'}>
      <Flex direction={'row'} style={{ width: '20vw' }}>
        <Tooltip
          title={<Version packageJson={packageJson as IPackageJson} style={{ fontSize: 10 }} />}
        >
          <Div marginRight={'10px'}>darwin</Div>{' '}
        </Tooltip>

        <Div marginRight={'10px'}>{new Date().getFullYear()}</Div>
        <FontAwesomeIcon color="gray" icon={faCopyright as IconProp} />
        <Div marginLeft={'10px'}>Registro</Div>

        <LogoRegistro style={{ width: 25, height: 25, margin: '0px 2px' }} />
      </Flex>
    </Flex>
  )
}
