import React from 'react'
import { useTranslation } from '@nic/commons'
import { RegistrantEntityType } from '@nic/nic-api/types/Domains/types'
import { Space } from 'antd'

export function PrintConsentForPublish(value: boolean) {
  const t = useTranslation()
  return <div>{value ? t('yes').toUpperCase() : t('no').toUpperCase()}</div>
}

export function PrintEntityType(value: RegistrantEntityType) {
  const t = useTranslation()
  return (
    <>
      {value} - {t(`entityTypes.${value}`)}
    </>
  )
}

export function StreetsDecoder(props: {
  streets: string[] | undefined
  style?: React.CSSProperties
}) {
  if (props.streets?.length === 1) {
    return <>{props.streets}</>
  }
  return (
    <Space direction={'vertical'}>
      <ul>
        {props.streets &&
          props.streets.map((street: string, i: number) => (
            <li key={i} style={props.style}>
              {street}
            </li>
          ))}
      </ul>
    </Space>
  )
}
