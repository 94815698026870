import React, { useContext } from 'react'
import { RequestContext } from '../../../Contexts/RequestContext'
import { useRequestInfo } from '../../../Hooks/useRequestInfo'
import { SummaryRequestTabProp } from './index'
import { SummaryRequestTabContent } from './SummaryRequestTabContent'

/**
 * Contenuto della visualizzazione della Request  summary
 * @param props
 * @constructor
 */
export const SummaryRequestTabController: React.FC<SummaryRequestTabProp> = (props) => {
  const { stateReqById, stateReqDocuments } = useContext(RequestContext)

  const { hasExpirationDays } = useRequestInfo()
  const hasDocuments =
    stateReqDocuments.data?.page && stateReqDocuments.data?.page.totalElements > 0
  const isRegistrarUpdatedStatus = stateReqById.data?.status === 'registrarUpdated'

  return (
    <SummaryRequestTabContent
      hasDocuments={hasDocuments}
      hasAssignmentRequestPermission={false}
      isRegistrarUpdatedStatus={isRegistrarUpdatedStatus}
      hasExpirationDays={hasExpirationDays}
      {...(props as SummaryRequestTabProp)}
    />
  )
}
