import React, { useContext, useState } from 'react'
import { Button, Modal } from 'antd'
import { IRequiredDocumentsBody } from '@nic/nic-api/types/Darwin/types'
import { RequestContext } from '../../Contexts/RequestContext'
import { useTranslation } from '@nic/commons'
import { ReferenceContext } from '../../Providers/ReferenceProvider'
import { RegistrantEntityType } from '@nic/nic-api/types/Domains/types'
import { equalArrays } from '../utils'
import { RequiredDocumentsContent } from '../RequiredDocumentsContent/RequiredDocumentsContent'
import { ErrorAlert } from '../../ErrorAlert/ErrorAlert'
import { useAssignmentRequest } from '../../AssignmentRequests/useAssignmentRequest'

export interface RequiredDocumentsModalProps {
  enabled: boolean
  initialRequiredDocs?: IRequiredDocumentsBody
  entityType: RegistrantEntityType
}

/**
 * Modale per gestione dei documenti da richiedere
 *
 * @param enabled
 * @param initialRequiredDocs
 * @constructor
 */
export const RequiredDocumentsModal: React.FC<RequiredDocumentsModalProps> = ({
  enabled,
  initialRequiredDocs,
}) => {
  const t = useTranslation()
  const {
    postRequiredDocuments,
    cleanRequiredDocument,
    stateRequiredDocuments: { loading, error, data: reqDocsData },
    reload,
  } = useContext(RequestContext)
  const {
    stateReferenceDarwin: { data },
  } = useContext(ReferenceContext)
  const [open, setOpen] = useState(false)
  const [requiredDocumentsBody, setRequiredDocumentsBody] =
    useState<IRequiredDocumentsBody | undefined>(initialRequiredDocs)
  const [touched, setTouched] = useState<boolean>(false)
  const entityType = data?.registrant?.entityType
  const { isRegistryAssignedUser } = useAssignmentRequest()

  const showModal = () => {
    setOpen(true)
  }

  const handleOk = () => {
    postRequiredDocuments && requiredDocumentsBody && postRequiredDocuments(requiredDocumentsBody)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleOnChangeDocs = (selectedBox: any) => {
    setTouched(!equalArrays(selectedBox, initialRequiredDocs?.requiredDocuments?.documents || []))
    setRequiredDocumentsBody(selectedBox)
  }

  const handleAfterClose = () => {
    setRequiredDocumentsBody(reqDocsData || initialRequiredDocs)
    cleanRequiredDocument && cleanRequiredDocument()
    reload && reload()
  }
  return (
    <>
      <Button
        type="primary"
        onClick={showModal}
        disabled={!(enabled && isRegistryAssignedUser)}
        loading={loading}
      >
        {t('requiredDocumentsModal.button')}
      </Button>
      <Modal
        visible={open}
        title={t('requiredDocumentsModal.title')}
        onOk={handleOk}
        okButtonProps={{ disabled: !touched }}
        confirmLoading={loading}
        onCancel={handleCancel}
        afterClose={handleAfterClose}
        destroyOnClose
      >
        {error && <ErrorAlert error={error} />}
        {entityType && (
          <RequiredDocumentsContent
            entityType={entityType}
            requiredDocumentsBody={requiredDocumentsBody}
            onChange={handleOnChangeDocs}
            message={initialRequiredDocs?.message}
            showMessage={true}
          />
        )}
      </Modal>
    </>
  )
}

/*
{
    "message": "string",
    "requiredDocumentsBody": {
    "documents": [
    "IC"
    ],
    "other": "string"
}
}
*/

/*
Type 1
Identity Card
Passport
Fiscal Code
other

Type 2/3/4/5/6/7
Chamber of Commerce registration
Deed of Incorporation
Certificate of attribution of FC / VAT num.
other
*/
