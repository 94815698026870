import { PaperLayout } from '@nic/ui-comps'
import React, { useContext } from 'react'
import { useTranslation } from '@nic/commons'
import { RequestContext } from '../../Contexts/RequestContext'
import { PaperTitle } from '../../Layout/PaperTitle'
import { FactoryContactCard } from '../../DataDisplay/RegistrantContactCards/FactoryContactCard'
import { RegistrantCompletedData } from '../../RegistrantLoader/RegistrantCompletedData'
import { RegistrantUpdatedData } from '../../RegistrantLoader/RegistrantUpdatedData'
import { RegistrantReferenceData } from '../../RegistrantLoader/RegistrantReferenceData'
import { keys } from 'lodash'

/**
 * Card Registrante dedicata al profilo Registrar
 * nella visualizzazione di tipo 'completed' ,'registrarUpdated' , altro
 * @constructor
 */
export function RegistrarRegistrantCard() {
  const t = useTranslation()
  const { stateReqById } = useContext(RequestContext)

  let component
  switch (stateReqById.data?.status) {
    case 'completed':
      component = (
        <RegistrantCompletedData>
          {(response) => (
            <>
              {response.data && keys(response.data).length > 0 ? (
                <FactoryContactCard type={'completed'} {...response} />
              ) : (
                <>{t('registrarRegistrantCard.noData')}</>
              )}
            </>
          )}
        </RegistrantCompletedData>
      )
      break
    case 'registrarUpdated':
      component = (
        <RegistrantUpdatedData>
          {(data) => <FactoryContactCard type={'updated'} {...data} />}
        </RegistrantUpdatedData>
      )
      break
    default:
      component = (
        <RegistrantReferenceData>
          {(data) => <FactoryContactCard type={'reference'} {...data} />}
        </RegistrantReferenceData>
      )
      break
  }
  return (
    <PaperLayout asCard={false}>
      <PaperTitle showIcon title={<>{t('registrarRegistrantCard.title')}</>} />
      {component}
    </PaperLayout>
  )
}
