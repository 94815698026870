import React, { useContext } from 'react'
import { Darwin } from '@nic/nic-api/types'
import StatusView from '../../Status/StatusView'
import { Div, HorizontalLayout } from '@nic/ui-comps'
import { Can, useTranslation } from '@nic/commons'
import TimeTag from '../../Various/TimeTag/TimeTag'
import { RegistrarJBillingLinK } from '../../Various/RegistrarJbillingLink'
import { ReferenceContext } from '../../Providers/ReferenceProvider'
import { Divider, Space } from 'antd'

/**
 * Card di dettaglio della richiesta
 * @param props
 * @constructor
 */

export function RequestDetail(props: {
  data: Darwin.Request
  scope?: 'registry' | 'registrar'
  onReload?: () => void
}) {
  const t = useTranslation()
  const { stateReferenceDarwin } = useContext(ReferenceContext)

  return (
    <Space
      style={{
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        columnGap: '10px',
      }}
      split={<Divider type={'vertical'} />}
    >
      <HorizontalLayout style={{ justifyContent: 'flex-start', rowGap: '0px', columnGap: '10px' }}>
        <Div>Id:</Div>
        <Div fontWeight={'700'}>{props.data.id}</Div>
      </HorizontalLayout>
      <Space
        style={{
          justifyContent: 'flex-start',
          border: '1px solid #D3D3D3',
          padding: '0px 5px ',
          columnGap: '10px',
          borderRadius: 5,
        }}
        split={<Divider type={'vertical'} />}
      >
        <Space style={{ justifyContent: 'flex-start', columnGap: '10px', rowGap: '0px' }}>
          <Div>{t('Org')}:</Div>
          <Div fontWeight={'700'}>{stateReferenceDarwin.data?.org} </Div>
        </Space>
        <HorizontalLayout
          style={{ justifyContent: 'flex-start', columnGap: '10px', rowGap: '0px' }}
        >
          <Div>{t('RegCode')}:</Div>
          <Div fontWeight={'700'}>{stateReferenceDarwin.data?.registrant?.regcode} </Div>
        </HorizontalLayout>
      </Space>
      {/*<Can I={'View'} a={'User'}>*/}
      {/*  <Div>Userid: {props.data.id}</Div>*/}
      {/*</Can>*/}
      {/*<HorizontalLayout style={{ justifyContent: 'flex-start', columnGap: '20px', rowGap: '0px' }}>*/}
      {props.scope === 'registry' && (
        <Can I={'Read'} a={'Registrar'}>
          <HorizontalLayout
            style={{ justifyContent: 'flex-start', columnGap: '10px', rowGap: '0px' }}
          >
            <Div>{t('Registrar')}:</Div>
            <Div fontWeight={'700'}>
              <RegistrarJBillingLinK registrarId={props.data.registrarId} scope={'registry'} />
            </Div>
          </HorizontalLayout>
        </Can>
      )}
      <HorizontalLayout style={{ justifyContent: 'flex-start', columnGap: '10px', rowGap: '0px' }}>
        <Div>{t('Created')}:</Div>
        <TimeTag created={props.data.created} color={'gray'} fontWeight={700} bordered={true} />
      </HorizontalLayout>
      {props.data.status && (
        <HorizontalLayout
          style={{ justifyContent: 'flex-start', columnGap: '10px', rowGap: '0px' }}
        >
          <Div>{t('Status')}:</Div>
          <StatusView status={props.data.status} />
        </HorizontalLayout>
      )}
    </Space>
  )
}
