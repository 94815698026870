import { Darwin, NicScope } from "@nic/nic-api/types";
import { DataNode } from "rc-tree/lib/interface";
import { UploadFile } from "antd/es/upload/interface";
import { RequestDocumentDownloadService } from "@nic/nic-api";

export interface IDocumentUploadFile<T> extends DataNode, UploadFile<T> {
  other?: any;
}

/**
 * metodo per costruire un albero di documenti.
 * I nodi padri sono la tipologia e le foglie sono una lista di documenti dello stesso tipo.
 * @param t
 * @param darwinDocuments
 * @param renderTitle
 */
export function buildDarwinDocumentUploadFileDataNode(
  t: (key: string) => string,
  // lista documenti di tipo Darwin
  darwinDocuments: Darwin.DarwinUploadedFileResponse[],
  // metodo per la render della riga del documento
  renderTitle?: (selected: Darwin.DarwinUploadedFileResponse) => React.ReactNode
): IDocumentUploadFile<Darwin.DarwinUploadedFileResponse>[] {
  return darwinDocuments.reduce(
    (accumulator, uploadedFileResponse, currentIndex, array) => {
      const { documentType: key } = uploadedFileResponse;

      /* building children*/
      const children: IDocumentUploadFile<Darwin.DarwinUploadedFileResponse> = {
        isLeaf: true,
        key: uploadedFileResponse.id as string,
        uid: uploadedFileResponse.id as string,
        title: renderTitle && renderTitle(uploadedFileResponse),
        fileName: uploadedFileResponse.fileName,
        status: "done",
        // icon: PaperClipOutlined,
        name: `${uploadedFileResponse.fileName} ${
          uploadedFileResponse.note !== undefined
            ? " - " + uploadedFileResponse.note
            : ""
        }`,
        response: uploadedFileResponse,
        size: uploadedFileResponse.fileSize,
        // lastModifiedDate: uploadedFileResponse.created as string,
        url: "http://#",
        linkProps: { target: "_blank", rel: "noopener noreferrer" },
        type: uploadedFileResponse.fileType,
      };

      let elementIndex = accumulator.findIndex((d) => d.name === key);

      if (elementIndex > -1) {
        // append children value if key already exists
        let element = accumulator[elementIndex];
        element = {
          ...element,
          children: [...(element.children as any), children],
        };
        accumulator.splice(elementIndex, 1);
        return [...accumulator, element];
      } else {
        // building main category
        const firstLevel: IDocumentUploadFile<Darwin.DarwinUploadedFileResponse> =
          {
            isLeaf: false,
            key: key as Darwin.DocType,
            title: t(key as Darwin.DocType).toUpperCase(),
            uid: key as Darwin.DocType,
            name: key as Darwin.DocType,
            children: [children],
          };

        return [...accumulator, firstLevel];
      }
    },
    [] as IDocumentUploadFile<Darwin.DarwinUploadedFileResponse>[]
  );
}

/**
 * {
 *     "created": "2022-06-09T06:36:32",
 *     "documentType": "IC",
 *     "fileName": "contratto.pdf",
 *     "fileSize": 870451,
 *     "fileType": "pdf",
 *     "id": "d39e79a8-0034-4f5b-861c-3ec66782e67a",
 *     "note": "era meglio morire....",
 *     "requestId": 23
 * }
 */

/**
 * Metodo per trasformare il contenuto binario in file e permetterlo di scaricarlo da web
 * @param binaryFile
 * @param type
 * @param fileName
 */
export function binaryToFile(
  binaryFile: any,
  type: "pdf" | "png",
  fileName?: string
) {
  let blob = new File([binaryFile], fileName as string);
  const url = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  document.body.appendChild(a);
  // a.style = 'display: none'
  a.href = url;
  a.download = fileName || "unknown.pdf";
  a.click();
  window.URL.revokeObjectURL(url);
}

// function downloadPDF(pdf: any) {
//   const linkSource = `data:application/pdf;base64,${pdf}`
//   const downloadLink = document.createElement('a')
//   const fileName = 'abc.pdf'
//   downloadLink.href = linkSource
//   downloadLink.download = fileName
//   downloadLink.click()
// }

/***
 * Metodo per scaricare il file da Darwin e aprirlo
 * @param scope
 * @param id
 * @param fileType
 * @param fileName
 */
export function openFile(
  scope: NicScope,
  id: string,
  fileType: "pdf" | "png",
  fileName?: string
) {
  try {
    RequestDocumentDownloadService(id, scope, {
      responseType: "blob",
    }).then((x) => {
      // console.log('yes')
      binaryToFile(x.data, fileType as "pdf" | "png", fileName);
    });
  } catch (error) {
    // console.log('no')
    console.log(error);
    return { error };
  }
}

export function equalArrays(arr1: any[], arr2: any[]) {
  if (arr1.length === arr2.length) {
    return arr1.every(function (element) {
      return arr2.includes(element);
    });
  }
  return false;
}
