import { Flex } from "@nic/ui-comps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React from "react";

export const UserTag = (props: { user: string; color: string }) => {
  return (
    <Flex direction={"row"} justify={"flex-start"} style={{ gap: "10px" }}>
      <Flex direction={"row"} style={{ gap: "5px" }}>
        <FontAwesomeIcon color={props.color} icon={faUser as IconProp} />
        <span>{props.user}</span>
      </Flex>
    </Flex>
  );
};
