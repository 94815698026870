import React, { useContext } from 'react'
import { VariationContext } from '../Providers/VariationProvider'
import { Div, VerticalLayout } from '@nic/ui-comps'
import { useRequestView } from './useRequestView'
import { useBreadcrumb } from '../Hooks/useBreadcrumb'
import { RequestTabs } from './RequestTabs/RequestTabs'
import LoadingSpinner from '@nic/ui-antd/build/LoadingSpinner/LoadingSpinner'
import { RequestBox } from '../RequestBox/RequestBox'

/***
 * Vista di una Request
 * @constructor
 */
export function RequestView() {
  useBreadcrumb()
  const { loading, handleOnChangeTab, activeKeyTab, handleEditOn, handleEditOff, requestId } =
    useRequestView()
  const {
    state: { edit },
  } = useContext(VariationContext)

  if (loading) {
    return <LoadingSpinner pageCentered tip={'Loading request ' + requestId + ' ...'} />
  }

  return (
    // <React.Profiler
    //   id={RequestView.name}
    //   onRender={(p1, p2, p3, p4, p5) => console.log(p1, p2, p3, p4, p5)}
    // >
    <VerticalLayout>
      <RequestBox onReload={handleEditOff} />
      <Div backgroundColor={'white'}>
        <RequestTabs
          onChangeTab={handleOnChangeTab}
          tabKey={activeKeyTab}
          edit={edit}
          onEdit={handleEditOn}
          onEditOff={handleEditOff}
        />
      </Div>
    </VerticalLayout>
    // </React.Profiler>
  )
}
