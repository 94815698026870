import React, {useContext} from "react";
import {Alert, FormInstance, Spin} from "antd";
import {Div, PaperLayout} from "@nic/ui-comps";
import {VariationContext} from "../../../../../Providers/VariationProvider";
import {ContactsContext} from "../../../../../Providers/ContactsProvider";
import {camelToDash, useTranslation} from "@nic/commons";
import {PaperTitle} from "../../../../../Layout/PaperTitle";
import {ReferenceContext} from "../../../../../Providers/ReferenceProvider";
import {RegistryRegistrantCards} from "../../../../../Cards/RegistryRegistrantCards/RegistryRegistrantCards";
import {Darwin} from "@nic/nic-api/types";
import {IContact} from "@nic/nic-api/types/Domains/types";
import {useScopeInfo} from "../../../../../Hooks/useScopeInfo";
import {VariationRegistrantStepForm} from "./VariationRagistrantStepForm";
import {AxiosError} from "axios";
import {printAxiosFetchError} from "../ResultStep/ResultStep";

/**
 *   Mostra il box della form utlile alla modifica dei dati del registrante.
 *   Gestisce la modifica del profilo da parte dell'operatore del registro e da parte del profilo del registrar offrendo
 *   uno switch nel caso del registrar che permette la modifica esclusiva del campo org/regcode
 *   Mostra i valori precedenti e quelli anomali
 * @constructor
 */
export const VariationRegistrantController: React.FC<{ form: FormInstance }> =
    (props: { form: FormInstance }) => {
        const {stateContactAnomalies, stateReferenceDarwin} =
            useContext(ReferenceContext);

        const hasError =
            !stateContactAnomalies.data ||
            stateContactAnomalies.data?.length === 0 ||
            !stateReferenceDarwin.data;

        return (
            <div className={camelToDash(VariationRegistrantController.name)}>
                {hasError ? <ShowError/> : <RegistrantController form={props.form}/>}
            </div>
        );
    };

/**
 * Mostra l'errore
 * @constructor
 */
const ShowError: React.FC = () => <div>Something went wrong!</div>;

/**
 *  Se nella lista dei contatti da aggiornare ci sono contatti sanare allo il controllare
 *  mostrerà la form di editing del Registrante.
 *  In caso contrario (nessun contatto da sanare ma solo contatti da eliminare o da non editare)
 *  verranno mostrati solamente i dati del Registrante in readonly
 *
 * @constructor
 */
const RegistrantController: React.FC<{ form: FormInstance }> = ({form}) => {
    const {
        state: {toDo},
    } = useContext(ContactsContext);
    const contactsToUpdate = React.useMemo(
        () => toDo.elements.map((c) => c.registrarContactId),
        [toDo]
    );

    const registrantCard = () => {
        if (contactsToUpdate.length === 0) {
            return <ReadOnlyRegistrantCard/>;
        } else {
            return (
                <EditorRegistrantCard
                    contactToUpdate={contactsToUpdate.length}
                    form={form}
                />
            );
        }
    };

    return <RegistrantFramework>{registrantCard()}</RegistrantFramework>;
};

/**
 * Componenti che contornano il contenuto principale della pagina
 * @param children
 * @constructor
 */
const RegistrantFramework: React.FC = ({children}) => {
    const t = useTranslation();
    return (
        <PaperLayout>
            <PaperTitle showIcon title={t("variationRegistrantStep.title")}/>
            <Div padding={"10px"}>{children}</Div>
        </PaperLayout>
    );
};

/***
 * Card che permette la visualizzazione dei valori del Registrante in sola lettura
 * @constructor
 */
const ReadOnlyRegistrantCard: React.FC = () => {
    const t = useTranslation();
    return (
        <>
            <Alert
                type={"warning"}
                description={t("variationRegistrantStep.description")}
                showIcon
                style={{marginBottom: 10}}
            />
            <Div style={{opacity: 0.4}}>
                <RegistryRegistrantCards/>
            </Div>
        </>
    );
};

interface EditorRegistrantCardProps {
    contactToUpdate: number;
    form: FormInstance;
}

function ValidationPrintError(props: { error: AxiosError }) {
    const t = useTranslation()
    if (props.error.response?.status === 400) {
        const {errors} = props.error.response.data
        return <div>{t("variationRegistrantStep.fieldsError")}:<ul>
            {errors.map((e: any)=><li><div><b>{t(e.field)}:</b> <span style={{color:'red'}}>{e.value}</span></div><i>{e.message}</i></li>)}
        </ul></div>
    } if (props.error.message){
        return <div><b>{t("variationRegistrantStep.invalidForm")}</b>: <span>{t("variationRegistrantStep."+props.error.message)}</span>
        </div>
    }
    else {
        return <>{printAxiosFetchError(props.error)}</>
    }
}

/**
 *
 * Card che permette la visualizzazione dei valori del Registrante in modifica
 *
 * @constructor
 */
const EditorRegistrantCard: React.FC<EditorRegistrantCardProps> = (props) => {
    const t = useTranslation();
    const {stateContactAnomalies, stateReferenceDarwin} =
        useContext(ReferenceContext);
    const {state} = useContext(VariationContext);
    const {scope} = useScopeInfo();

    // Commentato perchè è stato decise con non serve
    // const hasOrgAndRegCodeOption = stateContactAnomalies?.data?.map((a) => a.field)
    //   .filter((f) => f === 'org' || f === 'regcode').length
    console.log("props.anomalies1", stateContactAnomalies.data);
    console.log(
        "!!!!!state.error",
        JSON.stringify(state.error?.response?.data?.errors)
    );

    return (
        <div className={EditorRegistrantCard.name}>
            <Alert
                type={"warning"}
                description={t(`variationRegistrantStep.alert.contactUpdate`, {
                    number: props.contactToUpdate,
                })}
                showIcon
                style={{marginBottom: 10}}
            />
            {state.loading && (
                <Div width={"100%"} textAlign={"center"}>
                    <Spin tip={t("dataValidation")}/>
                </Div>
            )}

      {
        // TODO Capire se lasciare la stampa dell'errore o metterla solo quando diversa da un certo codice di errore
        state.error &&
          // <ValidationPrintError error={state.error}/>
          (
          <Alert
            message={t("Error")}
            description={
              <>
                  <ValidationPrintError error={state.error}/>
                {/*<Popover*/}
                {/*  content={<pre>{JSON.stringify(state.error, null, 2)}</pre>}*/}
                {/*>*/}
                {/*  <span style={{ float: "right" }}>*/}
                {/*    <Tag color={"error"}>Dettagli</Tag>*/}
                {/*  </span>*/}
                {/*</Popover>*/}
              </>
            }
            type={"error"}
            showIcon={true}
          />
        )
      }

                            <VariationRegistrantStepForm
                                form={props.form}
                                contact={stateReferenceDarwin?.data as IContact}
                                anomalies={stateContactAnomalies.data as Darwin.Anomalies}
                                enableSwitchOrgRegcode={scope === "registrar"}
                                scope={scope}
                                errors={state.error?.response?.data?.errors}
                            />

                                 {/*<VariationRegistrantStepView*/}
                                 {/*    scope={scope}*/}
                                 {/*    form={props.form}*/}
                                 {/*    initialValues={{*/}
                                 {/*        ...stateReferenceDarwin?.data,*/}
                                 {/*        ...stateReferenceDarwin?.data?.registrant,*/}
                                 {/*        ...state.changes,*/}
                                 {/*    }}*/}
                                 {/*    contact={stateReferenceDarwin?.data as IContact}*/}
                                 {/*    anomalies={stateContactAnomalies.data as Darwin.Anomalies}*/}
                                 {/*    // todo inserire logica*/}
                                 {/*    orgRegCodeOption={scope === 'registrar'}*/}
                                 {/*    errors={state.error?.response?.data?.errors || []}*/}
                                 {/*/>*/}
                            </div>
                            );
                        };


