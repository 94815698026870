import React from 'react'
import { PaperLayout, VerticalLayout } from '@nic/ui-comps'
import { RequestsSearchForm } from '../../Components/Forms/RequestsSearchForm'
import { useRouter, useTranslation } from '@nic/commons'
import { Darwin, NicScope } from '@nic/nic-api/types'
import { RequestsTableController } from '../../Components/RequestsTable/RequestsTableController'
import { PaperTitle } from '../../Components/Layout/PaperTitle'
import { Breadcrumb } from '../../Components/Breadcrumb/Breadcrumb'
import { LayoutContext } from '../../Context/LayoutContext'
import { AuthContext } from '@nic/kc-auth'
import { RequestsExpiringDays } from '../../Components/RequestsExpiringDays/RequestsExpiringDays'

interface IGetRequestsParams {
  page?: number
  registrarId?: { value: number; label: string }
  size?: number
  status?: Darwin.RequestStatus
  userId?: number
}

export function Search() {
  const t = useTranslation()
  const { query } = useRouter()
  const layout = React.useContext(LayoutContext)
  const { keycloak } = React.useContext(AuthContext)
  const realm = keycloak?.realm
  const search: IGetRequestsParams = query as IGetRequestsParams
  const isRegistrar = realm === 'registrar'

  // const queryParams = {
  //   ...search,
  // } as Darwin.GetRequestParams

  React.useEffect(() => {
    layout.setLayoutElement(
      'BREADCRUMB',
      <Breadcrumb
        elements={[
          { label: 'Dashboard', path: `/${realm}/dashboard` },
          {
            label: t('Requests'),
            path: `/${realm}/requests?status=pending&view=expirationDays,referenceContact&${
              isRegistrar ? 'registrarAssignation' : 'registryAssignation'
            }=assignedToMe`,
          },
        ]}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VerticalLayout>
      <RequestsExpiringDays />
      <PaperLayout
        asCard={true}
        marginTop={'0px'}
        marginBottom={'0px'}
        marginLeft={'0px'}
        marginRight={'0px'}
      >
        <PaperTitle showIcon title={t('search.title')} />
        <VerticalLayout>
          <RequestsSearchForm scope={keycloak?.realm as NicScope} />
          {/*<FilterCard style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }} />*/}
        </VerticalLayout>
      </PaperLayout>
      {/*</Can>*/}
      <RequestsTableController
        title={t('search.results')}
        userView={keycloak?.realm as NicScope}
        registrarId={search.registrarId?.value}
      />
    </VerticalLayout>
  )
}
