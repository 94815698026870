import { RequestToAssignToMeService } from '@nic/nic-api'
import { NicScope } from '@nic/nic-api/types'
import React, { useCallback } from 'react'
import { AxiosError } from 'axios'
import { notification } from 'antd'
import { printAxiosError } from '../../../Utils/common'
import { PrintSuccesMsg } from '../../Feedback/PrintSuccesMsg'

export function useAssignRequestToMe() {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<AxiosError>()
  const [response, setResponse] = React.useState<any>()

  const handleAssignRequestToMe = useCallback(async (requestId: number, scope: NicScope) => {
    notification.success({
      message: PrintSuccesMsg('Richiesta presa in carico.'),
      placement: 'top',
    })
    setLoading(true)

    // your api request logic in here, bellow only show example
    try {
      const { data, status } = await RequestToAssignToMeService(requestId, scope)
      if (data && status === 200) setResponse(data)
    } catch (e) {
      const error = e as AxiosError
      // if (axios.isAxiosError(error)) {
      //     setError(error)
      // } else {
      //     setError(error)
      // }
      notification.error({ message: printAxiosError(e), placement: 'top' })
      setError(error)
    }
    setLoading(false)
  }, [])

  return { loading, response, error, handleAssignRequestToMe }
}
