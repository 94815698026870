import { useTranslation } from "@nic/commons";
import { Darwin, NicScope } from "@nic/nic-api/types";
import {
  CountryDecoder,
  CountrySelectLookup,
  printEntityType,
  ProvinceDecoder,
  ProvinceSelectLookup,
} from "@nic/ui-antd";
import { Form, Input, Modal, Radio, RadioChangeEvent, Select } from "antd";
import { FormProps } from "antd/es/form/Form";
import { IFieldError, showOriginalValue } from "./utils";
import React from "react";
import { ExtendedAnomaly } from "../../../../../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faLandmark,
  faSchool,
  faStoreAlt,
  faToriiGate,
  faUserTie,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

interface VariationRegistrantStepFormProps extends FormProps {
  anomalies: Darwin.Anomalies;
  enableRegCode: boolean;
  enableOrg: boolean;
  scope?: NicScope;
  errors?: IFieldError[];
}

export function VariationRegistrantStepFormItems(
  props: VariationRegistrantStepFormProps
) {
  const t = useTranslation();
  const { initialValues, errors, anomalies } = props;
  const isRegistrar = props.scope === "registrar";
  const [isItaly, setIsItaly] = React.useState(
    initialValues?.country.toLowerCase() === "it"
  );
  const handleOnChangeConsentForPub = (e: RadioChangeEvent) => {
    if (e.target.value && isRegistrar) {
      Modal.info({
        title: t("variationRegistrantStepView.consentForPubInfo"),
        content: (
          <div>
            <p>{t("variationRegistrantStepView.consentForPubInfoDescr")}</p>
          </div>
        ),
        onOk() {},
      });
    }
  };
  const _style = { color: "rgba(0,0,0,.25)", marginRight: 5 };

  /*
                                                {
                                                    "id": 1,
                                                    "name": "Robert Hooft",
                                                    "org": "MOOWY BVBA",
                                                    "contactId": "4YJAMNPWL1",
                                                    "consentForPublish": true,
                                                    "created": "2020-06-22T19:36:02",
                                                    "email": "robert@moowy.be",
                                                    "voice": "+32.628965719",
                                                    "streets": [
                                                        "Meirbrug 1 Bus 5"
                                                    ],
                                                    "city": "Antwerpen",
                                                    "postalCode": "2000",
                                                    "state": "Antwerpen",
                                                    "country": "be",
                                                    "registrarId": 4951,
                                                    "registrant": {
                                                        "regcode": "NL852797643B01",
                                                        "nationality": "be",
                                                        "entityType": 7
                                                    },
                                                    "revision": {
                                                        "number": 99192726,
                                                        "date": "2020-06-22T19:36:02",
                                                        "type": "ADD"
                                                    }
                                                }
                                                     */
  const FormItemRegCode = () => (
    <Form.Item
      name={["registrant", "regcode"]}
      label={t(`label.regcode`)}
      rules={[{ required: true, message: "Please input a valid regcode!" }]}
      normalize={(value) => value.trim() && value.toUpperCase()}
      {...printValidatedErrors("regcode", errors)}
      extra={showOriginalValue(
        initialValues?.registrant?.regcode,
        anomalies.find((a: any) => a.field === "regcode") || undefined,
        t
      )}
    >
      <Input placeholder="regcode" disabled={!props.enableRegCode} />
    </Form.Item>
  );

  const addressAnomalies = anomalies.find(
    (a: any) => a.field === "address"
  ) as ExtendedAnomaly;

  const handleOnChangeCountry = (value: string) => {
    setIsItaly(value.toLowerCase() === "it");
  };
  // console.log(
  //   "log",
  //   form,
  //   initialValues?.streets,
  //   addressAnomalies !== undefined
  // );
  return (
    <>
      {/*  NAME */}
      <Form.Item
        name={"name"}
        label={t(`label.name`)}
        rules={[
          { required: true, message: "Please input a registrant username!" },
        ]}
        {...printValidatedErrors("name", errors)}
        extra={showOriginalValue(
          initialValues?.name,
          anomalies.find((a: any) => a.field === "name") || undefined,
          t
        )}
      >
        <Input placeholder="Contact name" />
      </Form.Item>

      {/*  ORG */}
      <Form.Item
        name={"org"}
        label={t(`label.org`)}
        rules={[
          { required: true, message: "Please input an organization name!" },
        ]}
        {...printValidatedErrors("org", errors)}
        extra={showOriginalValue(
          <ProvinceDecoder provinceCode={initialValues?.org} />,
          anomalies.find((a: any) => a.field === "org") || undefined,
          t
        )}
      >
        <Input placeholder="Org" disabled={!props.enableOrg} />
      </Form.Item>

      {isRegistrar && <FormItemRegCode />}

      {/*  Country */}
      <Form.Item
        name={"country"}
        label={t(`label.country`)}
        rules={[{ required: true, message: "Please input a country!" }]}
        {...printValidatedErrors("country", errors)}
        extra={showOriginalValue(
          <CountryDecoder countryCode={initialValues?.country} />,
          anomalies.find((a: any) => a.field === "country") || undefined,
          t
        )}
      >
        <CountrySelectLookup onChange={(v) => handleOnChangeCountry(v)} />
      </Form.Item>

      {/*  PROVINCE OR STATE */}
      {/*  TODO: dìpende da country, se IT mostra province italiane */}
      <Form.Item
        name={"state"}
        label={t(`label.state`)}
        rules={[
          { required: true, message: "Please input a province or state!" },
        ]}
        {...printValidatedErrors("state", errors)}
        extra={showOriginalValue(initialValues?.state, addressAnomalies, t)}
      >
        {isItaly ? (
          <ProvinceSelectLookup />
        ) : (
          <Input placeholder="Province or state" />
        )}
      </Form.Item>

      {/*  CITY */}
      <Form.Item
        name={"city"}
        label={t(`label.city`)}
        {...printValidatedErrors("city", errors)}
        rules={[{ required: true, message: "Please input a valid city!" }]}
        extra={showOriginalValue(initialValues?.city, addressAnomalies, t)}
      >
        <Input />
      </Form.Item>

      {/*  POSTALCODE */}
      <Form.Item
        name={"postalCode"}
        normalize={(value) => value.trim()}
        label={t(`label.postalCode`)}
        {...printValidatedErrors("postalCode", errors)}
        rules={[
          { required: true, message: "Please input a valid postal code!" },
        ]}
        extra={showOriginalValue(
          initialValues?.postalCode,
          addressAnomalies,
          t
        )}
      >
        <Input />
      </Form.Item>

      {/*  STREETS */}
      <Form.Item
        name={["streets", 0]}
        label={t(`label.streets`) + " (1)"}
        {...printValidatedErrors("streets[0]", errors)}
        rules={[{ required: true, message: "Please input streets!" }]}
        extra={showOriginalValue(
          initialValues?.streets[0],
          addressAnomalies,
          t
        )}
      >
        <Input />
      </Form.Item>

      {/*  STREETS */}
      <Form.Item
        name={["streets", 1]}
        label={t(`label.streets`) + " (2)"}
        {...printValidatedErrors("streets[1]", errors)}
        extra={
          initialValues?.streets[1] &&
          showOriginalValue(initialValues?.streets[1], addressAnomalies, t)
        }
      >
        <Input />
      </Form.Item>

      {/*  STREETS */}
      <Form.Item
        name={["streets", 2]}
        label={t(`label.streets`) + " (3)"}
        {...printValidatedErrors("streets[2]", errors)}
        extra={
          initialValues?.streets[2] &&
          showOriginalValue(initialValues?.streets[2], addressAnomalies, t)
        }
      >
        <Input />
      </Form.Item>

      {/* Vecchio metodo dinamico per inserire le vie*/}
      {/*{*/}
      {/*    form && initialValues?.streets && addressAnomalies !== undefined && RenderAnomalyArrayFieldInput(addressAnomalies, form, t, {}, 3)*/}
      {/*}*/}

      {/*  VOICE */}
      <Form.Item
        name={"voice"}
        normalize={(value) => value.trim()}
        label={t(`label.voice`)}
        {...printValidatedErrors("voice", errors)}
        rules={[{ required: true, message: "Please input voice!" }]}
        extra={showOriginalValue(
          initialValues?.voice,
          anomalies.find((a: any) => a.field === "voice") || undefined,
          t
        )}
      >
        <Input placeholder={"+39.0501234"} />
      </Form.Item>

      {/*  FAX */}
      <Form.Item
        normalize={(value) => value.trim()}
        name={"fax"}
        label={t(`label.fax`)}
        {...printValidatedErrors("fax", errors)}
        extra={
          initialValues?.fax &&
          showOriginalValue(
            initialValues?.fax,
            anomalies.find((a: any) => a.field === "fax") || undefined,
            t
          )
        }
      >
        <Input placeholder={"+39.0501234"} />
      </Form.Item>

      {/*  EMAIL */}
      <Form.Item
        name={"email"}
        label={t(`label.email`)}
        normalize={(value) => value.trim()}
        {...printValidatedErrors("email", errors)}
        rules={[{ required: true, message: "Please input a valid email!" }]}
        extra={showOriginalValue(
          initialValues?.email,
          anomalies.find((a: any) => a.field === "email") || undefined,
          t
        )}
      >
        <Input placeholder="email" />
      </Form.Item>

      <Form.Item
        name={"consentForPublish"}
        {...printValidatedErrors("consentForPublish", errors)}
        label={t(`label.consentForPublish`)}
        extra={showOriginalValue(
          t(initialValues?.consentForPublish.toString()),
          anomalies.find((a: any) => a.field === "consentForPublish") ||
            undefined,
          t
        )}
      >
        <Radio.Group onChange={handleOnChangeConsentForPub}>
          <Radio value={true}> SI </Radio>
          <Radio value={false}> NO </Radio>
        </Radio.Group>
      </Form.Item>

      {!isRegistrar && <FormItemRegCode />}

      {/*  ENTITYTYPE */}
      <Form.Item
        name={["registrant", "entityType"]}
        label={t(`label.entityType`)}
        {...printValidatedErrors("entityType", errors)}
        rules={[
          { required: !isRegistrar, message: "Please input an entity type!" },
        ]}
        extra={
          !isRegistrar &&
          showOriginalValue(
            printEntityType(initialValues?.registrant),
            anomalies.find((a: any) => a.field === "entityType") || undefined,
            t
          )
        }
      >
        {isRegistrar ? (
          printEntityType(initialValues?.registrant)
        ) : (
          <Select
            allowClear={true}
            placeholder={"Seleziona il tipo di registrante"}
            disabled={props.disabled}
            labelInValue
          >
            <Select.Option value={1}>
              <FontAwesomeIcon icon={faUserTie} style={_style} /> 1 -{" "}
              {t("entityTypes.1")}
            </Select.Option>
            <Select.Option value={2}>
              <FontAwesomeIcon icon={faIndustry} style={_style} /> 2 -{" "}
              {t("entityTypes.2")}
            </Select.Option>
            <Select.Option value={3}>
              <FontAwesomeIcon icon={faLandmark} style={_style} /> 3 -{" "}
              {t("entityTypes.3")}
            </Select.Option>
            <Select.Option value={4}>
              <FontAwesomeIcon icon={faStoreAlt} style={_style} /> 4 -{" "}
              {t("entityTypes.4")}
            </Select.Option>
            <Select.Option value={5}>
              <FontAwesomeIcon icon={faSchool} style={_style} /> 5 -{" "}
              {t("entityTypes.5")}
            </Select.Option>
            <Select.Option value={6}>
              <FontAwesomeIcon icon={faWarehouse} style={_style} /> 6 -{" "}
              {t("entityTypes.6")}
            </Select.Option>
            <Select.Option value={7}>
              <FontAwesomeIcon icon={faToriiGate} style={_style} /> 7 -{" "}
              {t("entityTypes.7")}
            </Select.Option>
          </Select>
        )}
      </Form.Item>

      {/*  NATIONALITY */}
      <Form.Item
        name={["registrant", "nationality"]}
        {...printValidatedErrors("nationality", errors)}
        label={t(`label.nationality`)}
        rules={[{ required: !isRegistrar, message: "Please input a nation!" }]}
        extra={
          !isRegistrar &&
          showOriginalValue(
            <CountryDecoder
              countryCode={initialValues?.registrant?.nationality}
            />,
            anomalies.find((a: any) => a.field === "nationality") || undefined,
            t
          )
        }
      >
        {isRegistrar ? (
          <CountryDecoder
            countryCode={initialValues?.registrant?.nationality}
          />
        ) : (
          <CountrySelectLookup />
        )}
      </Form.Item>
    </>
  );
}

// const FormItem: React.FC<{ next: React.ReactElement } & FormItemProps> = (
//   props
// ) => {
//   return (
//     <Space.Compact block style={{ alignItems: "baseline" }}>
//       <Form.Item {...props} style={{ width: "100%" }}>
//         {props.children}
//       </Form.Item>
//       <Space.Compact block style={{ alignItems: "baseline" }}>
//         {props.next}
//       </Space.Compact>
//     </Space.Compact>
//   );
// };

function printValidatedErrors(
  includedFieldString: string,
  errors?: IFieldError[]
): {
  validateStatus?: "error";
  help?: string;
} {
  const error = errors?.find((e) => e.field?.includes(includedFieldString));
  return {
    validateStatus: error && "error",
    help:
      error &&
      //`${error?.message} ${error?.code ? "(codice: " + error.code + ")" : ""}`
      `${error?.message}`,
  };
}
