import {
  ContactsOutlined,
  FileProtectOutlined,
  FlagOutlined,
  SolutionOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { Steps } from 'antd'
import { useContext } from 'react'
import { VariationContext } from '../../../../Providers/VariationProvider'
import { useTranslation } from '@nic/commons'

const { Step } = Steps

export function VariationProposalSteps() {
  const t = useTranslation()
  const {
    state: { step },
  } = useContext(VariationContext)
  const configSteps = [
    {
      title: t('variationProposalSteps.step1.title'),
      icon: <UserSwitchOutlined />,
      description: t('variationProposalSteps.step1.description'),
    },
    {
      title: t('variationProposalSteps.step2.title'),
      icon: <ContactsOutlined />,
      description: t('variationProposalSteps.step2.description'),
    },
    {
      title: t('variationProposalSteps.step3.title'),
      icon: <FileProtectOutlined />,
      description: t('variationProposalSteps.step3.description'),
    },
    {
      title: t('variationProposalSteps.step4.title'),
      icon: <SolutionOutlined />,
      description: t('variationProposalSteps.step4.description'),
    },
    {
      title: t('variationProposalSteps.step5.title'),
      icon: <FlagOutlined />,
    },
  ]

  return (
    <Steps>
      {configSteps.map((conf, k) => {
        let status: 'process' | 'wait' | 'finish' = 'process'
        let title: React.ReactNode = conf.title
        if (k > step) {
          status = 'wait'
        } else if (k < step) {
          status = 'finish'
        } else if (k === step) {
          title = <b>{title}</b>
        }

        return <Step key={k} {...conf} title={title} status={status} />
      })}
    </Steps>
  )
}
