import { IRequiredDocumentsBody } from "@nic/nic-api/types/Darwin/types";
import { Checkbox, Divider, Input, Row } from "antd";
import React, { useState } from "react";
import { camelToDash, useTranslation } from "@nic/commons";
import { RegistrantEntityType } from "@nic/nic-api/types/Domains/types";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import TextArea from "antd/es/input/TextArea";

const Type1Selections = (t: any) => (
  <div className={camelToDash(Type1Selections.name)}>
    <Row>
      <Checkbox value="IC">{t("IC")}</Checkbox>
    </Row>
    <Row>
      <Checkbox value="PASS">{t("PASS")}</Checkbox>
    </Row>
    <Row>
      <Checkbox value="FC">{t("FC")}</Checkbox>
    </Row>
  </div>
);

const OtherTypesSelections = (t: any) => (
  <div className={camelToDash(OtherTypesSelections.name)}>
    <Row>
      <Checkbox value="CHAMBER">{t("CHAMBER")}</Checkbox>
    </Row>
    <Row>
      <Checkbox value="DEED">{t("DEED")}</Checkbox>
    </Row>
    <Row>
      <Checkbox value="CERT">{t("CERT")}</Checkbox>
    </Row>
  </div>
);

/*
 {
    "message": "string",
    "requiredDocumentsBody": {
    "documents": ["IC","OTHER"],
    "other": "test"
  }

{
    "message": "string",
    "requiredDocumentsBody": {
    "documents": ["IC","OTHER"],
    "other": "test"
}



 */
export interface RequiredDocumentsContentProps {
  entityType: RegistrantEntityType;
  requiredDocumentsBody?: IRequiredDocumentsBody;
  onChange?: (d: IRequiredDocumentsBody) => void;
  disabled?: boolean;
  message?: string;
  showMessage?: boolean;
}

/**
 * Contenuto del componente RequiredDocument, mostra un checkbox group per la selezione dei documenti
 * @param entityType
 * @param documents
 * @param onChange
 * @param disabled
 * @constructor
 */
export const RequiredDocumentsContent: React.FC<RequiredDocumentsContentProps> =
  ({
    entityType,
    requiredDocumentsBody,
    onChange,
    disabled,
    message,
    showMessage,
  }) => {
    const t = useTranslation();
    const [changes, setChanges] = useState<IRequiredDocumentsBody>({
      requiredDocuments: requiredDocumentsBody?.requiredDocuments || {
        documents: [],
      },
      message: message,
    });
    const OTHER = "OTHER";
    const personEntityType = 1;
    const otherChecked = changes?.requiredDocuments?.documents.includes(OTHER);

    const handleTypesSelection = (type: RegistrantEntityType) => {
      if (type === personEntityType || type === personEntityType.toString()) {
        return Type1Selections(t);
      }
      return OtherTypesSelections(t);
    };

    const handleOnChangeCheckboxGroup = (v: Array<CheckboxValueType>) => {
      if (v.includes(OTHER)) {
        handleChange({
          requiredDocuments: { documents: v as [], other: undefined },
        });
      } else {
        handleChange({ requiredDocuments: { documents: v as [] } });
      }
    };

    const handleOnChangeOther = (v: any) => {
      otherChecked
        ? handleChange({
            message: changes?.message,
            requiredDocuments: {
              documents: changes?.requiredDocuments?.documents || [],

              other: v.target.value ?? undefined,
            },
          })
        : handleChange({
            message: changes?.message,
            requiredDocuments: {
              documents: changes?.requiredDocuments?.documents || [],
            },
          });
    };

    const handleOnChangeMessage = (v: any) => {
      handleChange({
        requiredDocuments: changes?.requiredDocuments,
        message: v.target.value ?? undefined,
      });
    };

    function handleChange(change: IRequiredDocumentsBody) {
      setChanges(change);
      onChange && onChange(change);
    }

    return (
      <div className={camelToDash(RequiredDocumentsContent.name)}>
        {/*<b>{t('requiredDocumentsContent.description')}:</b>*/}
        <Checkbox.Group
          disabled={disabled}
          style={{ width: "100%" }}
          onChange={handleOnChangeCheckboxGroup}
          value={changes?.requiredDocuments?.documents}
        >
          {handleTypesSelection(entityType)}
          <Row>
            <Checkbox value={OTHER} disabled={disabled}>
              {t("requiredDocumentsContent.other")}
            </Checkbox>
            <Input
              placeholder={t("requiredDocumentsContent.insertGenericDoc")}
              disabled={disabled || !otherChecked}
              value={
                otherChecked ? changes?.requiredDocuments?.other : undefined
              }
              onChange={handleOnChangeOther}
            />
          </Row>
        </Checkbox.Group>
        {showMessage && (
          <>
            <Divider type={"horizontal"} />
            <div>{t("requiredDocumentsContent.motivation")}:</div>
            <TextArea
              defaultValue={message}
              onChangeCapture={handleOnChangeMessage}
            />
          </>
        )}
      </div>
    );
  };
