import { RequestAssignationToReleaseService } from '@nic/nic-api'
import { NicScope } from '@nic/nic-api/types'
import React, { useCallback } from 'react'
import { notification } from 'antd'
import { printAxiosError } from '../../../Utils/common'
import { PrintSuccesMsg } from '../../Feedback/PrintSuccesMsg'

export function useReleaseAssignedRequest() {
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<any>()
  const [response, setResponse] = React.useState<any>()

  const handleReleaseAssignedRequest = useCallback(async (requestId: number, scope: NicScope) => {
    setLoading(true)
    notification.success({
      message: PrintSuccesMsg('Richiesta rilasciata.'),
      placement: 'top',
    })
    try {
      const { data, status } = await RequestAssignationToReleaseService(requestId, scope)
      if (data && status === 200) setResponse(data)
    } catch (error) {
      notification.error({ message: printAxiosError(error), placement: 'top' })
      setError(error)
    }
    setLoading(false)
  }, [])

  return { loading, response, error, handleReleaseAssignedRequest }
}
