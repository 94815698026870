import React, { useContext, useState } from 'react'
import { camelToDash, useRouter, useTranslation } from '@nic/commons'
import { RequestContext } from '../Contexts/RequestContext'
import { Button, notification } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { DeleteRequestModal } from '../VariationProposal/Modals/DeleteRequestModal'
import { useAssignmentRequest } from '../AssignmentRequests/useAssignmentRequest'

export const DeleteRequestButton: React.FC = () => {
  const t = useTranslation()
  const { reload, scope, stateReqById } = useContext(RequestContext)
  const { history } = useRouter()
  const [showModalRegistryDelete, setShowModalRegistryDelete] = useState(false)
  const { isRegistryAssignedUser } = useAssignmentRequest()

  const handleOnDelete = () => {
    setShowModalRegistryDelete(true)
  }

  const handleOnDeleteOk = () => {
    reload && reload()
    setShowModalRegistryDelete(false)
    notification.success({
      message: t('cardVariationProposalRegistry.msgDeletedRequest'),
    })
    history.push(`/${scope}/requests`)
  }

  const canBeDelete =
    (stateReqById.data?.status === 'registrarUpdated' || stateReqById.data?.status === 'pending') &&
    isRegistryAssignedUser

  return (
    <div
      className={camelToDash(DeleteRequestButton.name)}
      style={{ marginTop: 20, marginBottom: 20 }}
    >
      <Button
        type="primary"
        danger
        shape="round"
        size={'middle'}
        icon={<DeleteOutlined />}
        disabled={!canBeDelete}
        onClick={handleOnDelete}
      >
        {t('cancelRequest')}
      </Button>
      <DeleteRequestModal
        setShowModalDelete={setShowModalRegistryDelete}
        showModalDelete={showModalRegistryDelete}
        onOk={handleOnDeleteOk}
      />
    </div>
  )
}
