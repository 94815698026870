import React from 'react'
import { WarningOutlined } from '@ant-design/icons'
import { PaperLayout } from '@nic/ui-comps'
import { useLocation } from 'react-router'
import { useTranslation } from '@nic/commons'
import { HorizontalLayout, Div } from '@nic/ui-comps'

interface INoMatch {
  message?: string
}

export const NoMatchPage: React.FC<INoMatch> = () => {
  const location = useLocation()
  const t = useTranslation()
  return (
    <PaperLayout asCard={true}>
      <HorizontalLayout style={{ gap: 20 }}>
        <WarningOutlined
          style={{
            color: 'red',
            fontSize: '2rem',
          }}
        />
        <Div fontWeight={'700'}>{t('error.routerPath', { path: location.pathname })}</Div>
      </HorizontalLayout>
    </PaperLayout>
  )
}
