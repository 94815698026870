import React from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import {
  ContactByIdService,
  RequestContactChangeActionService,
  RequestContactsChangeActionService,
  RequestContactsService,
  RequestDomainCountService,
} from '@nic/nic-api'
import { Darwin, NicArray, NicScope } from '@nic/nic-api/types'
import { IDarwinContactExtended } from '../ContactListFullDetailed/ContactListFullDetailed'

export function createActionsCtx<StateType, ActionType>(
  // eslint-disable-next-line @typescript-eslint/no-shadow
  reducer: React.Reducer<StateType, ActionType>,
  initialState: StateType,
  _actions: any
) {
  const defaultDispatch: React.Dispatch<ActionType> = () => initialState // we never actually use this

  const ctx = React.createContext({
    state: initialState,
    dispatch: defaultDispatch, // just to mock out the dispatch type and make it not optioanl
    actions: _actions,
  })

  function Provider(props: React.PropsWithChildren<{ requestId: number; scope: NicScope }>) {
    const [state, dispatch] = React.useReducer<React.Reducer<StateType, ActionType>>(
      reducer,
      initialState
    )

    // Usato per loggare lo stato
    React.useEffect(() => {
      console.debug('ContactsProvider state', state)
    }, [state])

    // Carica la prima volta tutti i dati dei contatti
    React.useEffect(() => {
      actions.getAllContacts(props.requestId, props.scope)(dispatch)
    }, [props.requestId, props.scope])

    return <ctx.Provider value={{ state, dispatch, actions: actions }} {...props} />
  }

  return [ctx, Provider] as const
}

// usage
interface InitialStateP {
  loading: boolean
  error: any
  toDo: NicArray<IDarwinContactExtended>
  toDelete: NicArray<IDarwinContactExtended>
  toHold: NicArray<IDarwinContactExtended>
  tab: string
}

const nicArrayInitialState: NicArray<IDarwinContactExtended> = {
  elements: [],
  page: {
    totalPages: 0,
    number: 0,
    size: 0,
    totalElements: 0,
  },
}

const initialState: InitialStateP = {
  loading: false,
  error: undefined,
  toDo: nicArrayInitialState,
  toDelete: nicArrayInitialState,
  toHold: nicArrayInitialState,
  tab: '1',
}
type AppState = typeof initialState
type Action =
  | { type: 'CHANGE_TAB'; payload: string }
  // Count dei contatti
  // | { type: 'LOAD_FETCH_TODO_CONTACTS_COUNT' }
  // | { type: 'LOAD_FETCH_DELETE_CONTACTS_COUNT' }
  // | { type: 'LOAD_FETCH_HOLD_CONTACTS_COUNT' }
  // | { type: 'FETCH_DELETE_CONTACTS_COUNT_FAILURE'; payload: any }
  // | { type: 'FETCH_HOLD_CONTACTS_COUNT_FAILURE'; payload: any }
  // | { type: 'FETCH_TODO_CONTACTS_COUNT_FAILURE'; payload: any }
  // | {
  //     type: 'FETCH_TODO_CONTACTS_COUNT_SUCCESS'
  //     payload: NicArray<IDarwinContactExtended>
  //   }
  // | {
  //     type: 'FETCH_DELETE_CONTACTS_COUNT_SUCCESS'
  //     payload: NicArray<IDarwinContactExtended>
  //   }
  // | {
  //     type: 'FETCH_HOLD_CONTACTS_COUNT_SUCCESS'
  //     payload: NicArray<IDarwinContactExtended>
  //   }

  // Azione su tutti i contatti
  | { type: 'LOAD_HOLD_ALL_CONTACTS' }
  | { type: 'LOAD_TODO_ALL_CONTACTS' }
  | { type: 'LOAD_DELETE_ALL_CONTACTS' }
  | {
      type: 'TODO_ALL_CONTACTS_SUCCESS'
      payload: NicArray<IDarwinContactExtended>
    }
  | {
      type: 'DELETE_ALL_CONTACTS_SUCCESS'
      payload: NicArray<IDarwinContactExtended>
    }
  | {
      type: 'HOLD_ALL_CONTACTS_SUCCESS'
      payload: NicArray<IDarwinContactExtended>
    }
  | { type: 'DELETE_ALL_CONTACTS_FAILURE'; payload: any }
  | { type: 'HOLD_ALL_CONTACTS_FAILURE'; payload: any }
  | { type: 'TODO_ALL_CONTACTS_FAILURE'; payload: any }

  // Fetch dei contatti
  | { type: 'LOAD_FETCH_TODO_CONTACTS' }
  | { type: 'LOAD_FETCH_DELETE_CONTACTS' }
  | { type: 'LOAD_FETCH_HOLD_CONTACTS' }
  | { type: 'FETCH_DELETE_CONTACTS_FAILURE'; payload: any }
  | { type: 'FETCH_HOLD_CONTACTS_FAILURE'; payload: any }
  | { type: 'FETCH_TODO_CONTACTS_FAILURE'; payload: any }
  | {
      type: 'FETCH_TODO_CONTACTS_SUCCESS'
      payload: NicArray<IDarwinContactExtended>
    }
  | {
      type: 'FETCH_DELETE_CONTACTS_SUCCESS'
      payload: NicArray<IDarwinContactExtended>
    }
  | {
      type: 'FETCH_HOLD_CONTACTS_SUCCESS'
      payload: NicArray<IDarwinContactExtended>
    }

  // Azione su singolo contatto
  | { type: 'LOAD_TODO_CONTACT'; payload: number }
  | { type: 'LOAD_DELETE_CONTACT'; payload: number }
  | { type: 'LOAD_HOLD_CONTACT'; payload: number }
  | { type: 'DELETE_CONTACT_SUCCESS'; payload: any }
  | { type: 'HOLD_CONTACT_SUCCESS'; payload: any }
  | { type: 'TODO_CONTACT_SUCCESS'; payload: any }
  | { type: 'DELETE_CONTACT_FAILURE'; payload: any }
  | { type: 'HOLD_CONTACT_FAILURE'; payload: any }
  | { type: 'TODO_CONTACT_FAILURE'; payload: any }

function reducer(state: AppState, action: Action): AppState {
  console.debug('Contacts Reducer', action, state)
  switch (action.type) {
    //Count
    // case 'LOAD_FETCH_HOLD_CONTACTS_COUNT':
    //   return { ...state, loading: true, error: undefined }
    // case 'LOAD_FETCH_DELETE_CONTACTS_COUNT':
    //   return { ...state, loading: true, error: undefined }
    // case 'LOAD_FETCH_TODO_CONTACTS_COUNT':
    //   return { ...state, loading: true, error: undefined }
    //
    // case 'FETCH_TODO_CONTACTS_COUNT_SUCCESS':
    //   return { ...state, loading: false, toDo: action.payload }
    // case 'FETCH_HOLD_CONTACTS_COUNT_SUCCESS':
    //   return { ...state, loading: false, toHold: action.payload }
    // case 'FETCH_DELETE_CONTACTS_COUNT_SUCCESS':
    //   return { ...state, loading: false, toDelete: action.payload }
    //
    // case 'FETCH_TODO_CONTACTS_COUNT_FAILURE':
    //   return { ...state, loading: false, error: undefined }
    // case 'FETCH_HOLD_CONTACTS_COUNT_FAILURE':
    //   return { ...state, loading: false, error: undefined }
    // case 'FETCH_DELETE_CONTACTS_COUNT_FAILURE':
    //   return { ...state, loading: false, error: undefined }

    //Fetch
    case 'LOAD_FETCH_TODO_CONTACTS':
      return { ...state, loading: true, error: undefined }
    case 'LOAD_FETCH_DELETE_CONTACTS':
      return { ...state, loading: true, error: undefined }
    case 'LOAD_FETCH_HOLD_CONTACTS':
      return { ...state, loading: true, error: undefined }

    case 'FETCH_TODO_CONTACTS_SUCCESS':
      return { ...state, loading: false, toDo: action.payload }
    case 'FETCH_HOLD_CONTACTS_SUCCESS':
      return { ...state, loading: false, toHold: action.payload }
    case 'FETCH_DELETE_CONTACTS_SUCCESS':
      return { ...state, loading: false, toDelete: action.payload }

    case 'FETCH_TODO_CONTACTS_FAILURE':
      return { ...state, loading: false, error: undefined }
    case 'FETCH_HOLD_CONTACTS_FAILURE':
      return { ...state, loading: false, error: undefined }
    case 'FETCH_DELETE_CONTACTS_FAILURE':
      return { ...state, loading: false, error: undefined }

    // Action su singolo contatto
    case 'LOAD_TODO_CONTACT':
      return { ...state, loading: true, error: undefined }
    case 'LOAD_DELETE_CONTACT':
      return { ...state, loading: true, error: undefined }
    case 'LOAD_HOLD_CONTACT':
      return { ...state, loading: true, error: undefined }

    case 'DELETE_CONTACT_SUCCESS':
      return { ...state, loading: false }
    case 'TODO_CONTACT_SUCCESS':
      return { ...state, loading: false }
    case 'HOLD_CONTACT_SUCCESS':
      return { ...state, loading: false }

    case 'DELETE_CONTACT_FAILURE':
      return { ...state, loading: false, error: action.payload }
    case 'TODO_CONTACT_FAILURE':
      return { ...state, loading: false, error: action.payload }
    case 'HOLD_CONTACT_FAILURE':
      return { ...state, loading: false, error: action.payload }

    // Action su tutti i contatti
    case 'LOAD_TODO_ALL_CONTACTS':
      return { ...state, loading: true, error: undefined }
    case 'LOAD_DELETE_ALL_CONTACTS':
      return { ...state, loading: true, error: undefined }
    case 'LOAD_HOLD_ALL_CONTACTS':
      return { ...state, loading: true, error: undefined }

    case 'TODO_ALL_CONTACTS_SUCCESS':
      return { ...state, loading: false }
    case 'HOLD_ALL_CONTACTS_SUCCESS':
      return { ...state, loading: false }
    case 'DELETE_ALL_CONTACTS_SUCCESS':
      return { ...state, loading: false }

    case 'DELETE_ALL_CONTACTS_FAILURE':
      return { ...state, loading: false, error: action.payload }
    case 'TODO_ALL_CONTACTS_FAILURE':
      return { ...state, loading: false, error: action.payload }
    case 'HOLD_ALL_CONTACTS_FAILURE':
      return { ...state, loading: false, error: action.payload }
    case 'CHANGE_TAB':
      return { ...state, tab: action.payload }
    default:
      throw new Error()
  }
}

export const actions = {
  changeTab: (tab: string) => async (dispatch: React.Dispatch<any>) => {
    dispatch({ type: 'CHANGE_TAB', payload: tab })
  },
  getContacts:
    (
      requestId: number,
      action: Darwin.ContactActions,
      page: number,
      size: number,
      scope: NicScope
    ) =>
    async (dispatch: React.Dispatch<any>) => {
      const _page = page && page > 0 ? page - 1 : 0
      switch (action) {
        case 'delete':
          dispatch({ type: 'LOAD_FETCH_DELETE_CONTACTS' })
          break
        case 'todo':
          dispatch({ type: 'LOAD_FETCH_TODO_CONTACTS' })
          break
        case 'hold':
          dispatch({ type: 'LOAD_FETCH_HOLD_CONTACTS' })
          break
      }

      switch (action) {
        case 'delete': {
          getExtendedContactDetailsList(requestId, action, _page, size, scope).then(
            (extendedContat) => {
              //console.log('delete getExtendedContactDetailsList Resp', extendedContat)
              dispatch({
                type: 'FETCH_DELETE_CONTACTS_SUCCESS',
                payload: extendedContat,
              })
            }
          )
          break
        }
        case 'hold': {
          getExtendedContactDetailsList(requestId, action, _page, size, scope).then(
            (extendedContat) => {
              //console.log('hold getExtendedContactDetailsList Resp', extendedContat)

              dispatch({
                type: 'FETCH_HOLD_CONTACTS_SUCCESS',
                payload: extendedContat,
              })
            }
          )
          break
        }
        case 'todo': {
          getExtendedContactDetailsList(requestId, action, _page, size, scope).then(
            (extendedContat) => {
              //console.log('todo getExtendedContactDetailsList Resp', extendedContat)
              dispatch({
                type: 'FETCH_TODO_CONTACTS_SUCCESS',
                payload: extendedContat,
              })
            }
          )
          break
        }
      }

      // TODO - gestire e capire come farlo, la parte degli errori

      // getRequestContacts({ requestId, action, page: _page, size })
      //   .then((response) => {
      //
      //   })
      //   .catch((err) => {
      //     switch (action) {
      //       case 'delete':
      //         dispatch({ type: 'FETCH_DELETE_CONTACTS_FAILURE', payload: err })
      //         break
      //       case 'todo':
      //         dispatch({ type: 'FETCH_TODO_CONTACTS_FAILURE', payload: err })
      //         break
      //     }
      //
      //     console.error(err)
      //   })
    },
  getAllContacts: (requestId: number, scope: NicScope) => async (dispatch: React.Dispatch<any>) => {
    await actions.getContacts(requestId, 'todo', 0, 5, scope)(dispatch)
    await actions.getContacts(requestId, 'delete', 0, 5, scope)(dispatch)
    await actions.getContacts(requestId, 'hold', 0, 5, scope)(dispatch)
  },
  changeContactAction:
    (requestId: number, contactId: number, action: Darwin.ContactActions, scope: NicScope) =>
    async (dispatch: React.Dispatch<Action>) => {
      switch (action) {
        case 'delete':
          dispatch({ type: 'LOAD_DELETE_CONTACT', payload: contactId })
          break
        case 'todo':
          dispatch({ type: 'LOAD_TODO_CONTACT', payload: contactId })
          break
        case 'hold':
          dispatch({ type: 'LOAD_HOLD_CONTACT', payload: contactId })
          break
      }
      RequestContactChangeActionService(contactId, { action }, scope)
        .then((response: AxiosResponse) => {
          //console.log('Change action Resp', response)
          switch (action) {
            case 'delete':
              dispatch({ type: 'DELETE_CONTACT_SUCCESS', payload: response?.data })
              break
            case 'todo':
              dispatch({ type: 'TODO_CONTACT_SUCCESS', payload: response?.data })
              break
            case 'hold':
              dispatch({ type: 'HOLD_CONTACT_SUCCESS', payload: response?.data })
              break
          }
          actions.getAllContacts(requestId, scope)(dispatch)
        })
        .catch((err: AxiosError) => {
          console.debug('Change action EEERRR', JSON.stringify(err))

          switch (action) {
            case 'delete':
              dispatch({ type: 'DELETE_CONTACT_FAILURE', payload: err?.response?.data })
              break
            case 'todo':
              dispatch({ type: 'TODO_CONTACT_FAILURE', payload: err?.response?.data })
              break
            case 'hold':
              dispatch({ type: 'HOLD_CONTACT_FAILURE', payload: err?.response?.data })
              break
          }
          console.error(err)
        })
    },
  changeContactsAction:
    (requestId: number, action: Darwin.ContactActions, scope: NicScope) =>
    async (dispatch: React.Dispatch<Action>) => {
      switch (action) {
        case 'delete':
          dispatch({ type: 'LOAD_DELETE_ALL_CONTACTS' })
          break
        case 'todo':
          dispatch({ type: 'LOAD_TODO_ALL_CONTACTS' })
          break
        case 'hold':
          dispatch({ type: 'LOAD_HOLD_ALL_CONTACTS' })
          break
      }
      RequestContactsChangeActionService(requestId, { action }, scope)
        .then((response: AxiosResponse) => {
          console.debug('Change contacts action Resp', response)
          switch (action) {
            case 'delete':
              dispatch({ type: 'DELETE_ALL_CONTACTS_SUCCESS', payload: response.data })
              break
            case 'todo':
              dispatch({ type: 'TODO_ALL_CONTACTS_SUCCESS', payload: response.data })
              break
            case 'hold':
              dispatch({ type: 'HOLD_ALL_CONTACTS_SUCCESS', payload: response.data })
              break
          }
          actions.getAllContacts(requestId, scope)(dispatch)
        })
        .catch((err: AxiosError) => {
          console.debug('Change action EEERRR', JSON.stringify(err))

          switch (action) {
            case 'delete':
              dispatch({ type: 'DELETE_ALL_CONTACTS_FAILURE', payload: err?.response?.data })
              break
            case 'todo':
              dispatch({ type: 'TODO_ALL_CONTACTS_FAILURE', payload: err?.response?.data })
              break
            case 'hold':
              dispatch({ type: 'HOLD_ALL_CONTACTS_FAILURE', payload: err?.response?.data })
              break
          }
          console.error(err)
        })
    },
}

const [ctx, Provider] = createActionsCtx(reducer, initialState, actions)
export const ContactsContext = ctx
export const ContactsProvider = Provider
export const ContactsConsumer = ctx.Consumer

// TODO esportare su API?
/***
 * Partendo da una lista di contatti Darwin viene
 * generata una lista di contatti Darwin con aggregati :
 * - dettaglio preso dalle API nic
 * - conteggio dei domini associati al registrante
 *
 * @param requestId
 * @param action
 * @param page
 * @param size
 * @param scope
 */
const getExtendedContactDetailsList = async (
  requestId: number,
  action: Darwin.ContactActions,
  page: number,
  size: number,
  scope: NicScope
) => {
  const requestContactList = await RequestContactsService({ requestId, action, page, size }, scope)
    .then((response) => response.data)
    .catch((err) => {
      console.error(err)
    })

  const contactDomainsCount = (contactId: number) =>
    RequestDomainCountService(
      {
        contactId,
      },
      scope
    )
      .then((response) => response.data)
      .catch((err) => {
        console.error(err)
      })

  /**
   * Il metodo contactByIdService, putroppo non funziona per entrambi i profili con lo stesso id.
   * Per il profilo registry vuole il valore del dbnaId mentre per il profilo registrar vuole il darwinId.
   * Periò è stato necessario fare questo metodo che switcha i due valori a seconda del profilo
   * **/
  const contactById = (_contact: Darwin.Contact, _scope: NicScope) =>
    ContactByIdService(scope === 'registrar' ? _contact.id : _contact.dbnaId, _scope)
      .then((response) => response.data)
      .catch((err) => {
        console.error(err)
      })

  const arrOfPromises = (requestContactList as Darwin.Contacts).elements?.map(async (_contact) => {
    const contactByIdResult = await contactById(_contact, scope)
    const contactDomainsCountResult = await contactDomainsCount(_contact.id)

    return {
      ...contactByIdResult,
      ..._contact,
      darwinId: _contact.id,
      domains: contactDomainsCountResult.count,
    }
  })

  // return { ...response.data, ..._contact, darwinId: _contact.id, domains: domainsCount.count }

  const contactsDetails = await Promise.all(arrOfPromises)

  return new Promise((resolve) =>
    resolve({ page: (requestContactList as Darwin.Contacts).page, elements: contactsDetails })
  )
}
