import { Progress, Tooltip } from 'antd'
import { VerticalLayout } from '@nic/ui-comps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import TimeTag from '../../Various/TimeTag/TimeTag'
import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ProgressType } from 'antd/lib/progress/progress'
import { useTranslation } from '@nic/commons'

export interface IRequestProgress {
  creationDate: string
  expireDays?: number
  duration: number
  paused: boolean
}

/***
 * Barra di progresso che indica lo stato di avenzamento verso la chiusra
 * @param p
 * @constructor
 */
export function RequestProgressView(p: {
  remainingDays: number
  isPaused: boolean
  percentage: number
  isOverLabel: string
  type?: ProgressType
}) {
  const t = useTranslation()

  const currentReqProgressStatus = p.percentage < 100 ? (p.isPaused ? 'pause' : 'active') : 'over'
  return (
    <Tooltip
      placement="top"
      title={t(`requestProgressView.${currentReqProgressStatus}`, {
        remainingDays: p.remainingDays,
      })}
    >
      <Progress
        strokeColor={requestValidityStatusColor(p.isPaused, p.remainingDays)}
        style={{ marginBottom: 20 }}
        type={p.type}
        percent={p.percentage}
        format={() =>
          p.percentage < 100 ? (
            <FontAwesomeIcon
              icon={(p.isPaused ? faPause : faPlay) as IconProp}
              style={{ color: requestValidityStatusColor(p.isPaused, p.remainingDays) }}
            />
          ) : (
            <span style={{ color: requestValidityStatusColor(p.isPaused, p.remainingDays) }}>
              {' '}
              {p.isOverLabel}
            </span>
          )
        }
        showInfo={true}
      />{' '}
    </Tooltip>
  )
}

/***
 * Label + indicazione temporale
 * @param p
 * @constructor
 */
export function TimeLabel(p: {
  label: string
  date: string
  color: string
  isOver: boolean
  hideTime?: boolean
}) {
  return (
    <RequestProgressLabel
      label={p.label}
      isOver={p.isOver}
      childrenStyle={{ color: p.isOver ? 'red' : '' }}
      children={
        <TimeTag
          created={p.date}
          color={p.color}
          fontWeight={700}
          bordered={true}
          hideTime={p.hideTime}
        />
      }
    />
  )
}

export interface IRequestProgressLabel {
  label: string
  isOver?: boolean
  childrenStyle?: React.CSSProperties
}

export function RequestProgressLabel(props: React.PropsWithChildren<IRequestProgressLabel>) {
  return (
    <VerticalLayout>
      <div>
        <b>{props.label}</b>
      </div>
      <div style={{ margin: '0 auto', ...props.childrenStyle }}>{props.children}</div>
    </VerticalLayout>
  )
}

/***
 * Label + indicazione numerica
 * @param p
 * @constructor
 */
export function DaysLeft(p: { label: string; remainingDays: number; isPaused: boolean }) {
  return (
    <RequestProgressLabel
      label={p.label}
      childrenStyle={{
        color: requestValidityStatusColor(p.isPaused, p.remainingDays),
      }}
      children={<div className={'days-left'}>{p.remainingDays > 0 ? p.remainingDays : 0}</div>}
    />
  )
}

/***
 * Colori per gestire gli stati della richiesta {paused, active, warning}
 * @param isPaused
 * @param remainingDays
 */
export function requestValidityStatusColor(isPaused: boolean, remainingDays: number) {
  const pausedColor = '#c6c6c6'
  const activeColor = '#1890FF'
  const warnColor = '#FF4D4F'
  return isPaused ? pausedColor : remainingDays > 5 ? activeColor : warnColor
}
