import { useContext } from 'react'
import { ReferenceContext } from '../Providers/ReferenceProvider'
import { ContactUpdatedContext } from '../Providers/ContactUpdatedProvider'
import { contactUpdatesBuilder } from './helpers'
import { RegistrantLoaderProps } from './types'

export const RegistrantCompletedData: React.FC<RegistrantLoaderProps> = ({ children }) => {
  const { stateReferenceDarwin, stateContactAnomalies } = useContext(ReferenceContext)
  const { state: stateContactUpd } = useContext(ContactUpdatedContext)
  const noContactUpdateData = stateContactUpd.error?.response?.data.code === 1012

  const loading =
    stateContactAnomalies.loading || stateContactUpd.loading || stateReferenceDarwin.loading
  const error = !noContactUpdateData && stateContactUpd.error

  const data = contactUpdatesBuilder(stateContactUpd.data, stateContactAnomalies.data)

  return children({ data, loading, error })
}

/*** UDATED **/
// error={
//   stateContactAnomalies.error ||
//       (!noContactUpdateData && stateContactUpd.error) ||
//       stateReferenceDarwin.error
// }
// loading={
//   stateContactAnomalies.loading || stateReferenceDarwin.loading || stateContactUpd.loading
// }

/*** CANDIDATE **/
// error={stateContactAnomalies.error || stateCandidate.error}
// loading={loading}

/*** REFERENCE **/
// error={stateContactAnomalies.error || stateContactUpd.error || stateReferenceDarwin.error}
// loading={loading}

/** COMPLETED **/
// error={!noContactUpdateData && stateContactUpd.error}
// loading={loading}
