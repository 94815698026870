import React from 'react'
import { Button } from 'antd'
import { Can, useRouter, useTranslation } from '@nic/commons'
import { stringify } from 'qs'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { ButtonProps } from 'antd/lib/button/button'

export interface IButtonsRequestStatusViewProps {
  style?: React.CSSProperties
}

export const ButtonsRequestStatusView: React.FC<IButtonsRequestStatusViewProps> = (props) => {
  const STATUS = [
    'pending',
    'registrarUpdated',
    'completed',
    'submitted',
    'rejected',
    'expired',
    'cancelled',
    //'processing',
    //'processingExpired',
    //'processingCancelled'
  ]

  const [showMore, setShowMore] = React.useState(false)
  return (
    <div>
      <ButtonRequestStatusViewAll requestStatusView={'all'} onClick={() => setShowMore(false)} />
      {STATUS.slice(0, showMore ? STATUS.length : 2).map((statusView, i) => (
        <ButtonRequestStatusView
          requestStatusView={statusView}
          key={i}
          onClick={() => setShowMore(!['pending', 'registrarUpdated'].includes(statusView))}
        />
      ))}
      {!showMore && (
        <Button type={'link'} onClick={() => setShowMore(true)}>
          ...
        </Button>
      )}
      <br />
    </div>
  )
}

interface IButtonRequestStatusView extends ButtonProps {
  requestStatusView: string
}

function ButtonRequestStatusView(props: IButtonRequestStatusView) {
  const { query, pathname } = useRouter()
  const t = useTranslation()
  return (
    <Can I={props.requestStatusView} a={'ViewRequests'}>
      <Button {...props} disabled={query.status === props.requestStatusView}>
        <Link to={pathname + '?' + stringify({ ...query, status: props.requestStatusView })}>
          {t('requestsStatus.' + props.requestStatusView)}
        </Link>
      </Button>
    </Can>
  )
}

function ButtonRequestStatusViewAll(props: IButtonRequestStatusView) {
  const { query, pathname } = useRouter()
  const _query = _.omit(query, 'status')
  const t = useTranslation()
  return (
    <Can I={props.requestStatusView} a={'ViewRequests'}>
      <Button {...props} disabled={query.status === undefined}>
        <Link to={pathname + '?' + stringify({ ..._query })}>
          {t('requestsStatus.' + props.requestStatusView)}
        </Link>
      </Button>
    </Can>
  )
}
