import { Input, Modal } from 'antd'
import React, { useContext } from 'react'
import { Registrar } from '@nic/ui-antd'
import { RequestContext } from '../../Contexts/RequestContext'
import { RequestPostDeleteService } from '@nic/nic-api'
import { useTranslation } from '@nic/commons'
import { ErrorAlert } from '../../ErrorAlert/ErrorAlert'

const { TextArea } = Input

export function DeleteRequestModal(props: {
  showModalDelete: boolean
  setShowModalDelete: (value: ((prevState: boolean) => boolean) | boolean) => void
  onOk: () => void
}) {
  const [note, setNote] = React.useState<string>()
  const { stateReqById, id } = useContext(RequestContext)
  const t = useTranslation()
  // TODO Capire perche non funziona il RegistryVariationProvider dato andrebbe usato quello a favore
  // dei 3 useState sotto con i tra valori (loading, rejectSucess, rejectFail}
  const [loading, setLoading] = React.useState<boolean>()
  const [deleteSuccess, setDeleteSuccess] = React.useState()
  const [deleteFail, setDeleteFail] = React.useState<any>()

  React.useEffect(() => {
    setDeleteFail(undefined)
  }, [deleteSuccess])

  const handleOnSubmit = async () => {
    setLoading(true)
    try {
      const rej = await RequestPostDeleteService(id, note)
      setDeleteSuccess(rej)
      setLoading(false)
      props.onOk()
    } catch (e) {
      setLoading(false)
      setDeleteFail(e)
    }
  }

  return (
    <Modal
      title={<b>{t('deleteRequestModal.modalTitle')}</b>}
      visible={props.showModalDelete}
      destroyOnClose
      onOk={handleOnSubmit}
      okButtonProps={{ loading, disabled: deleteFail }}
      onCancel={() => {
        setDeleteFail(undefined)
        props.setShowModalDelete(!props.showModalDelete)
      }}
    >
      <p>
        {`${t('deleteRequestModal.p1.p1')} `}
        <b>{`${t('deleteRequestModal.p1.bold1')} `}</b>
        {`${t('deleteRequestModal.p1.p2')} `} <b>{id}</b>
        {' - '}(
        <b>
          {stateReqById?.data?.registrarId && (
            <Registrar
              id={stateReqById?.data?.registrarId}
              scope={'registry'}
              render={(r) => r.name}
            />
          )}
        </b>
        )
      </p>
      {`${t('deleteRequestModal.addNote')}:`}

      <TextArea rows={4} onChange={(e) => setNote(e.target.value)} />
      {deleteFail && (
        <div style={{ marginTop: 10 }}>
          <ErrorAlert error={deleteFail} />
        </div>
      )}
    </Modal>
  )
}
