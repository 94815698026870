import React from 'react'

export const anomalyFoundStyle: React.CSSProperties = {
  color: 'red',
  backgroundColor: '#f9e3e4',
  fontWeight: 'bold',
  padding: '3px 10px',
  border: '1px solid #f4555a',
  borderRadius: '15px',
}

export const emphasizeAnomalyStyle: React.CSSProperties = {
  color: 'green',
  backgroundColor: '#e3f9e4',
  fontWeight: 'bold',
  padding: '3px 10px',
  border: '1px solid #6afc6e',
  borderRadius: '15px',
}

export const anomalyEditedStyle: React.CSSProperties = {
  color: 'orange',
  backgroundColor: '#f9eaca',
  fontWeight: 'bold',
  padding: '3px 10px',
  border: '1px solid rgb(246, 207, 125)',
  borderRadius: '15px',
}
