import React, { useContext } from 'react'
import { Suspense, useTranslation } from '@nic/commons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { RequestContext } from '../../../Contexts/RequestContext'
import { TabTitle } from '../../../Various/TabTitle/TabTitle'
import { ContactSelection } from '../EditTab/ContentStep/ContactSelectionStep'
import { CustomTabPane } from '../CustomTabPane'
import { PaperLayout, VerticalLayout } from '@nic/ui-comps'
import { Skeleton, TabPaneProps } from 'antd'

/**
 * Tab dei contatti, mostra una lista e un dettaglio del contatto selezionato
 * @constructor
 */
export function ContactsTab(props: TabPaneProps) {
  const { stateReqContactsCount } = useContext(RequestContext)
  const t = useTranslation()

  return (
    <CustomTabPane
      divStyle={{ padding: 10 }}
      data-cy={'tabContacts'}
      tab={
        <Suspense
          state={stateReqContactsCount}
          spinner={<Skeleton.Button />}
          render={(data) => (
            <TabTitle iconType={faUsers} text={t('tabContacts.title', { contacts: data?.count })} />
          )}
        />
      }
      key={props.tabKey}
    >
      <VerticalLayout>
        <PaperLayout>
          <ContactSelection />
        </PaperLayout>
      </VerticalLayout>
    </CustomTabPane>
  )
}
