import { contactAnomaliesUpdatesBuilder } from './helpers'
import { RegistrantLoaderProps } from './types'
import { IDarwinContactExtended } from '../ContactListFullDetailed/ContactListFullDetailed'
import { Darwin, IFetchState } from '@nic/nic-api/types'

export const RegistrantSelectedData: React.FC<
  RegistrantLoaderProps & {
    contactData: IDarwinContactExtended
    stateContactAnomalies: IFetchState<Darwin.Anomalies, any>
  }
> = ({ children, stateContactAnomalies, contactData }) => {
  const loading = stateContactAnomalies.loading
  const error = stateContactAnomalies.error
  const data = contactAnomaliesUpdatesBuilder(contactData, undefined, stateContactAnomalies.data)

  return children({ data, loading, error })
}

/*** UDATED **/
// error={
//   stateContactAnomalies.error ||
//       (!noContactUpdateData && stateContactUpd.error) ||
//       stateReferenceDarwin.error
// }
// loading={
//   stateContactAnomalies.loading || stateReferenceDarwin.loading || stateContactUpd.loading
// }

/*** CANDIDATE **/
// error={stateContactAnomalies.error || stateCandidate.error}
// loading={loading}

/*** REFERENCE **/
// error={stateContactAnomalies.error || stateContactUpd.error || stateReferenceDarwin.error}
// loading={loading}

/** COMPLETED **/
// error={!noContactUpdateData && stateContactUpd.error}
// loading={loading}
