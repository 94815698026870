import {
  IGetRequestsExpiringParams,
  IGetRequestsParams,
  IRequestEvents,
  RequestContactCountService,
  RequestDomainCountService,
  RequestEventsService,
  RequestsService,
  RequestsServiceExpiring,
} from '@nic/nic-api'
import { Darwin, IPage, NicScope } from '@nic/nic-api/types'
import React from 'react'
import { useHistory } from 'react-router'
import axios, { AxiosError, AxiosResponse } from 'axios'

/**
 * Hook per caricare le richieste
 * @param queryParams
 * @param scope
 */
export function useRequestTable(
  queryParams: IGetRequestsParams &
    IGetRequestsExpiringParams & {
      reload: boolean
      referenceContactOrg: string
      referenceContactRegcode: string
    },
  scope: NicScope
) {
  const [elements, setElements] = React.useState<Darwin.Request[]>([])
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<AxiosError>()
  const [page, setPage] = React.useState<IPage>()
  let history = useHistory()

  React.useEffect(
    () => {
      const fetchAssociatedDomains = (id: number) => {
        try {
          const domains = RequestDomainCountService({ requestId: id }, scope)
          return { domains }
        } catch (e) {
          throw new Error('fetchAssociatedDomains error with id:' + id)
        }
      }
      const fetchAssociatedContacts = (id: number) => {
        try {
          const contacts = RequestContactCountService({ requestId: id }, scope)
          return { contacts }
        } catch (e) {
          throw new Error('fetchAssociatedContacts error with id:' + id)
        }
      }
      const fetchAssociatedEvents = (params: IRequestEvents) => {
        try {
          const events = RequestEventsService(params, scope)
          return { events }
        } catch (e) {
          throw new Error('fetchAssociatedEvents error with id:' + params.requestId)
        }
      }

      const buildCustomRequestsList = async (response: AxiosResponse<Darwin.Requests>) => {
        return await Promise.all(
          response.data.elements.map(async (request: Darwin.Request) => {
            const associatedDomains = fetchAssociatedDomains(request.id)
            const associatedContacts = fetchAssociatedContacts(request.id)
            const associatedEvents = fetchAssociatedEvents({ requestId: request.id, size: 100 })
            const domains = await associatedDomains.domains
            const contacts = await associatedContacts.contacts
            const events = await associatedEvents.events

            return {
              ...request,
              domains: domains.data?.count,
              contacts: contacts.data?.count,
              events: events.data.elements && events.data.elements,
            }
          })
        ).catch((e) => {
          throw new Error(
            'BuildCustomRequestsList found an error - ' + JSON.stringify((e as any).message)
          )
        })
      }

      const aggregateRequestsData = async (requestsPromise: Promise<any>) => {
        setElements([])
        setError(undefined)
        setLoading(true)
        try {
          const requests = await requestsPromise
          const aggregated = await buildCustomRequestsList(requests)
          setPage(requests.data.page)
          setElements(aggregated)
          setLoading(false)
        } catch (e) {
          console.error((e as any).message)
          if (axios.isAxiosError(e)) {
            setError(e as AxiosError)
          } else {
            setError((e as any).message)
          }
          setLoading(false)
        }
      }

      /** Expiring non è uno stato ma per compatibilità con le schermate 'contatore' è stato inserito in questa ricerca */
      if (history.location.pathname.includes('expiring') || queryParams.days) {
        aggregateRequestsData(RequestsServiceExpiring(queryParams, scope))
      } else {
        aggregateRequestsData(RequestsService(queryParams, scope))
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      queryParams.registrarId,
      queryParams.registrarAssignation,
      queryParams.registryAssignation,
      queryParams.referenceContactOrg,
      queryParams.referenceContactRegcode,
      queryParams.status,
      queryParams.userId,
      queryParams.page,
      queryParams.days,
      queryParams.size,
      queryParams.reload,
      history.location.pathname,
      scope,
    ]
  )

  return {
    loading,
    requests: { page, elements },
    error,
  }
}
