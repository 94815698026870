import { Button, Space, Modal } from 'antd'
import { QuestionCircleTwoTone } from '@ant-design/icons'
import React, { useContext } from 'react'
import { VariationContext } from '../../../Providers/VariationProvider'
import { useTranslation } from '@nic/commons'
import { Flex, Div } from '@nic/ui-comps'

export function VariationProposalButtons(props: {
  onCancel: () => void
  onPrev: () => void
  onNext: () => void

  disabledPrev?: boolean
  disabledNext?: boolean
}) {
  const FIRST = 0
  const LAST = 4
  const t = useTranslation()
  const { dispatch, state } = useContext(VariationContext)

  const handleOnCancel = () => {
    props.onCancel()
    dispatch({ type: 'EDIT_OFF' })
  }

  const handleNext = () => {
    props.onNext()
  }

  const handlePrev = () => {
    props.onPrev()
  }

  const [confirmModal, setConfirmModal] = React.useState(false)

  return (
    <Space>
      {state.step > FIRST && state.step < LAST && (
        <Button onClick={handlePrev} disabled={props.disabledPrev}>
          {t('Previous')}
        </Button>
      )}
      {state.step < LAST - 1 && (
        <Button onClick={handleNext} disabled={props.disabledNext} type={'primary'}>
          {t('Next')}
        </Button>
      )}
      {state.step === LAST - 1 && (
        <>
          <Button
            onClick={() => setConfirmModal(true)}
            disabled={props.disabledNext}
            type={'primary'}
          >
            {t('Done')}
          </Button>
          <Modal
            title={t('message.info.confirmChanges.title')}
            visible={confirmModal}
            onOk={handleNext}
            okText={t('button.ConfirmSend')}
            cancelText={t('Cancel')}
            onCancel={() => {
              setConfirmModal(false)
            }}
          >
            <Flex direction={'row'} justify={'space-around'}>
              <QuestionCircleTwoTone style={{ fontSize: '20pt' }} />
              <Div fontSize={'14pt'}>{t('message.info.confirmChanges.message')}</Div>
            </Flex>
          </Modal>
        </>
      )}
      {state.step < LAST && <Button onClick={handleOnCancel}>{t('Cancel')}</Button>}
    </Space>
  )
}
