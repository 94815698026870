import { Darwin, Domains, NicArray, NicScope } from '@nic/nic-api/types'
import { camelToDash, Suspense } from '@nic/commons'
import { Div, PaperLayout } from '@nic/ui-comps'
import React from 'react'
import './ContactListFullDetailed.css'
import { DarwinHelpers } from '@nic/nic-api'
import {
  requestContactAnomaliesInitialState,
  requestContactAnomaliesReducer,
} from '@nic/nic-api/build/Reducer/Darwin/ContactAnomalies'
import { ContactsTable, ContactTableBaseProp } from '../DataDisplay/ContactsTable/ContactsTable'
import { useGetDomainsFromContactId } from '../RequestView/hooks/useGetDomainsFromContactId'
import { ContactDomainsTable } from '../DataDisplay/ContactDomainsTable/ContactDomainsTable'
import { PaperTitle } from '../Layout/PaperTitle'
import { SelectedRegistrantCard } from '../Cards/SelectedRegistrantCard/SelectedRegistrantCard'
import { DetailResultNotification } from '../Feedback/DetailResultNotification'

export interface ContactListFullDetailedProp extends ContactTableBaseProp {
  /** lista dei contatti **/
  contacts?: NicArray<IDarwinContactExtended>
  /** anomalie associate alla richiesta e da visualizzare durante le rendere dei dati con del contatto */
  anomalies?: Darwin.Anomalies
  /** evento che si scatena al click della riga della lista contatti */
  onClickContactTableRow?: (contact: Domains.Contact) => void
  /** evento che si scatena al click della riga della lista contatti */
  // onRenderContactDetailRow?: (value: any, field: string, anomalyField: string) => void
  /** contatto selezionato */
  selectedContact?: IDarwinContactExtended
  /** titolo della card del componente, viene mostrato solo se presente*/
  title?: React.ReactNode
  /** Descrizione visualizzata sotto il titolo*/
  description?: React.ReactNode
  /** titolo della card dettaglio contatto */
  contactDetailTitle?: React.ReactNode
  /** titolo della tabella lista domini del contatto */
  domainContactDetailTitle?: React.ReactNode
  /** colonna azioni visualizzata in corrispondenza di ogni contatto */
  actionColumn?: (record: IDarwinContactExtended) => JSX.Element[]
  /** indica se mostrare un card aggiuntiva nel dettaglio del contatto */
  showAdditionalContactDetailCard?: boolean
  /** contenuto della card aggiuntiva nel dettaglio del contatto  */
  additionalContactDetailCard?: React.ReactNode
}

export interface IDarwinContactExtended extends Domains.Contact, Darwin.Contact {
  domains: number
  darwinId: number
}

/**
 * Componente per visualizzare una lista di contatti ed alcune informazioni associate:
 * - lista contatti (con numero di domini associati ed azioni)
 * - dettaglio contatto ed eventuale contatto candidato
 *
 *
 * @param props
 * @constructor
 */

/**
 * NOTE:
 *
 * Probabilmente sarà necessario valutare in futuro (oggi - 20/10/2022) se sganciare i componenti BoxContactDetail e
 * BoxDomainTable al di fuori da questo componente e passargli a entrambi il contatto o il dominio di riferimento.
 * Avendo l'id di riferimento (contatto o dominio) entrambe le componenti possono ricavarsi
 * in autonomia l'informazione necessaria, dettaglio Dominio e dettaglio Contatto, a seconda del caso.
 * Si renderebbero così più indipendenti i contesti delle due componenti generando meno confusione
 * (e forse aumentando le performance. In verità non cambia nulla in questo senso credo)
 */
export function ContactListFullDetailed(props: ContactListFullDetailedProp) {
  const [selectedContact, setSelectedContact] = React.useState<IDarwinContactExtended | undefined>(
    props.selectedContact
  )
  const [stateContactAnomalies, dispatch] = React.useReducer(
    requestContactAnomaliesReducer,
    requestContactAnomaliesInitialState
  )

  const hasAdditionalContactCard = !!(
    props.showAdditionalContactDetailCard && props.additionalContactDetailCard
  )

  // Recupera info legate al numero dei domini associati al contatto
  const stateDomainsByRegistrant = useGetDomainsFromContactId(
    { registrantContactId: selectedContact?.contactId },
    props.scope
  )

  // azione gestita quando si seleziona un contatto dalla lista
  const handleOnClickContactRow = (contact: IDarwinContactExtended) => {
    setSelectedContact(contact)
    props.onClickContactTableRow && props.onClickContactTableRow(contact)
    contact.darwinId &&
      DarwinHelpers.getContactAnomalies(
        dispatch,
        contact.darwinId,
        { all: true },
        props.scope as NicScope
      )
  }

  const currentLoading = props.loading

  const BoxDomainsTable = () => (
    <Suspense
      state={stateDomainsByRegistrant}
      render={(data) => (
        <ContactDomainsTable data={data.elements} scope={props.scope} withSelector={false} />
      )}
    />
  )

  const ContactsTableMemoized = React.memo(ContactsTable)

  return (
    <Div className={camelToDash(ContactListFullDetailed.name)}>
      {props.title && <PaperTitle showIcon title={props.title} />}
      {props.description}
      {props.contacts?.elements && (
        <ContactsTableMemoized
          readonly={props.readonly}
          data={props.contacts?.elements}
          onRowClick={handleOnClickContactRow}
          scope={props.scope}
          selectedContactId={selectedContact?.contactId}
          actionColumn={props.actionColumn}
          loading={currentLoading}
          pagination={{
            current:
              props.contacts?.page.number !== undefined ? props.contacts?.page.number + 1 : 0,
            pageSize: props.contacts?.page.size,
            total: props.contacts?.page.totalElements,
          }}
          onChangePagination={props.onChangePagination}
        />
      )}
      <Div style={{ backgroundColor: '#fafafa', padding: '15px 15px 15px 15px' }}>
        {selectedContact ? (
          <>
            <SelectedRegistrantCard
              contactDetailTitle={props.contactDetailTitle}
              hasAdditionalContactCard={hasAdditionalContactCard}
              additionalContactDetailCard={props.additionalContactDetailCard}
              stateContactAnomalies={stateContactAnomalies}
              selectedContact={selectedContact}
            />
            <PaperLayout>
              {props.domainContactDetailTitle && (
                <PaperTitle showIcon title={props.domainContactDetailTitle} />
              )}
              <BoxDomainsTable />
            </PaperLayout>
          </>
        ) : (
          !props.readonly && <DetailResultNotification elements={props.contacts?.elements.length} />
        )}
      </Div>
    </Div>
  )
}
