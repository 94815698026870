import { Alert, Button, Form, FormInstance, Input } from "antd";
import React, { useContext } from "react";
import { VariationContext } from "../../../../../Providers/VariationProvider";
import { Div, Flex, PaperLayout } from "@nic/ui-comps";
import { ContactsContext } from "../../../../../Providers/ContactsProvider";
import { isEqual } from "lodash";
import { useTranslation } from "@nic/commons";
import { ConfirmationStepView } from "./ConfirmationStepView";
import { RequestContext } from "../../../../../Contexts/RequestContext";
import { Darwin } from "@nic/nic-api/types";
import { DocumentUploadFile } from "@nic/ui-antd";
import { IAnomaly } from "@nic/nic-api/types/Darwin/types";
import { PaperTitle } from "../../../../../Layout/PaperTitle";
import { ReferenceContext } from "../../../../../Providers/ReferenceProvider";
import { PaperLayoutTitled } from "../../../../../Various/PaperLayoutTitled/PaperLayoutTitled";

const { TextArea } = Input;

export function ConfirmationStep(props: {
  managed: boolean;
  handleChanges?: (c: boolean) => void;
  form: FormInstance;
}) {
  const { stateReferenceDarwin } = useContext(ReferenceContext);
  const { state: stateVariation } = useContext(VariationContext);
  const { stateReqAnomalies } = useContext(RequestContext);
  const { documents } = stateVariation;
  const {
    state: { toHold, toDelete, toDo },
  } = useContext(ContactsContext);
  const [, setNote] = React.useState<string>();
  const [values, setValues] = React.useState<any>({
    elements: [],
    noChanges: true,
  });
  const [unmodifiedAnomalies, setUnmodifiedAnomalues] = React.useState<
    string[]
  >([]);

  const t = useTranslation();

  const contactsToUpdate = React.useMemo(
    () => toDo.elements.map((c) => c.registrarContactId),
    [toDo]
  );
  const contactsToDelete = React.useMemo(
    () => toDelete.elements.map((c) => c.registrarContactId),
    [toDelete]
  );
  const contactsToHold = React.useMemo(
    () => toHold.elements.map((c) => c.registrarContactId),
    [toHold]
  );

  const checkAnomaliesChanges = (
    changes: Record<string, boolean>,
    anomalies?: Darwin.Anomalies
  ) => {
    let res: string[] = [];
    if (anomalies !== undefined) {
      anomalies.forEach((a: IAnomaly) => {
        switch (a.field) {
          case "address":
            // Campo address in realtà è composto da
            // streets, city, state, country, postalcode
            const subFields = ["streets", "city", "state", "postalCode"];
            let atLeastOne = false;
            subFields.forEach((f: string) => {
              atLeastOne = atLeastOne || changes[f];
            });

            if (!atLeastOne) {
              res.push(a.field);
            }

            break;
          case "regcode":
            if (!changes[a.field] && !changes.org) {
              res.push(a.field);
            }
            break;
          case "org":
            if (!changes[a.field] && !changes.regcode) {
              res.push(a.field);
            }
            break;
          default:
            if (!changes[a.field]) {
              res.push(a.field);
            }
            break;
        }
      });
    }
    return res;
  };

  // TODO avento applicato un componente (cioè EditedRegistrantCard) e non più
  // la card che mostrava le differenze affiancate, questa parte potra essere omessa
  // appena deciso quale delle due compoente verrà usata
  React.useEffect(() => {
    const v = Object.entries(stateVariation?.changes?.contactData).reduce(
      (previous: { elements: any[]; noChanges: boolean }, current) => {
        const originaleValue =
          // @ts-ignore
          (stateReferenceDarwin.data &&
            // @ts-ignore
            stateReferenceDarwin.data[current[0]]) ??
          // @ts-ignore
          stateReferenceDarwin.data?.registrant[current[0]];

        const isDifferent = Array.isArray(current[1])
          ? !isEqual(current[1], originaleValue)
          : current[1] !== originaleValue;
        return {
          elements: [
            ...previous.elements,
            {
              key: current[0],
              modifiedValue: current[1],
              isDifferent: isDifferent,
              originalValue: originaleValue,
            },
          ],
          noChanges: previous.noChanges && !isDifferent,
        };
      },
      { elements: [], noChanges: true }
    );

    const fieldMap: Record<string, boolean> = {};

    v.elements.forEach((e: any) => {
      //console.log('key: ', e.key)
      if (e.key === "registrant") {
        fieldMap.regcode = e.modifiedValue.regcode !== e.originalValue.regcode;
      } else {
        fieldMap[e.key] = e.isDifferent;
      }
    });

    const modAnomalies: string[] = checkAnomaliesChanges(
      fieldMap,
      stateReqAnomalies.data
    );

    setUnmodifiedAnomalues(modAnomalies);

    setValues(v);
    /* Se non ci sono modifiche e il vettore anomalie è ancora pieno mostra warning */
    if (
      (v.noChanges || modAnomalies.length > 0) &&
      props.handleChanges !== undefined &&
      !props.managed
    ) {
      props.handleChanges(false);
      /* Se il vettore anomalie è vuoto allora sblocca pulsante FATTO */
    } else if (modAnomalies.length === 0 && props.handleChanges !== undefined){
      props.handleChanges(true);
    }
  }, [
    stateVariation.changes.contactData,
    stateReferenceDarwin,
    props,
    stateReqAnomalies.data,
  ]);

  const variationDocuments =
    documentUploadFile2DarwinUploadedFileResponse(documents);
  //console.log('Values: ', values, values.noChanges)
  return (
    <PaperLayout>
      <PaperTitle showIcon title={t("confirmationStepView.title")} />
      <Div padding={"10px"}>
        <Alert
          type={"info"}
          message={t("message.info.changes")}
          showIcon
          style={{ marginBottom: 20 }}
        />
        {values.elements && (
          <ConfirmationStepView
            values={values}
            toHold={contactsToHold}
            toDelete={contactsToDelete}
            toUpdate={contactsToUpdate}
            referenceContact={stateReferenceDarwin.data}
            //TODO da aggiornare
            domainsToDelete={[]}
            domainsToHold={[]}
            documentList={variationDocuments}
            changes={stateVariation.changes}
            anomalies={stateReqAnomalies.data}
          />
        )}
        <PaperLayoutTitled title={t("confirmationStepView.message.title")}>
          <Form form={props.form}>
            <Form.Item name={"message"}>
              <TextArea
                rows={4}
                placeholder={t("confirmationStepView.message.placeholder")}
                maxLength={t("confirmationStepView.message.maxLength")}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Item>
          </Form>
        </PaperLayoutTitled>
        {
          // TODO Trasfomare in componente e rivedere cosa passare per eliminare tutto la parte che sta sotto UseEffect
          // che è al momento eliminabile.
          // Bug: quando viene modificato l'org non va segnalata come anomalia il regcode e viceversa.
          (values.noChanges || unmodifiedAnomalies.length > 0) && (
            <>
              <br />
              <Alert
                message={
                  <Div fontSize={"1.1rem"} fontWeight={"700"}>
                    {t("message.warning.title")}
                  </Div>
                }
                description={
                  <Flex
                    direction={"column"}
                    style={{ rowGap: "20px" }}
                    align={"flex-start"}
                  >
                    <Flex
                      direction={"column"}
                      align={"flex-start"}
                      justify={"flex-start"}
                    >
                      {values.noChanges && (
                        <Div>{t("message.warning.noChanges")}</Div>
                      )}
                      {unmodifiedAnomalies.length > 0 && (
                        <>
                          <Div marginTop={"15px"}>
                            {t("message.warning.unchangedAnomaly")}
                          </Div>
                          <Flex
                            direction={"column"}
                            align={"flex-start"}
                            justify={"flex-start"}
                          >
                            {unmodifiedAnomalies.map((a: string, i: number) => (
                              <Div
                                fontWeight={"700"}
                                marginLeft={"10px"}
                                key={i}
                              >
                                {t(`label.${a}`)}
                              </Div>
                            ))}
                          </Flex>
                          <Div
                            marginTop={"20px"}
                            fontWeight={"700"}
                            fontSize={"1.3rem"}
                          >
                            {t("message.warning.areUSure")}
                          </Div>
                        </>
                      )}
                    </Flex>
                    <Flex
                      direction={"row"}
                      align={"flex-end"}
                      justify={"flex-end"}
                      style={{ width: "100%" }}
                    >
                      <Button
                        size="small"
                        type="primary"
                        onClick={() => {
                          if (props.handleChanges !== undefined) {
                            props.handleChanges(true);
                          }
                        }}
                      >
                        {t("button.ConfirmSend")}
                      </Button>
                    </Flex>
                  </Flex>
                }
                type="warning"
                showIcon
              />
            </>
          )
        }
      </Div>
    </PaperLayout>
  );
}

function documentUploadFile2DarwinUploadedFileResponse(
  documents: DocumentUploadFile<Darwin.DarwinUploadedFileResponse>[]
): Darwin.DarwinUploadedFileResponse[] {
  return (
    documents
      .map((docTypeList) =>
        docTypeList?.fileList?.map((docTypeItem) => {
          return {
            id: docTypeItem?.response?.id,
            documentType: docTypeList.docType,
            name: docTypeItem.name,
            note: docTypeItem.response?.note,
            fileName: docTypeItem.response?.fileName,
            fileType: docTypeItem.response?.fileType,
            fileSize: docTypeItem.response?.fileSize,
          } as Darwin.DarwinUploadedFileResponse;
        })
      )
      .reduce((result, item) => {
        // @ts-ignore
        return [...result, ...item];
      }, [] as Darwin.DarwinUploadedFileResponse[]) || []
  );
}
