import React from 'react'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from '@nic/commons'
import { TabTitle } from '../../../Various/TabTitle/TabTitle'
import { CustomTabPane } from '../CustomTabPane'
import { RequestTabType } from '../../index'
import { TabPaneProps } from 'antd'
import { SummaryRequestTabController } from './SummaryRequestTabController'
import { useAssignmentRequest } from '../../../AssignmentRequests/useAssignmentRequest'

export interface SummaryRequestTabProp extends TabPaneProps {
  onChangeTab: (index: RequestTabType | string) => void
  onEdit?: (edit: boolean) => void
  onEditOff?: () => void
  edit?: boolean
  hasAssignmentRequestPermission?: boolean
  disabledEdit?: boolean
  tabKey: RequestTabType
}

/**
 * SummaryRequestTab, tab che visualizza il riepilogo delle informazioni della richiesta
 * @param props
 * @constructor
 */
export const SummaryRequestTab: React.FC<SummaryRequestTabProp> = (props) => {
  const t = useTranslation()
  const { isRegistrarAssignedUser, isRegistrarAssigned, isUserDomain, isUserAdmin } =
    useAssignmentRequest()
  const hasEditPermission =
    isRegistrarAssignedUser || isUserAdmin || !(isRegistrarAssigned && isUserDomain)
  // Guardare
  // https://stackoverflow.com/questions/54015086/how-to-prevent-child-component-from-re-rendering-when-using-react-hooks-and-memo

  return (
    <CustomTabPane
      tab={<TabTitle iconType={faCodeBranch} text={`${t('tabReqSummary.title')}`} />}
      divStyle={{ backgroundColor: '#fafafa', margin: '0 15px 15px 15px' }}
      key={props.tabKey}
    >
      <SummaryRequestTabController {...props} hasAssignmentRequestPermission={hasEditPermission} />
    </CustomTabPane>
  )
}
