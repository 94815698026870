import { ScheduleOutlined } from '@ant-design/icons'
import { Result } from 'antd'
import React from 'react'
import { useTranslation } from '@nic/commons'
import styled from "styled-components";

const StyledDetailResult = styled(Result)`
  &.detail > .ant-result-icon > .anticon {
    color: grey;
    font-size: 4rem;
  }
`

export function DetailResultNotification(props: { elements?: number }) {
  const t = useTranslation()

  if (props.elements !== undefined && props.elements > 0) {
    return (
      <StyledDetailResult
        className={'detail'}
        title={t('detailResultNotification.title')}
        subTitle={t('detailResultNotification.subTitle')}
        icon={<ScheduleOutlined />}
      />
    )
  }
  return <></>
}
