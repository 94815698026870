import { Button, Divider, Modal, Space } from 'antd'
import React from 'react'
import { useTranslation } from '@nic/commons'
import { ErrorAlert } from '../../ErrorAlert/ErrorAlert'
import { UserSelected, useSelectUserContext } from '../../Providers/SelectedUserContext'
import RegistrarUsersLookup from '../../RegistrarUsersLookup/RegistrarUsersLookup'

export function ModalRegistrarUserList(props: {
  showModalAccept: boolean
  setShowModalAccept: (value: ((prevState: boolean) => boolean) | boolean) => void
  onOk: (user: any) => void
}) {
  const t = useTranslation()
  const [loading] = React.useState<boolean>()
  const [acceptSuccess] = React.useState()
  const [acceptFail, setAcceptFail] = React.useState<any>()
  const [user, setUser] = React.useState<UserSelected>()
  const { selectedUsers, addSelectedUser } = useSelectUserContext()

  React.useEffect(() => {
    setAcceptFail(undefined)
  }, [acceptSuccess])

  const handleOnSubmit = () => {
    props.onOk(user)
    if (user?.id && user.username) {
      addSelectedUser({ id: user?.id, username: user.username })
    }
  }

  const handleOnSelect = (value: { key: number; value: string }) => {
    setUser({ id: value.key, username: value.value })
  }

  const handleClickListedUser = (user: UserSelected) => {
    props.onOk(user)
    setUser(user)
  }

  return (
    <Modal
      title={<b>{t('modalRegistrarUserList.modalTitle')}</b>}
      visible={props.showModalAccept}
      destroyOnClose
      okButtonProps={{ loading, disabled: acceptFail }}
      onOk={handleOnSubmit}
      okText={t('modalRegistrarUserList.associate')}
      onCancel={() => {
        setAcceptFail(undefined)
        props.setShowModalAccept(!props.showModalAccept)
      }}
    >
      <p>{`${t('modalRegistrarUserList.description')} `}</p>

      {acceptFail && (
        <div style={{ marginTop: 10 }}>
          <ErrorAlert error={acceptFail} />
        </div>
      )}

      <RegistrarUsersLookup onSelect={handleOnSelect} />
      {selectedUsers.length > 0 && (
        <>
          <div>{t('modalRegistrarUserList.fastAssociation')}:</div>
          <Space split={<Divider type={'vertical'} />}>
            {selectedUsers.map((selectedUser) => (
              <Button type={'link'} onClick={() => handleClickListedUser(selectedUser)}>
                {selectedUser.username}
              </Button>
            ))}
          </Space>
        </>
      )}
    </Modal>
  )
}
