import React from 'react'
import { Button, Divider, Dropdown, Menu, Space, Tag } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { AuthContext } from '@nic/kc-auth'
import { useTranslation } from '@nic/commons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faA, faD, faGlobe, faUser } from '@fortawesome/free-solid-svg-icons'
import Keycloak from 'keycloak-js'

interface IUserInfo {
  color?: string
}

const DARWIN_USER_ROLES = ['admin', 'darwin', 'domain', 'darwin-admin']
const registryRoles = (keycloak: Keycloak) => keycloak?.resourceAccess?.['darwin-service']?.roles
const registrarRoles = (keycloak: Keycloak) => keycloak?.realmAccess?.roles
const getUserFromToken = (keycloak?: Keycloak.KeycloakInstance) => {
  const parsed: any | undefined = keycloak?.tokenParsed as any | undefined
  return parsed ? parsed.name ?? parsed.preferred_username : ''
}

// interface DarwinRole {
//   role: string
//   assignationView: 'all' | 'signed' | 'assignedToMe' | 'unassigned'
// }

export function userDarwinRole(keycloak: Keycloak | undefined): string | undefined {
  console.log(userDarwinRole.name, 'token:', keycloak)

  /**
   * Cerca il ruolo nel token e vede se c'è il match
   * @param val
   * @param arr
   */
  function checkRole(val: string, arr?: string[]) {
    return arr?.some((arrVal) => val === arrVal)
  }

  if (keycloak?.realm === 'registrar') {
    if (checkRole('admin', registrarRoles(keycloak))) {
      return 'admin'
    } else if (checkRole('domain', registrarRoles(keycloak))) {
      return 'domain'
    } else if (checkRole('darwin', registrarRoles(keycloak))) {
      return 'darwin'
    } else {
      return undefined
    }
  } else if (keycloak?.realm === 'registry') {
    if (checkRole('darwin-admin', registryRoles(keycloak))) {
      return 'darwin-admin'
    } else {
      return undefined
    }
  }
  return undefined
}

export const UserInfo: React.FC<IUserInfo> = (props) => {
  const clr = props.color ?? 'white'
  const t = useTranslation()
  const { keycloak } = React.useContext(AuthContext)
  const username = getUserFromToken(keycloak)

  // console.log('keycloak?.realmAccess?.roles', keycloak?.realmAccess?.roles)
  const menuHandler = (value: any) => {
    console.debug('Value ', value)
    switch (value.key) {
      case 'logout':
        keycloak?.logout()
        break
      default:
        break
    }
  }

  const menu = (
    <Menu onClick={menuHandler}>
      <Menu.ItemGroup title={t('userRoles')}>
        <Menu.Item key={'hide-role'} disabled>
          {keycloak &&
            keycloak.realm === 'registrar' &&
            DARWIN_USER_ROLES.map((r) => registrarRoles(keycloak)?.includes(r) && <Tag>{r}</Tag>)}
          {keycloak &&
            keycloak.realm === 'registry' &&
            DARWIN_USER_ROLES.map((r) => registryRoles(keycloak)?.includes(r) && <Tag>{r}</Tag>)}
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Item key={'logout'}>{t('logout')}</Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu}>
      <Button type={'primary'} ghost style={{ color: `${clr}`, borderColor: `${clr}` }}>
        <Space>
          <RoleIcon />
          <Divider type={'vertical'} style={{ background: 'white' }} />
          <span>{username}</span>
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  )
}

function RoleIcon() {
  const { keycloak } = React.useContext(AuthContext)
  let role = userDarwinRole(keycloak)
  //TODO aggiungere hint alle icone
  switch (role) {
    case 'admin':
      return <FontAwesomeIcon icon={faA} />
    case 'darwin-admin':
      return <FontAwesomeIcon icon={faA} />
    case 'domain':
      return <FontAwesomeIcon icon={faGlobe} />
    case 'darwin':
      return <FontAwesomeIcon icon={faD} />
    default:
      return <FontAwesomeIcon icon={faUser} style={{ color: 'lightgray' }} />
  }
}
