import { ContactReportType } from '../types'
import { Flex } from '@nic/ui-comps'
import { Empty } from 'antd'
import React from 'react'
import { ContactReportDetailBox, KeyValueBoxProps } from './KeyValueDetailCard'

export interface RegistrantBoxProps {
  noDataDescription?: string
}

export function RegistrantBox(props: KeyValueBoxProps<ContactReportType> & RegistrantBoxProps) {
  if (!props.data) {
    return (
      <Flex direction={'row'} justify={'flex-end'}>
        <div style={{ float: 'right' }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={props.noDataDescription} />
        </div>
      </Flex>
    )
  }
  return <ContactReportDetailBox data={props.data} rows={props.rows} />
}
