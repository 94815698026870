import { ContactReportContent, ContactReportType } from '../types'
import React from 'react'
import { isArray, isEqual } from 'lodash'
import { useTranslation } from '@nic/commons'
import { RenderAnomaly } from '../../../Anomalies/Anomaly/RenderAnomaly'
import { anomalyEditedStyle } from '../styles'
import { PrintEditedAnomalyDescriptions } from '../../../Anomalies/Anomaly/PrintEditedAnomalyDescriptions'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

/***
 * Metodo Render in caso di anomalia
 * @param obj
 * @param element
 * @param fieldKeyGroup
 * @param defaultRender
 * @constructor
 */
export function RenderUpdatesWithAnomalies(
  obj: ContactReportType,
  element: ContactReportContent,
  fieldKeyGroup: string,
  defaultRender?: (obj: ContactReportType, element: ContactReportContent) => React.ReactNode
) {
  // permette la scelta del giusto titolo
  const handlePopOverTitle = (isEdited: boolean, isAnomaly: boolean, _field: string) => {
    if (!isAnomaly) {
      return isEdited ? t(`editedField`) : t(`notEditedField`)
    } else {
      return isEdited ? t(`editedAnomalyField`) : t(`notEditedAnomalyField`)
    }
  }

  // permette di capire se il campo è stato modificato o meno cosi da dare un feedback visivo
  const handleIsEdited = (): boolean => {
    let hasChanged

    if (isArray(element.update)) {
      const toCompare = isArray(element.defaultValue)
        ? element.defaultValue
        : [element.defaultValue]
      // isChanged = JSON.stringify(change) !== JSON.stringify(toCompare)
      hasChanged = !isEqual(element.update, toCompare)
    } else {
      hasChanged = element.update !== element.defaultValue
    }

    if (fieldKeyGroup === 'state') {
      hasChanged =
        JSON.stringify(obj.state.defaultValue).toString().substring(0, 3) !==
        JSON.stringify(element.update).toString().substring(0, 3)
    }

    /**
     * Si compara che il valore di change sia diverso da quello di value per
     * capire se ci sono stati cambiamenti
     *
     * Nel caso di array si comparano i due facendo la stringify dei due array
     * **/

    return hasChanged
  }

  const t = useTranslation()

  if (element.update !== undefined) {
    const renderFn = defaultRender ? defaultRender(obj, element.update) : element.update

    const isChanged = handleIsEdited()
    const isNotEditedCss = !isChanged && {
      fontWeight: 200,
      color: 'grey',
    }
    return (
      <RenderAnomaly
        value={renderFn}
        update={element.update}
        field={fieldKeyGroup as string}
        anomalyCssStyle={{ ...anomalyEditedStyle, ...isNotEditedCss }}
        popOverContent={PrintEditedAnomalyDescriptions(element.anomalies, element.defaultValue, t)}
        popOverTitle={handlePopOverTitle(isChanged, !!element.anomalies, fieldKeyGroup)}
        fontAwesomeIcon={faEdit}
        iconColor={'orange'}
        isAnomaly={!!element.anomalies}
      />
    )
  } else {
    return <>{defaultRender ? defaultRender(obj, element.defaultValue) : element.defaultValue}</>
  }
}
