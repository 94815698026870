import React from 'react'
import { useRouter, useTranslation } from '@nic/commons'
import { Button, Form, Input, Select } from 'antd'
import { stringify } from 'qs'
import { RegistrarSelect } from '@nic/ui-antd'
import {
  fetchRegistrarsByName,
  getRegistrarsByNameInitialState,
  getRegistrarsByNameReducer,
} from '@nic/nic-api'
import { NicScope } from '../../../../nic-react-monorepo/packages/nic-api/types'

export function RequestsSearchForm(props: { scope: NicScope }) {
  const [stateGetRegByName, dispatchGetRegByName] = React.useReducer(
    getRegistrarsByNameReducer,
    getRegistrarsByNameInitialState
  )
  const { pathname, query, history } = useRouter()
  const t = useTranslation()

  const { Option } = Select
  const [form] = Form.useForm()

  React.useEffect(() => {
    console.log('change ', query)

    form.resetFields()
    form.setFieldsValue(query)
  }, [form, query])

  const onFinish = (values: { registrar: any; mai: string }) => {
    // console.log('OnFinish', JSON.stringify(values))
    history.push(pathname + '?' + stringify({ ...(query as any), ...values }))
  }

  const onFinishFailed = (errorInfo: any) => {
    console.error('Failed:', errorInfo)
  }

  const handleOnSearch = (value: string) => {
    fetchRegistrarsByName(dispatchGetRegByName, { name: value })
  }

  const handleOnClear = () => {
    dispatchGetRegByName({ type: 'REGISTRARS_BYNAME_CLEAN' })
  }

  const searchStatusLabels: string[] = [
    'completed',
    'expired',
    'pending',
    'processing',
    'processingExpired',
    'registrarUpdated',
    'rejected',
    'submitted',
    'cancelled',
  ]
  return (
    <Form
      layout="inline"
      form={form}
      name={'search'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      style={{ width: '100%' }}
    >
      {props.scope === 'registry' && (
        <>
          <Form.Item
            label="Registrar"
            name="registrarId"
            style={{ marginBottom: '16px', width: 400 }}
          >
            <RegistrarSelect
              placeholder={t('search.placeholder.registrar')}
              loading={stateGetRegByName.loading}
              onClear={handleOnClear}
              onSearch={handleOnSearch}
              data={stateGetRegByName.data}
            />
          </Form.Item>
          {!history.location.pathname.includes('requests_expiring') && (
            <Form.Item
              label={t('field.status')}
              name="status"
              style={{ marginBottom: '16px', width: 400 }}
            >
              <Select allowClear placeholder={t('search.placeholder.status')}>
                {searchStatusLabels.map((l: string, i: number) => (
                  <Option key={i} value={l}>
                    {t(`status.${l}`)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </>
      )}

      <Form.Item
        label={t('field.referenceContactOrg')}
        name="referenceContactOrg"
        style={{ marginBottom: '16px', width: 400 }}
      >
        <Input allowClear placeholder={t('search.placeholder.org')} />
      </Form.Item>
      <Form.Item
        label={t('field.referenceContactRegcode')}
        name="referenceContactRegcode"
        style={{ marginBottom: '16px', width: 400 }}
      >
        <Input allowClear placeholder={t('search.placeholder.regcode')} />
      </Form.Item>
      <Form.Item style={{ marginBottom: '16px', width: 400 }}>
        <Button type="primary" htmlType="submit">
          {t('Filter')}
        </Button>
      </Form.Item>
    </Form>
  )
}
