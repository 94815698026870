import {useTranslation} from '@nic/commons'
import React from 'react'
import {ContactDetailCardIconBoxStyleProps} from './Base/Card/ContactDetailCard/ContactDetailCard'
import {ContactReportType} from './types'
import {RegistrantDetailCard} from './KeyValueDetailCard/RegistrantDetailCard'
import {contactDetailRows} from './rows'
import {RenderAnomalies} from './CustomRenderFunctions/RenderAnomalies'
import {RenderCompleteUpdates} from './CustomRenderFunctions/RenderCompleteUpdates'
import {RenderUpdatesWithAnomalies} from './CustomRenderFunctions/RenderUpdatesWithAnomalies'
import {RenderReportedFields} from './CustomRenderFunctions/RenderReportedFields'
import {RenderEditedFields} from './CustomRenderFunctions/RenderEditedFields'
import {Registrar} from "@nic/ui-antd";
import {Tag} from "antd";

export interface FactoryContactCardProps extends ContactDetailCardIconBoxStyleProps {
    type: 'completed' | 'updated' | 'reference' | 'candidate' | 'selected' | 'edited'
    data: ContactReportType | {}
    loading: boolean
    error: any
    contactId?: string
}

function renderRegistrar(id: any) {
    console.log("renderRegistrar",id)
    return id && (
        <Tag color={"green"} style={{marginRight:0}}><Registrar

            id={id}
            scope={'registry'}
            render={(r) => r.name}
        /></Tag>)
}

/**
 * Sceglie quale componente mostrare quando cè da visualizzare la card per il Registrante nel caso di
 * profilo Registrar
 * @constructor
 */
export function FactoryContactCard({
                                       type,
                                       data,
                                       contactId,
                                       ...otherProps
                                   }: FactoryContactCardProps) {
    const t = useTranslation()
    console.log("FactoryContactCard data", data)
    switch (type) {
        case 'completed':
            return (
                <RegistrantDetailCard
                    data={data as ContactReportType}
                    rows={contactDetailRows(RenderCompleteUpdates, t)}
                    className={'completed-registrant-detail-card'}
                    contactTitle={t('CompleteRegistrantCard.title')}
                    contactSubTitle={t('CompleteRegistrantCard.subTitle')}
                    cardTitle={t('CompleteRegistrantCard.cardTitle')}
                    color={'#008001'}
                    {...otherProps}
                />
            )
        case 'updated':
            return (
                <RegistrantDetailCard
                    data={data as ContactReportType}
                    rows={contactDetailRows(RenderUpdatesWithAnomalies, t)}
                    className={'updated-registrant-detail-card'}
                    cardTitle={t('registryRegistrantCards.cardTitle')}
                    contactSubTitle={t('registryRegistrantCards.contactSubTitle')}
                    contactTitle={t('registryRegistrantCards.contactTitle')}
                    color={'#FAAD13'}
                    {...otherProps}
                />
            )
        case 'reference':
            return (
                <RegistrantDetailCard
                    data={data as ContactReportType}
                    rows={contactDetailRows(RenderAnomalies, t)}
                    className={'reference-registrant-detail-card'}
                    cardTitle={t('registrarRegistrantCard.cardTitle')}
                    contactSubTitle={t('registrarRegistrantCard.contactSubTitle')}
                    contactTitle={t('registrarRegistrantCard.contactTitle')}
                    color={'#FE5254'}
                    {...otherProps}
                />
            )
        case 'edited':
            // contactUpdateData={result}
            // contactUpdateData={props.changes}
            // anomaliesData={[{ field: 'name', codes: [11] }]}
            // anomaliesData={[]}
            // contactData={props.referenceContact as Domains.Contact}
            console.log('edited', data)
            return (
                <RegistrantDetailCard
                    data={data as ContactReportType}
                    rows={contactDetailRows(RenderEditedFields, t)}
                    className={'edited-registrant-detail-card'}
                    cardTitle={t('confirmationStepView.registrantData.title')}
                    contactSubTitle={t('registryRegistrantCards.contactSubTitle')}
                    contactTitle={t('registryRegistrantCards.contactTitle')}
                    color={'#FAAD13'}
                    {...otherProps}
                />
            )
        case 'candidate':
            const registrarId = (data as ContactReportType).registrarId?.defaultValue
            return (
                <RegistrantDetailCard
                    data={data as ContactReportType}
                    rows={contactDetailRows(RenderReportedFields, t)}
                    className={'candidate-registrant-detail-card'}
                    cardTitle={t('CandidateCard.title')}
                    contactSubTitle={t('CandidateCard.subTitle')}
                    contactTitle={t('CandidateCard.cardTitle')}
                    extra={registrarId && renderRegistrar(registrarId)}
                    color={'#008001'}
                    {...otherProps}
                />
            )
        case 'selected':
            return (
                <RegistrantDetailCard
                    data={data as ContactReportType}
                    rows={contactDetailRows(RenderAnomalies, t)}
                    className={'selected-registrant-detail-card'}
                    contactTitle={t(`contactListFullDetailed.contactDetailTitle`)}
                    contactSubTitle={contactId as string}
                    color={'#3FA9FF'}
                    cardTitle={t(`contactListFullDetailed.contactDetailCardTitle`)} // error={stateContactAnomalies.error || stateCandidate.error}
                    {...otherProps}
                />
            )
    }
}
