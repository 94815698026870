import React from 'react'
import { NicScope } from '@nic/nic-api/types'
import { Counter } from './Counter'
import {
  DarwinHelpers,
  requestsCountInitialState,
  requestsExpiringCountReducer,
} from '@nic/nic-api'

interface IRequestExpiringCounterProp {
  title: string
  icon?: React.ReactElement<any>
  redirect: string
  scope: NicScope
  color?: string
  backgroundColor?: string
  className?: string
  tip?: string
}

export const RequestsExpiringCounter: React.FC<IRequestExpiringCounterProp> = ({
  redirect,
  icon,
  title,
  color, //= '#3f8600',
  scope = 'registrar',
  backgroundColor,
  className,
  tip,
}) => {
  const [stateCountReq, dispatchCountReq] = React.useReducer(
    requestsExpiringCountReducer,
    requestsCountInitialState
  )
  React.useEffect(() => {
    DarwinHelpers.getRequestsExpiringCount(dispatchCountReq, { days: 7 }, scope)
  }, [scope])

  return (
    <Counter
      title={title}
      redirect={redirect}
      value={stateCountReq?.data?.count}
      error={stateCountReq?.error}
      loading={stateCountReq?.loading}
      color={color}
      icon={icon}
      backgroundColor={backgroundColor}
      className={className}
      tip={tip}
    />
  )
}
