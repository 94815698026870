import { Div } from '@nic/ui-comps'
import { Button, Divider, Spin } from 'antd'
import { AxiosError } from 'axios'
import React, { useContext } from 'react'
import { VariationContext } from '../../../../../Providers/VariationProvider'
import { ProposalResultSuccess } from '../../ModalProposal'
import { RequestContext } from '../../../../../Contexts/RequestContext'
import { ErrorAlert } from '../../../../../ErrorAlert/ErrorAlert'

export function ResultStep(props: { onClose: () => void }) {
  const {
    state: { loading, result, error },
  } = useContext(VariationContext)
  const { id } = useContext(RequestContext)
  return (
    <Div marginLeft={'20%'} marginRight={'20%'}>
      {loading && <Spin />}
      {error && <ErrorAlert error={error} />}
      {result && id && <ProposalResultSuccess requestId={id} />}
      <Divider type={'horizontal'} />
      <Div textAlign={'center'}>
        <Button onClick={props.onClose} type={'primary'}>
          Chiudi
        </Button>
      </Div>
    </Div>
  )
}

export function printAxiosFetchError(error: AxiosError): string {
  console.group('printAxiosFetchError')
  console.log('raw', JSON.stringify(error))
  console.log('response', JSON.stringify(error.response))
  if (error.response && error.response.data) {
    const { response } = error
    console.error(JSON.stringify(error.response))
    return `(${response.statusText}) - ${JSON.stringify(response.data)} ${
      response.data.errors ? JSON.stringify(response.data.errors) : ''
    }  `
  }
  console.groupEnd()

  return JSON.stringify(error)
}
