import { HorizontalLayout, PaperLayout } from '@nic/ui-comps'
import React, { useContext } from 'react'
import { useTranslation } from '@nic/commons'
import { Darwin, IFetchState } from '@nic/nic-api/types'
import { PaperTitle } from '../../Layout/PaperTitle'
import { Spin } from 'antd'
import { FactoryContactCard } from '../../DataDisplay/RegistrantContactCards/FactoryContactCard'
import { NicScopeHandler } from '../../Various/NicScopeHandler/NicScopeHandler'
import { RequestContext } from '../../Contexts/RequestContext'
import { RegistrantCandidateData } from '../../RegistrantLoader/RegistrantCandidateData'
import { RegistrantReferenceData } from '../../RegistrantLoader/RegistrantReferenceData'
import { RegistrantUpdatedHandler } from './RegistrantUpdatedHandler'

/***
 * Visualizza la card con i dettagli del contatto, mostrando:
 * - i dati del contatto partendo dal id del contatto
 * - i dati relativi all'aggiornamento del contatto partendo dal id del contatto
 * - se la richiesta è in RegistrarUpdate mostra il contatto in edited version
 * altrimenti lo mostra in anomaly
 * @constructor
 */
export function RegistryRegistrantCards() {
  const t = useTranslation()
  const { stateReqById } = useContext(RequestContext)
  const LeftRightSideContentM = React.memo(LeftRightSideContent)
  return (
    <PaperLayout asCard={false}>
      <PaperTitle showIcon title={<>{t('registryRegistrantCards.title')}</>} />
      <HorizontalLayout style={{ columnGap: '1em', alignItems: 'stretch', padding: 10 }}>
        <LeftRightSideContentM state={stateReqById} />
      </HorizontalLayout>
    </PaperLayout>
  )
}

/**
 *
 *  Card Lato sinistro
 * @param state
 * @param scope
 * @constructor
 */
const LeftRightSideContent: React.FC<{
  state: IFetchState<Darwin.Request, any>
}> = ({ state }) => {
  const { loading, data } = state
  const RightSide = (
    <RegistrantCandidateData>
      {(_data) => <FactoryContactCard type={'candidate'} {..._data} iconSide={'right'} />}
    </RegistrantCandidateData>
  )

  if (loading) {
    return <Spin />
  }

  /* Card Lato sinistro */
  const LeftSide = () => {
    if (data?.status === 'registrarUpdated' || data?.status === 'completed') {
      return <RegistrantUpdatedHandler />
    } else {
      return (
        <RegistrantReferenceData>
          {(_data) => <FactoryContactCard type={'reference'} {..._data} />}
        </RegistrantReferenceData>
      )
    }
  }

  return (
    <>
      <LeftSide />
      <NicScopeHandler registry={RightSide} />
    </>
  )
}
