import { Button, Popover, Table, TablePaginationConfig } from 'antd'
import { useTranslation } from '@nic/commons'
import { Domains, NicScope } from '@nic/nic-api/types'
import React from 'react'

import { MidaLink } from '@nic/ui-antd'
import { NicScopeHandler } from '../../Various/NicScopeHandler/NicScopeHandler'
import { getWindowRunConfig } from '../../../darwinConfig'

interface ContactDomainsTableProp {
  data?: Domains.Domains
  scope?: NicScope
  onRowClick?: (record: any) => void
  selectedDomainId?: number
  withSelector?: boolean
  loading?: boolean
  onChangePagination?: (page?: number, pageSize?: number) => void
  pagination?: TablePaginationConfig
  readonly?: boolean
}

/**
 * Tabella domini
 * @constructor
 */
export function ContactDomainsTable(props: ContactDomainsTableProp) {
  const t = useTranslation()
  const midaHosts = getWindowRunConfig().MIDA_HOST
  const handleClassNameRow = (record: any) => {
    return record?.id === props.selectedDomainId ? 'row-selected clickable-row' : 'clickable-row'
  }
  return (
    <Table
      rowKey={(r) => r.id}
      bordered
      rowClassName={handleClassNameRow}
      dataSource={props.data}
      size={'small'}
      pagination={props.pagination}
      onChange={(pagination) =>
        props.onChangePagination &&
        props.onChangePagination(pagination.current, pagination.pageSize)
      }
      loading={props.loading}
      // scroll={{ y: 430 }}
    >
      <Table.Column<Domains.Domain & { dbnaId: number }>
        key={'domain'}
        dataIndex={'domain'}
        title={`${t('datatable.domain')}`}
        render={(domain: string, record) => (
          <NicScopeHandler
            registry={
              <MidaLink
                host={midaHosts}
                tag
                path={`/domains/${record.dbnaId}`}
                description={record.domain}
              />
            }
            registrar={
              <a href={`http://${domain}`} rel="noopener noreferrer" target="_blank">
                {domain}
              </a>
            }
          />
        )}
      />
      <Table.Column<Domains.Domain>
        title={`${t('datatable.contactId')}`}
        dataIndex={'registrarContactId'}
        key={'registrarContactId'}
        // render={(_, record) => record.contacId}
      />
      {!props.readonly && (
        <Table.Column<Domains.Domain>
          title={`${t('datatable.status')}`}
          dataIndex={'status'}
          key={'status'}
          // render={(_, record) => record.contacId}
        />
      )}
      {props.withSelector && !props.readonly && (
        <Table.Column<Domains.Domain>
          align={'center'}
          title={t('Select')}
          render={(_, record) => (
            <>
              <Button
                type={'link'}
                onClick={() => props.onRowClick && props.onRowClick(record)}
                style={{ color: 'black' }}
              >
                <Popover content={t('selectForDetails')}>
                  {/*<SelectOutlined />*/}
                  <Button type={'link'}>{t('detail')}</Button>
                </Popover>
              </Button>
            </>
          )}
        />
      )}
    </Table>
  )
}

/**
 * <a href={`http://${domain}`} rel="noopener noreferrer" target="_blank">
 *             {domain}
 *           </a>
 */
