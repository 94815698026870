import React from 'react'
import { faExclamationCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Popover } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

/**
 *
 * @param props
 * @constructor
 */
export function AnomalyPopOver(props: {
  content?: React.ReactNode
  title?: string
  icon?: IconDefinition
  iconColor?: string
  isAnomaly?: boolean
}) {
  return (
    <Popover
      content={props.content}
      title={<b>{props.title}</b>}
      trigger="hover"
      className={'anomaly-popover'}
    >
      <FontAwesomeIcon
        icon={(props.icon ? props.icon : faExclamationCircle) as IconProp}
        style={{ color: props.iconColor, cursor: 'pointer' }}
      />
      {props.isAnomaly && (
        <span style={{ marginLeft: '5px' }}>
          <FontAwesomeIcon
            icon={faExclamationCircle as IconProp}
            style={{ color: 'red', cursor: 'pointer' }}
          />
        </span>
      )}
    </Popover>
  )
}
