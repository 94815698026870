import React, { useContext } from 'react'
import { RequestContext } from '../Contexts/RequestContext'
import { useTranslation } from '@nic/commons'
import { useAssignmentRequest } from '../AssignmentRequests/useAssignmentRequest'
import { HorizontalLayout } from '@nic/ui-comps'
import { Space, Typography } from 'antd'
import { AssignmentRequestTag } from '../AssignmentRequests/AssignmentRequestTag'
import { AssignmentRequestActions } from '../AssignmentRequests/ButtonsAssignmentRequestActions'

export function AssignmentRequestContent(props: { labels?: boolean }) {
  const request = useContext(RequestContext)
  const t = useTranslation()
  const { isRegistryAssignedUser, isRegistryAssigned, registrarStatus } = useAssignmentRequest()

  return (
    <HorizontalLayout
      style={{
        gap: 20,
        justifyContent: 'space-between',
        width: '100%',
        verticalAlign: 'bottom',
      }}
    >
      <div>
        <Space>
          {props.labels && (
            <Space style={{ marginRight: 30 }}>
              <Typography.Text>{t('RegistrarOperator')}:</Typography.Text>
              <div style={{ fontSize: 12, fontWeight: '700' }}>
                <AssignmentRequestTag
                  showIcon
                  scope={'registrar'}
                  status={registrarStatus}
                  name={request.stateReqById.data?.registrarUser?.username}
                  noStyle={request.scope === 'registry'}
                />
              </div>
            </Space>
          )}
          {props.labels && request.scope === 'registry' && (
            <Space>
              <Typography.Text>{t('RegistryOperator')}:</Typography.Text>
              {isRegistryAssignedUser ? (
                <AssignmentRequestTag
                  status={'assignedToMe'}
                  scope={'registry'}
                  showIcon
                  name={request.stateReqById.data?.registryUser?.username}
                />
              ) : (
                <AssignmentRequestTag
                  showIcon
                  scope={'registry'}
                  status={isRegistryAssigned ? 'assignedTo' : undefined}
                  name={request.stateReqById.data?.registryUser?.username}
                />
              )}
            </Space>
          )}
        </Space>
      </div>
      <div style={{ float: 'right' }}>
        <AssignmentRequestActions requestId={request.id} onSuccess={request.reload} />
      </div>
    </HorizontalLayout>
  )
}
