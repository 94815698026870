import { RequiredDocumentsModal } from '../RequiredDocumentsModal/RequiredDocumentsModal'
import React from 'react'
import { Skeleton, Space } from 'antd'
import { Div } from '@nic/ui-comps'
import { NicScopeHandler } from '../../Various/NicScopeHandler/NicScopeHandler'
import { PrintEntityType } from '../../DataDisplay/RegistrantContactCards/helpers'
import { RegistrantEntityType } from '@nic/nic-api/types/Domains/types'
import { IRequiredDocumentsBody } from '@nic/nic-api/types/Darwin/types'
import { RequiredDocumentsContent } from '../RequiredDocumentsContent/RequiredDocumentsContent'
import { useTranslation } from '@nic/commons'

export interface RequiredDocumentsProps {
  enableRequiredDocsModal: boolean
  loading: boolean
  entityType?: RegistrantEntityType
  initialRequiredDocumentList?: IRequiredDocumentsBody
}

/**
 * Mostra i documenti richiesti (RequestDocumentsContent) e un eventuale
 * pulsante (solo per Registry) che permette l'apertura di una modale per
 * la modifica dei documenti richiesti
 *
 * @param enableRequiredDocsModal
 * @param entityType
 * @param loading
 * @param initialRequiredDocumentList
 * @constructor
 */
export const RequiredDocuments: React.FC<RequiredDocumentsProps> = ({
  enableRequiredDocsModal,
  entityType,
  loading,
  initialRequiredDocumentList,
}) => {
  const t = useTranslation()
  if (entityType) {
    return (
      <Space direction="vertical" style={{ width: '100%' }}>
        <p>
          {t('requiredDocuments.description')}:<b> {PrintEntityType(entityType)}</b>
        </p>
        <Skeleton loading={loading}>
          <RequiredDocumentsContent
            entityType={entityType}
            requiredDocumentsBody={initialRequiredDocumentList}
            disabled={true}
            message={initialRequiredDocumentList?.message}
            showMessage={false}
          />
        </Skeleton>
        <NicScopeHandler
          registry={
            <Div style={{ paddingTop: 20, textAlign: 'center' }}>
              <RequiredDocumentsModal
                entityType={entityType}
                enabled={enableRequiredDocsModal}
                initialRequiredDocs={initialRequiredDocumentList}
              />
            </Div>
          }
        />
      </Space>
    )
  }

  return null
}
