import { createCtx } from "@nic/commons";
import { DocumentUploadFile } from "@nic/ui-antd";
import { Darwin } from "@nic/nic-api/types";
import { UploadFile } from "antd/es/upload/interface";

export interface InitialStateVariationProp {
  /** indica se è abilitato l'editing dei file **/
  edit: boolean;
  /** indica lo step corrente **/
  step: number;
  /** contiene i cambiamenti fatti **/
  changes: Darwin.ContactUpdate;
  /** indica che il contatto è stato  validato correttamente */
  contactValidated: boolean;
  /** tab corrente  **/
  tab: string;
  loading: boolean;
  result: any;
  error: any;
  /** contiene i documenti già ineriti (nel passato) e quelli aggiunti durante la fase di editing  **/
  documents: DocumentUploadFile<Darwin.DarwinUploadedFileResponse>[];
  /** indica se si ha il giusto numero di documenti richiesti **/
  hasRequiredDocs: boolean;
}

const initialState: InitialStateVariationProp = {
  edit: false,
  step: 0,
  changes: {
    created: undefined,
    requestId: 0,
    message: undefined,
    readsValidation: undefined,
    contactData: {
      name: "",
      org: "",
      streets: [],
      city: "",
      state: "",
      postalCode: "",
      voice: "",
      fax: "",
      email: "",
      country: "",
      consentForPublish: false,
      registrant: {
        regcode: "",
        nationality: "",
        entityType: 1,
      },
    },
  } as Darwin.ContactUpdate,
  tab: "1",
  loading: false,
  result: undefined,
  contactValidated: false,
  error: undefined,
  documents: [],
  hasRequiredDocs: true,
};
type AppState = typeof initialState;
type Action =
  | { type: "EDIT_ON" }
  | { type: "EDIT_OFF" }
  | { type: "ENABLE_NEXT_STEP" }
  | { type: "DISABLE_NEXT_STEP" }
  | { type: "NEXT_STEP" }
  | { type: "PREV_STEP" }
  | { type: "SET_CHANGES_LOAD" }
  | { type: "SET_CHANGES_INIT"; payload: Darwin.ContactUpdate }
  | { type: "SET_CHANGES_SUCCEED"; payload: Darwin.ContactUpdate }
  | {
      type: "SET_CHANGES_FAILED";
      payload: { error: object; changes: Darwin.ContactUpdate };
    }
  | { type: "SUBMIT_VARIATION" }
  | { type: "VARIATION_SUCCEED"; payload: object }
  | { type: "VARIATION_FAILED"; payload: object }
  | {
      type: "INIT_DOCUMENTS";
      payload: DocumentUploadFile<Darwin.DarwinUploadedFileResponse>[];
    }
  | {
      type: "DOCUMENT_UPLOAD_SUCCEED";
      payload: DocumentUploadFile<Darwin.DarwinUploadedFileResponse>;
    }
  | {
      type: "DOCUMENT_REMOVE";
      payload: UploadFile<Darwin.DarwinUploadedFileResponse>;
    }
  | {
      type: "DOCUMENT_UPLOAD_FAILED";
      payload: DocumentUploadFile<Darwin.DarwinUploadedFileResponse>;
    };

/** funzione che calcola se si hanno come requisito i documenti richiesti **/
const _hasRequiredDocs = (
  _documents: DocumentUploadFile<Darwin.DarwinUploadedFileResponse>[]
) => {
  let docsValid: number = 0;
  _documents.forEach((docs) => {
    console.debug(
      "docs let me see",
      docs,
      docs &&
        docs.fileList &&
        docs.fileList.findIndex((d) => d.status === "done")
    );
    if (
      docs &&
      docs.fileList &&
      docs.fileList.findIndex((d) => d.status === "done") !== -1
    ) {
      docsValid = docsValid + 1;
    }
  });
  return docsValid === _documents.length;
};

function variationReducer(state: AppState, action: Action): AppState {
  console.debug("VariationReducer", action, state);
  switch (action.type) {
    case "EDIT_ON":
      return { ...state, edit: true, step: 0 };
    case "EDIT_OFF":
      return { ...initialState, documents: state.documents };
    case "NEXT_STEP":
      return { ...state, step: state.step + 1 };
    case "PREV_STEP":
      return { ...state, step: state.step > 0 ? state.step - 1 : 0 };
    case "SET_CHANGES_INIT":
      return {
        ...state,
        changes: action.payload,
        contactValidated: false,
        error: undefined,
      };
    case "SET_CHANGES_LOAD":
      return { ...state, loading: true, error: undefined };
    case "SET_CHANGES_SUCCEED":
      return {
        ...state,
        loading: false,
        changes: { ...state.changes, ...action.payload },
        error: undefined,
        contactValidated: true,
      };
    case "SET_CHANGES_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        changes: action.payload.changes,
        contactValidated: false,
      };
    case "SUBMIT_VARIATION":
      return { ...state, loading: true };
    case "VARIATION_SUCCEED":
      return {
        ...state,
        loading: false,
        result: action.payload,
        error: undefined,
      };
    case "VARIATION_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
        result: undefined,
      };
    case "INIT_DOCUMENTS":
      return {
        ...state,
        documents: action.payload,
        hasRequiredDocs: _hasRequiredDocs(action.payload),
      };
    case "DOCUMENT_UPLOAD_SUCCEED":
      // console.log('DOCUMENT_UPLOAD_SUCCEED this.state.', state)
      // console.log('DOCUMENT_UPLOAD_SUCCEED action', action)
      const refreshDocsAfterSucceed = state.documents.map((obj) => {
        if (obj.docType === action.payload.docType) {
          return {
            docType: obj.docType,
            fileList: [
              ...(obj.fileList || []),
              ...(action.payload.fileList || []),
            ],
          };
        } else {
          return { docType: obj.docType, fileList: obj.fileList };
        }
      });
      return {
        ...state,
        documents: refreshDocsAfterSucceed,
        hasRequiredDocs: _hasRequiredDocs(refreshDocsAfterSucceed),
      };
    // case 'DOCUMENT_REMOVE':
    //   console.log('DOCUMENT_REMOVE this.state.', state)
    //   console.log('DOCUMENT_REMOVE action', action)
    //   const refreshDocsAfterRemovedItem = state.documents.map((obj) => {
    //     if (obj.docType === action.payload.uid.split('-', 1)[0]) {
    //       return {
    //         docType: obj.docType,
    //         fileList: [...(obj.fileList?.filter((e) => e.uid != action.payload.uid) || [])],
    //       }
    //     } else {
    //       return { docType: obj.docType, fileList: obj.fileList }
    //     }
    //   })
    //   return {
    //     ...state,
    //     documents: refreshDocsAfterRemovedItem,
    //     hasRequiredDocs: _hasRequiredDocs(refreshDocsAfterRemovedItem),
    //   }
    case "DOCUMENT_UPLOAD_FAILED":
      //console.log('DOCUMENT_UPLOAD_FAILED this.state.', state)
      //console.log('DOCUMENT_UPLOAD_FAILED action', action)
      const refreshDocsAfterFail = state.documents.map((obj) => {
        if (obj.docType === action.payload.docType) {
          return {
            docType: obj.docType,
            fileList: [
              ...(obj.fileList || []),
              ...(action.payload.fileList || []),
            ],
          };
        } else return [] as any;
      });

      return {
        ...state,
        documents: refreshDocsAfterFail,
        hasRequiredDocs: _hasRequiredDocs(refreshDocsAfterFail),
      };
    default:
      throw new Error("Not handled action " + action);
  }
}

const [ctx, Provider] = createCtx(variationReducer, initialState);
export const VariationContext = ctx;
export const VariationProvider = Provider;
export const VariationConsumer = ctx.Consumer;
