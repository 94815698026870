import React, { useContext } from "react";
import { useTranslation } from "@nic/commons";
import { equalArrays } from "../utils";
import { RequestContext } from "../../Contexts/RequestContext";
import { Alert } from "antd";
import { Div } from "@nic/ui-comps";

interface DocsWarningProps {
  hasWarning: boolean;
}

interface RequiredDocumentsWarningProps {
  children?(props: DocsWarningProps): JSX.Element;
}

/**
 * Mostra un warning nel caso in cui il numero dei documenti richiesti
 * non è uguale a quello dei documenti uplodati
 * @constructor
 */
export const RequiredDocumentsWarning: React.FC<RequiredDocumentsWarningProps> =
  ({ children }) => {
    const {
      stateReqDocuments,
      stateRequiredDocuments: { loading },
    } = useContext(RequestContext);
    const { stateReqById } = useContext(RequestContext);
    const t = useTranslation();

    const uploadedDocumentList = stateReqDocuments.data?.elements || [];
    const requiredDocumentList =
      stateReqById.data?.requiredDocuments?.documents || [];

    const documentListArray = Array.from(
      new Set(uploadedDocumentList.map((d) => d.documentType))
    );
    const hasWarning = !equalArrays(
      requiredDocumentList,
      documentListArray as []
    );

    const showWarning = !loading && hasWarning;

    return (
      <Div>
        {showWarning && (
          <Alert
            message={t("requiredDocumentsWarning.warningDocs")}
            type={"warning"}
            showIcon
          />
        )}
        {children && children({ hasWarning })}
      </Div>
    );
  };
