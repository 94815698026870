import { ContactReportContent, ContactReportType } from '../types'
import React from 'react'
import { RenderAnomaly } from '../../../Anomalies/Anomaly/RenderAnomaly'
import { emphasizeAnomalyStyle } from '../styles'

/***
 * Metodo Render che mostra i campi reported ma senza segnalare anomalia
 * @param obj
 * @param element
 * @param fieldKeyGroup
 * @param defaultRender
 * @constructor
 */
export function RenderReportedFields(
  obj: ContactReportType,
  element: ContactReportContent,
  fieldKeyGroup: string,
  defaultRender?: (obj: ContactReportType, element: ContactReportContent) => React.ReactNode
) {
  const renderFn = defaultRender ? defaultRender(obj, element.defaultValue) : element.defaultValue
  if (element.anomalies) {
    return (
      <RenderAnomaly
        value={renderFn}
        field={fieldKeyGroup as string}
        anomalyCssStyle={{ ...emphasizeAnomalyStyle, overflow: 'auto' }}
      />
    )
  } else {
    return <>{renderFn}</>
  }
}
