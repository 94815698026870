import React from 'react'
import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'
import { Div } from '@nic/ui-comps'
import { useTranslation } from '@nic/commons'

export const Page404: React.FC = () => {
  const t = useTranslation()
  return (
    <Div width={'100%'}>
      <Result
        status="404"
        title="404"
        subTitle={t('404message')}
        extra={
          <Button type="primary">
            <Link to={'/'}>Dashboard</Link>
          </Button>
        }
      />
    </Div>
  )
}
