import React from "react";
import { SummaryRequestTab, SummaryRequestTabProp } from "./SummaryRequestTab";
import { ContactsTab } from "./ContactsTab";
import { DomainsTab } from "./DomainsTab";
import { Tabs } from "antd";
import { EditTab } from "./EditTab";
import { useScopeInfo } from "../../Hooks/useScopeInfo";

/***
 * Raccoglitore di TAB della richiesta
 * -Summary,Contacts,Domains, Edit
 * @param onChangeTab
 * @param onEdit
 * @param onEditOff
 * @param tabKey
 * @param edit
 * @constructor
 */
export const RequestTabs: React.FC<SummaryRequestTabProp> = ({
  onChangeTab,
  onEdit,
  onEditOff,
  tabKey,
  edit,
}) => {
  // console.log(RequestTabs.name, edit, tabKey)

  function handleTabKey() {
    if (tabKey === "edit") {
      return edit ? tabKey : "summary";
    } else {
      return tabKey;
    }
  }
  const { scope } = useScopeInfo();
  const TabListRegistry = [
    SummaryRequestTab({
      onChangeTab: onChangeTab,
      edit,
      onEdit: onEdit,
      tabKey: "summary",
    }),
    ContactsTab({ tabKey: "contacts" }),
    DomainsTab({ tabKey: "domains" }),
    EditTab({
      disabled: !edit,
      tabKey: "edit",
      onCancel: onEditOff,
    }),
  ];

  const TabListRegistrar = [
    ...TabListRegistry,
    // EditTab({
    //   disabled: !edit,
    //   tabKey: 'edit',
    //   onCancel: onEditOff,
    // }),
  ];
  return (
    <Tabs
      type={"card"}
      style={{ width: "100%", paddingTop: 0 }}
      activeKey={handleTabKey()}
      tabBarStyle={{ backgroundColor: "#EFEFEF" }}
      onChange={onChangeTab}
    >
      {scope === "registry" ? TabListRegistry : TabListRegistrar}
    </Tabs>
  );
};
