import Tag from 'antd/lib/tag'
import React from 'react'
import { useTranslation } from '@nic/commons'
import { Space } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHomeUser, faUser } from '@fortawesome/free-solid-svg-icons'
import { NicScope } from '@nic/nic-api/types'

export function AssignmentRequestTag(props: {
  scope: NicScope
  status?: 'assignedToMe' | 'assignedTo'
  name?: string
  disabled?: boolean
  showIcon?: boolean
  noStyle?: boolean
}) {
  const t = useTranslation()
  const handleColor = (color: string = '') => (props.disabled ? '' : color)
  if (!props.status) {
    // return <Tag color={handleColor()}>{t('assignmentRequestTag.unassigned')}</Tag>
    return <UserTag {...props} name={t('assignmentRequestTag.unassigned')} />
  }
  switch (props.status) {
    case 'assignedToMe':
      return <UserTag {...props} color={'green'} />
    // return <UserTag {...props} color={'green'} name={t('assignmentRequestTag.assignedToMe')} />
    case 'assignedTo':
      // return (
      //   <Tag color={handleColor('blue')}>
      //     <Space>
      //       <FontAwesomeIcon icon={faHomeUser} />
      //       {props.name}
      //     </Space>
      //   </Tag>
      // )
      return <UserTag {...props} color={'blue'} />
    default:
      return <Tag color={handleColor('red')}>{t('assignmentRequestTag.unassigned')}</Tag>
  }
}

function UserTag(props: {
  scope: string
  name?: string
  disabled?: boolean
  color?: string
  showIcon?: boolean
  noStyle?: boolean
}) {
  let style = {}
  const handleColor = (color: string = '') => {
    if (props.noStyle) {
      style = {
        backgroundColor: 'default',
        color: '#b6b6b6',
        border: 0,
        fontWeight: 700,
      } as React.CSSProperties
    } else {
      if (props.disabled) {
        return ''
      } else {
        return color
      }
    }
  }
  return (
    <Tag color={handleColor(props.color)} style={style}>
      <Space>
        {props.showIcon && (
          <>
            {props.scope === 'registry' ? (
              <FontAwesomeIcon icon={faHomeUser} />
            ) : (
              <FontAwesomeIcon icon={faUser} />
            )}
          </>
        )}
        {props.name}
      </Space>
    </Tag>
  )
}
