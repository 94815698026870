import React from 'react'
import { Domains, NicScope } from '@nic/nic-api/types'
import { FactoryContactCard } from '../../../../../../DataDisplay/RegistrantContactCards/FactoryContactCard'
import { RegistrantCandidateData } from '../../../../../../RegistrantLoader/RegistrantCandidateData'
import { RegistrantReferenceData } from '../../../../../../RegistrantLoader/RegistrantReferenceData'

/**
 *
 * @param scope
 * @param candidateContact
 * @constructor
 */
export const CandidateOrReferenceDetailCard: React.FC<{
  scope: NicScope
  candidateContact?: Domains.Contact
}> = ({ scope, candidateContact }) => {
  const commonProps: any = {
    iconFontSize: '3em',
    iconSide: 'right',
    style: {
      opacity: 0.7,
      backgroundColor: '#fafafa',
    },
  }
  if (scope === 'registry' && candidateContact) {
    return (
      <RegistrantCandidateData>
        {(data) => <FactoryContactCard type={'candidate'} {...data} {...commonProps} />}
      </RegistrantCandidateData>
    )
  }

  return (
    <RegistrantReferenceData>
      {(data) => <FactoryContactCard type={'reference'} {...data} {...commonProps} />}
    </RegistrantReferenceData>
  )
}
