import React from 'react'
import { Darwin, IAction, IFetchState, NicScope } from '@nic/nic-api/types'
import {
  getDocumentsInitialState,
  requestAnomaliesInitialState,
  requestContactsCountInitialState,
  requestContactsInitialState,
  requestDomainsCountInitialState,
  requestDomainsInitialState,
  requestEventsInitialState,
  requestsByIdInitialState,
  requestsRequiredDocumentsInitialState,
} from '@nic/nic-api'

export interface IRequestContext {
  stateReqById: IFetchState<Darwin.Request, any>
  stateReqAnomalies: IFetchState<Darwin.Anomalies, any>
  stateReqEvents: IFetchState<Darwin.Events, any>
  stateReqContacts: IFetchState<Darwin.Contacts, any>
  stateReqContactsCount: IFetchState<Darwin.RequestContactsCount, any>
  stateReqDomains: IFetchState<Darwin.Domains, any>
  stateReqDomainsCount: IFetchState<Darwin.RequestDomainsCount, any>
  stateReqDocuments: IFetchState<Darwin.Documents, any>
  stateRequiredDocuments: IFetchState<Darwin.Request, any>
  dispatchReqDocuments: React.Dispatch<IAction<Darwin.ActionsGetRequestDocuments>>
  dispatchReqEvents: React.Dispatch<IAction<Darwin.ActionsGetRequestEvents>>
  scope?: NicScope
  reload?: () => void
  load?: (id: number) => void
  loadDomains?: (page: number, size: number) => void
  loadDocuments?: () => void
  postRequiredDocuments?: (data: Darwin.RequiredDocumentsBody) => void
  cleanRequiredDocument?: () => void
  id: number
}

export const RequestContext = React.createContext<IRequestContext>({
  stateReqById: requestsByIdInitialState,
  stateReqAnomalies: requestAnomaliesInitialState,
  stateReqEvents: requestEventsInitialState,
  stateReqContacts: requestContactsInitialState,
  stateReqDomains: requestDomainsInitialState,
  stateReqContactsCount: requestContactsCountInitialState,
  stateReqDomainsCount: requestDomainsCountInitialState,
  stateReqDocuments: getDocumentsInitialState,
  stateRequiredDocuments: requestsRequiredDocumentsInitialState,
  dispatchReqDocuments: () => {},
  dispatchReqEvents: () => {},
  id: 0,
})
