import React from 'react'
import { Divider } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

/**
 * Monstra l'Hint dell'anomalia
 * @param props
 * @constructor
 */
export function ShowHint(props: { anomalyHint: React.ReactNode }) {
  return (
    <div style={{ backgroundColor: '#e6fffb' }}>
      <i style={{ color: 'green' }}>{props.anomalyHint}</i>
      <Divider type={'vertical'} />
      <FontAwesomeIcon icon={faLightbulb as IconProp} style={{ color: '#ffe58f' }} />
    </div>
  )
}
