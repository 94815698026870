import React from 'react'
import { TabPaneProps, Tabs } from 'antd'
import { Div } from '@nic/ui-comps'

const { TabPane } = Tabs

export const CustomTabPane: React.FC<TabPaneProps & { divStyle?: React.CSSProperties }> = (
  props
) => {
  return (
    <TabPane {...props}>
      <Div style={props.divStyle}>{props.children}</Div>
    </TabPane>
  )
}
