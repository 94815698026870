import React from 'react'
import { Result, Spin } from 'antd'
import { DetailCard, DetailCardRow } from '@nic/ui-comps'
import { useTranslation } from '@nic/commons'
import { Darwin, IFetchState } from '@nic/nic-api/types'

// export interface ModalProposalProp {
//   show: boolean
//   values: any
//   requestId: number
//   onClose: (submitted: boolean) => void
// }
//
// /**
//  * Modale che permette l'invio della proposta di modifica
//  * @param props
//  * @constructor
//  */
// export function ModalProposal(props: ModalProposalProp) {
//   const t = useTranslation()
//   const [statePostContact, dispatchPostContact] = React.useReducer(
//     postRequestContactUpdateReducer,
//     postRequestContactUpdateInitialState
//   )
//
//   const [submitted, setSubmitted] = React.useState(false)
//
//   const touchedValues = Object.entries(props.values)
//     .filter((v) => v[1] !== undefined)
//     .map((entry) => ({
//       field: entry[0],
//       value: entry[1] as string,
//     }))
//
//   const handleUpdate = (values: { [key: string]: string }, requestId: number) => {
//     postRequestContactUpdate(dispatchPostContact, { id: requestId, values: touchedValues })
//     setSubmitted(true)
//   }
//
//   return (
//     <Modal
//       className={'modal-proposal'}
//       title={<b>{t('modalProposal.title')}</b>}
//       visible={props.show}
//       destroyOnClose
//       onOk={() => handleUpdate(props.values, props.requestId)}
//       //@ts-ignore
//       okButtonProps={{ 'data-testid': 'submit-button', disabled: submitted }}
//       okText={submitted ? t('sent') : t('send')}
//       cancelText={t('Close')}
//       onCancel={() => props.onClose(submitted)}
//     >
//       {submitted ? (
//         <ProposalSubmittedController
//           loading={statePostContact.loading}
//           requestId={props.requestId}
//           data={statePostContact.data}
//           error={statePostContact.error}
//         />
//       ) : (
//         <ProposalResult values={touchedValues} />
//       )}
//     </Modal>
//   )
// }

/**
 * Controlla il flusso della visualizzazione della risposta alla post
 * della modifica contatto
 * @param props
 * @constructor
 */
export function ProposalSubmittedController(props: IFetchState<any, any> & { requestId: number }) {
  if (props.loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin tip={'sending....'} />
      </div>
    )
  }

  if (props.data) {
    return <ProposalResultSuccess requestId={props.requestId} />
  }

  if (props.error) {
    return <ProposalResultError requestId={props.requestId} />
  }

  return <></>
}

/**
 * Mostra il risultato proposto da sottomettere
 * @param props
 * @constructor
 */
export function ProposalResult(props: { values: Darwin.ContactUpdate }) {
  const t = useTranslation()

  return (
    <Result
      className={'proposal-result'}
      status="info"
      title={t('proposalResult.title')}
      subTitle={
        <>
          {t('proposalResult.subTitle1')}
          <br />
          {t('proposalResult.subTitle2')}
        </>
      }
      extra={[
        <>
          <DetailCard style={{ justifyContent: 'center' }}>
            {props.values &&
              Object.entries(props.values).map((element, index: number) => (
                <React.Fragment key={index}>
                  <DetailCardRow value={<>{element[1]}</>} label={element[0]} labelBold />
                </React.Fragment>
              ))}
          </DetailCard>
        </>,
      ]}
    />
  )
}

/**
 * Mostra il risultato proposto inviato in caso di successo
 * @constructor
 */
export function ProposalResultSuccess(props: { requestId: number }) {
  return (
    <Result
      className={'proposal-result-success'}
      status="success"
      title="Proposta di modifica inviata"
      subTitle={
        <>
          La proposta di modifica per la richiesta <b>n° {props.requestId}</b> è stata correttamente
          inviata al Registro .it
        </>
      }
    />
  )
}

/**
 * Mostra il risultato proposto inviato in caso di errore
 * @constructor
 */
export function ProposalResultError(props: { requestId: number }) {
  return (
    <Result
      className={'proposal-result-error'}
      status="error"
      title="Proposta di modifica errata"
      subTitle={
        <>
          La proposta di modifica per la richiesta <b>n° {props.requestId}</b> risulta errata perciò
          non è stato possibile inviarla al Registro .it
        </>
      }
    />
  )
}
