import { useTranslation } from "@nic/commons";
import { VariationRegistrantStepFormItems } from "./VariationRagistrantStepFormItems";
import { Form, FormInstance } from "antd";
import { ContactDetailCard } from "../../../../../DataDisplay/RegistrantContactCards/Base/Card/ContactDetailCard/ContactDetailCard";
import { OrgRegCodeSelector } from "./OrgRegCodeSelector";
import { IFieldError } from "./utils";
import { Darwin, Domains, NicScope } from "@nic/nic-api/types";
import { useState } from "react";

interface VariationRegistrantStepFormProps {
  contact: Domains.Contact;
  anomalies: Darwin.Anomalies;
  /** se vera abilita l'opzione per modificare o solo org o solo regcode **/
  enableSwitchOrgRegcode: boolean;
  form: FormInstance;
  initialValues?: {};
  errors?: IFieldError[];
  scope?: NicScope;
}

export function VariationRegistrantStepForm(
  props: VariationRegistrantStepFormProps
) {
  const { form } = props;
  const [switchValue, setSwitchValue] = useState<boolean>(
    props.enableSwitchOrgRegcode
  );
  const t = useTranslation();
  const { enableSwitchOrgRegcode } = props;
  const handleOnChange = (value: boolean) => {
    setSwitchValue(value);
    form.setFieldsValue({
      org: props.contact.org,
      registrant: { regcode: props.contact.registrant?.regcode },
    });
  };
  return (
    <div className={VariationRegistrantStepForm.name}>
      {props.enableSwitchOrgRegcode && (
        <OrgRegCodeSelector label={t("edit")} onChange={handleOnChange} />
      )}
      <ContactDetailCard
        style={{ width: "100%" }}
        cardTitle={t("variationRegistrantStepView.cardTitle")}
        contactTitle={t("variationRegistrantStepView.contactTitle")}
        contactSubTitle={t("variationRegistrantStepView.contactSubTitle")}
        color={"#ffd600"}
      >
        <Form
          form={props.form}
          name={"variation-registrant-step"}
          initialValues={props.contact}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
        >
          <VariationRegistrantStepFormItems
            initialValues={props.contact}
            anomalies={props.anomalies}
            enableRegCode={enableSwitchOrgRegcode ? !switchValue : true}
            enableOrg={enableSwitchOrgRegcode ? switchValue : true}
            scope={props.scope}
            form={props.form}
            errors={props.errors}
          />
        </Form>
      </ContactDetailCard>
    </div>
  );
}
