/**
 * Pagina principale che raccoglie i dettagli dell'anomalia
 * @constructor
 */
import { useScopeInfo } from '../Components/Hooks/useScopeInfo'
import { CandidateRegistrantProvider } from '../Components/Providers/CandidateRegistrant'
import { ContactsProvider } from '../Components/Providers/ContactsProvider'
import { ContactUpdatedProvider } from '../Components/Providers/ContactUpdatedProvider'
import { DomainsDetailProvider } from '../Components/Providers/DomainsDetailProvider'
import { ReferenceProvider } from '../Components/Providers/ReferenceProvider'
import { RequestExpirationDaysProvider } from '../Components/Providers/RequestExpirationDaysProvider'
import { RequestProvider } from '../Components/Providers/RequestProvider'
import { RequestVisualizerProvider } from '../Components/Providers/RequestVisualizerProvider'
import { VariationProvider } from '../Components/Providers/VariationProvider'
import { RequestView } from '../Components/RequestView'

export function RequestPage() {
  const { scope, requestId } = useScopeInfo()
  console.log(RequestPage.name, 'Render')
  return (
    <RequestProvider>
      <VariationProvider>
        <ContactsProvider requestId={requestId} scope={scope}>
          <RequestVisualizerProvider>
            <DomainsDetailProvider>
              <ContactUpdatedProvider>
                <ReferenceProvider>
                  <CandidateRegistrantProvider>
                    <RequestExpirationDaysProvider>
                      <RequestView />
                    </RequestExpirationDaysProvider>
                  </CandidateRegistrantProvider>
                </ReferenceProvider>
              </ContactUpdatedProvider>
            </DomainsDetailProvider>
          </RequestVisualizerProvider>
        </ContactsProvider>
      </VariationProvider>
    </RequestProvider>
  )
}
