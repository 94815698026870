import { TabPaneProps, Tabs } from 'antd'
import React, { useContext } from 'react'
import { useRouter, useTranslation } from '@nic/commons'
import {
  ContactListFullDetailed,
  IDarwinContactExtended,
} from '../../../../../../ContactListFullDetailed/ContactListFullDetailed'
import { RequestContext } from '../../../../../../Contexts/RequestContext'
import { ContactsContext } from '../../../../../../Providers/ContactsProvider'
import { ActionButtonDelete, ActionButtonUpdate } from '../ActionButtons/ActionButtons'
import { CandidateOrReferenceDetailCard } from './common'
import { NicScope } from '@nic/nic-api/types'
import { CandidateRegistrantContext } from '../../../../../../Providers/CandidateRegistrant'

const { TabPane } = Tabs

export function ContactsToHoldTab(props: TabPaneProps) {
  const t = useTranslation()
  const {
    state: { toHold, loading },
    actions,
    dispatch,
  } = useContext(ContactsContext)
  const { state: stateCandidate } = useContext(CandidateRegistrantContext)
  const { stateReqAnomalies, scope, id, stateReqById } = useContext(RequestContext)
  const router = useRouter()

  const isRegistrar = scope === 'registrar'
  const isPending = stateReqById.data?.status === 'pending'

  const Actions = (record: IDarwinContactExtended) => {
    return [
      <ActionButtonUpdate
        key={'b_update'}
        onClick={() => {
          id && actions.changeContactAction(id, record.darwinId, 'todo', scope)(dispatch)
        }}
        disabled={!isPending || !isRegistrar}
      />,
      <ActionButtonDelete
        key={'b_delete'}
        onClick={() => {
          id && actions.changeContactAction(id, record.darwinId, 'delete', scope)(dispatch)
        }}
        disabled={!isPending || !isRegistrar}
      />,
    ]
  }
  const MemoizedContactListFullDetailed = React.memo(ContactListFullDetailed)

  const onChangePageHandler = (page?: number, pageSize?: number) => {
    router.pushHelper({ page: page, size: pageSize })
    actions.getContacts(id, 'hold', page, pageSize, scope)(dispatch)
  }
  return (
    <TabPane
      tab={t('contactsToHoldTab.tabTitle', { count: toHold.page.totalElements })}
      key={props.tabKey}
    >
      <MemoizedContactListFullDetailed
        readonly={stateReqById.data?.status === 'completed'}
        contacts={toHold}
        contactDetailTitle={t('contactsToHoldTab.contactDetailTitle')}
        anomalies={stateReqAnomalies.data}
        scope={scope}
        domainContactDetailTitle={t('contactsToHoldTab.domainContactDetailTitle')}
        actionColumn={Actions}
        loading={loading}
        onChangePagination={onChangePageHandler}
        showAdditionalContactDetailCard
        additionalContactDetailCard={
          <CandidateOrReferenceDetailCard
            candidateContact={stateCandidate.data}
            scope={scope as NicScope}
          />
        }
      />
    </TabPane>
  )
}
