import { PaperLayout } from "@nic/ui-comps";
import { Form, Switch } from "antd";

export const OrgRegCodeSelector: React.FC<{ label: string; onChange: (value: boolean) => void }> = (
    props
) => {
    return (
        <PaperLayout>
            <Form.Item label={props.label}>
                <Switch
                    checkedChildren="Org"
                    unCheckedChildren="regcode"
                    defaultChecked
                    onChange={props.onChange}
                />
            </Form.Item>
        </PaperLayout>
    )
}
