import { Darwin, Domains, NicScope } from '@nic/nic-api/types'
import { Button, Divider, Popover, Spin, Table, TablePaginationConfig, Tag, Tooltip } from 'antd'
import React from 'react'
import { stringToHslColor, Suspense, useTranslation } from '@nic/commons'
import './RequestTable.css'
import { ColumnGroupType, ColumnType } from 'antd/es/table'
import { stdDateHelper } from '../../../Utils/DateUtils'
import { Flex } from '@nic/ui-comps'
import { StarOutlined } from '@ant-design/icons'
import { getRequestDomainsCount } from '@nic/nic-api'
import { IDarwinContactExtended } from '../../ContactListFullDetailed/ContactListFullDetailed'
import { MidaLink } from '@nic/ui-antd'

import { getWindowRunConfig } from '../../../darwinConfig'

export interface ContactsTableProp extends ContactTableBaseProp {
  data: (Domains.Contact & Darwin.Contact[]) | any
  selectedContactId?: string
  onRowClick?: (record: IDarwinContactExtended) => void
}

export interface ContactTableBaseProp {
  scope?: NicScope
  readonly?: boolean
  actionColumn?: (record: any) => JSX.Element[]
  loading?: boolean
  onChangePagination?: (page?: number, pageSize?: number) => void
  pagination?: TablePaginationConfig
}

/**
 * Tabella contatti
 * @param props
 * @constructor
 */
export const ContactsTable = (props: ContactsTableProp) => {
  const t = useTranslation()
  const midaHosts = getWindowRunConfig().MIDA_HOST

  const handleClassNameRow = (record: Domains.Contact & Darwin.Contact) => {
    return record?.contactId === props.selectedContactId
      ? 'row-selected clickable-row'
      : 'clickable-row'
  }
  console.log('ContactsTable', props)
  const processing: boolean = false

  const columnsReadonlyRegistry: (
    | ColumnGroupType<Domains.Contact & Darwin.Contact>
    | ColumnType<Domains.Contact & Darwin.Contact>
  )[] = [
    {
      title: `${t('datatable.contactId')}`,
      dataIndex: 'contactId',
      key: 'contactId',
      render: (_, record) => {
        return <PrintContactIdForRegistry record={record} reference={record.reference} />
      },
    },
    {
      title: `${t('datatable.firstRevision')}`,
      dataIndex: 'firstRevision',
      key: 'firstRevision',
      render: (_, record) => {
        return (
          <MidaLink
            host={midaHosts}
            tag
            path={`/contacts/${record.dbnaId}/revisions/${record?.revision?.first}`}
            description={record?.revision?.first}
          />
        )
      },
    },
    {
      title: `${t('datatable.lastRevision')}`,
      dataIndex: 'lastRevision',
      key: 'lastRevision',
      render: (_, record) => {
        return (
          <MidaLink
            host={midaHosts}
            tag
            path={`/contacts/${record.dbnaId}/revisions/${record?.revision?.last}`}
            description={record?.revision?.last}
          />
        )
      },
    },
  ]

  const columnsReadonlyRegistrar: (
    | ColumnGroupType<Domains.Contact & Darwin.Contact>
    | ColumnType<Domains.Contact & Darwin.Contact>
  )[] = [
    {
      title: `${t('datatable.contactId')}`,
      dataIndex: 'contactId',
      key: 'contactId',
      render: (_, record) => <PrintContactIdForRegistrar contactId={record.registrarContactId} />,
    },
  ]

  const columns: (
    | ColumnGroupType<Domains.Contact & Darwin.Contact>
    | ColumnType<Domains.Contact & Darwin.Contact>
  )[] = [
    {
      title: `${t('datatable.contactId')}`,
      dataIndex: 'contactId',
      key: 'contactId',
      render: (_, record) => {
        if (props.scope === 'registry') {
          return <PrintContactIdForRegistry record={record} reference={record.reference} />
        }
        return (
          <PrintContactIdForRegistrar
            contactId={record.registrarContactId}
            reference={record.reference}
          />
        )
      },
    },
    {
      title: `${t('datatable.name')}`,
      key: 'name',
      render: (record) => (
        <span
          style={{
            color: record.name && stringToHslColor((record.name as string).toLowerCase()),
          }}
        >
          {record.name}
        </span>
      ),
    },
    {
      title: `${t('datatable.org')}`,
      key: 'org',

      render: (record) => (
        <span
          style={{
            color: record.org && stringToHslColor((record.org as string).toLowerCase()),
          }}
        >
          {record.org}
        </span>
      ),
    },
    {
      title: `${t('datatable.lastUpdate')}`,
      render: (record) => stdDateHelper(record.lastupdate),
    },
    {
      title: `${t('datatable.domains')}`,
      key: 'domains',
      align: 'center',
      dataIndex: 'domains',
    },
    {
      title: `${t('datatable.status')}`,
      key: 'status',
      dataIndex: 'status',
      render: (_, record) => <PrintStatus status={record.status} error={record.error} />,
    },
    {
      title: `${t('datatable.action')}`,
      align: 'center',
      render: (record) => (
        <Flex direction={'row'}>
          {/*<Button type={'link'} onClick={() => props.onRowClick && props.onRowClick(record)}>*/}
          {/*  Dettaglio*/}
          {/*</Button>*/}
          {props.actionColumn && props.actionColumn(record).map((r) => r)}
        </Flex>
      ),
    },
    {
      title: t('Select'),
      align: 'center',
      render: (record) => (
        <Flex direction={'row'}>
          <Button
            type={'link'}
            onClick={() => props.onRowClick && props.onRowClick(record)}
            style={{ color: 'black' }}
          >
            <Popover content={t('selectForDetails')}>
              {/*<SelectOutlined />*/}
              <Button type={'link'}>{t('detail')}</Button>
            </Popover>
          </Button>
        </Flex>
      ),
    },
  ]

  function handleColumns() {
    if (props.readonly) {
      return props.scope === 'registry' ? columnsReadonlyRegistry : columnsReadonlyRegistrar
    } else {
      return columns
    }
  }

  return (
    <Table
      rowKey={(record) => {
        return record.contactId
      }}
      rowClassName={handleClassNameRow}
      // onRow={(record, rowIndex) => {
      //   return {
      //     onClick: (event) => {
      //       // props.onRowClick && props.onRowClick(record)
      //     }, // click row
      //     // onMouseEnter: (event) => {
      //     //   props.onRowClick && props.onRowClick(record)
      //     // }, // click row
      //   }
      // }}
      bordered
      dataSource={props.data || []}
      size={'small'}
      columns={handleColumns()}
      pagination={props.pagination}
      onChange={(pagination) =>
        props.onChangePagination &&
        props.onChangePagination(pagination.current, pagination.pageSize)
      }
      loading={props.loading || processing}
    />
  )
}

export function DomainsCounter(props: { registrarContactId: string }) {
  // const [stateReqDomainsCount, dispatchReqDomainsCount] = React.useReducer(
  //   requestDomainsCountReducer,
  //   requestDomainsCountInitialState
  // )
  // getRequestDomainsCountHelper(dispatchReqDomainsCount, {
  //   registrarContactId: props.registrarContactId,
  // })
  const [state, setState] = React.useState<{ loading: boolean; data: any; error: any }>({
    loading: false,
    data: undefined,
    error: undefined,
  })
  React.useEffect(() => {
    setState({ ...state, loading: true })
    getRequestDomainsCount({ registrarContactId: props.registrarContactId })
      .then((resp) => setState({ error: undefined, loading: false, data: resp.data }))
      .catch((eResp) => setState({ loading: false, error: eResp.data, data: undefined }))
  }, [props.registrarContactId, state])

  //console.log('RendereZZZ', props.registrarContactId)
  return <Suspense state={state} spinner={<Spin />} render={(data) => <>{data.count}</>} />
}

function PrintStatus(props: { status: string; error?: { code: number; message: string } }) {
  if (props.status === 'rejected') {
    return (
      <span>
        {`${props.status} `}
        <div
          style={{ fontStyle: 'italic', color: 'red' }}
        >{`(${props.error?.code}) - ${props.error?.message}`}</div>
      </span>
    )
  }
  return <span>{`${props.status}`}</span>
}

function ReferenceTag() {
  const t = useTranslation()
  return (
    <Tag color={'red'}>
      <Tooltip title={t('reference')}>
        <StarOutlined />
      </Tooltip>
    </Tag>
  )
}

function ContactIdReferenceTag(props: { contactId: string }) {
  const { contactId } = props
  return (
    <>
      {contactId}
      <Divider type={'vertical'} />
      <ReferenceTag />
    </>
  )
}

function PrintContactIdForRegistrar(props: { contactId: string; reference?: boolean }) {
  console.log('ContactsTable refe', props)
  return (
    <>{props.reference ? <ContactIdReferenceTag contactId={props.contactId} /> : props.contactId}</>
  )
}

function PrintContactIdForRegistry(props: {
  record: Domains.Contact & Darwin.Contact
  reference?: boolean
}) {
  const midaHosts = getWindowRunConfig().MIDA_HOST
  const { record } = props
  const Mida = () => (
    <MidaLink
      host={midaHosts}
      tag
      path={`/contacts/${record.dbnaId}`}
      description={record.contactId || record.registrarContactId}
    />
  )
  return (
    <>
      {props.reference ? (
        <>
          <Mida />
          <ReferenceTag />
        </>
      ) : (
        <Mida />
      )}
    </>
  )
}
