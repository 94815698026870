import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

/**
 * Titolo TAb composto da icona+descrizione
 * @param props
 * @constructor
 */
export function TabTitle(props: { iconType: IconDefinition; text: string }) {
  return (
    <>
      <FontAwesomeIcon icon={props.iconType as IconProp} style={{ marginRight: 5 }} />
      {props.text}
    </>
  )
}
