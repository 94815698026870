import { Div, HorizontalLayout } from '@nic/ui-comps'
import React from 'react'
import { useTranslation } from '@nic/commons'
import { useRequestTimeline } from '../useRequestTimeline'
import '../RequestProgress.css'
import {
  DaysLeft,
  IRequestProgress,
  RequestProgressLabel,
  RequestProgressView,
  TimeLabel,
} from '../index'

export function LinearRequestProgress(props: IRequestProgress) {
  const t = useTranslation()
  const { percentage, endDate } = useRequestTimeline(
    props.creationDate,
    props.duration,
    props.expireDays
  )
  // console.log('LinearRequestProgress', props)
  const remainingDays = props.expireDays || 0

  return (
    <>
      <Div padding={'0px 30px 20px 10px'}>
        <RequestProgressView
          remainingDays={remainingDays}
          isPaused={props.paused || false}
          percentage={percentage}
          isOverLabel={`${t('requestProgress.over')}`}
          type={'line'}
        />
      </Div>
      <HorizontalLayout>
        <Div>
          <TimeLabel
            label={`${t('requestProgress.open')}`}
            date={props.creationDate}
            color={'lightBlue'}
            isOver={false}
            hideTime={true}
          />
        </Div>
        <Div>
          <DaysLeft
            remainingDays={remainingDays}
            isPaused={props.paused}
            label={`${t('requestProgress.daysLeft')}`}
          />
        </Div>
        <Div>
          {props.paused ? (
            <RequestProgressLabel label={`${t('requestProgress.closed')}`}>
              <div className={'time-content'}>{`${t('requestProgress.reloading')}`}</div>
            </RequestProgressLabel>
          ) : (
            <TimeLabel
              label={`${t('requestProgress.closed')}`}
              date={endDate.toString()}
              color={'burlywood'}
              isOver={!(percentage < 100)}
              hideTime={true}
            />
          )}
        </Div>
      </HorizontalLayout>
    </>
  )
}
