import { useNextPrevRequest } from '../../Hooks/useNextPrevRequest'
import { Button } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import ReloadOutlined from '@ant-design/icons/lib/icons/ReloadOutlined'
import { useTranslation } from '@nic/commons'
import { NicScope } from '@nic/nic-api/types'
import { ButtonProps } from 'antd/lib/button/button'

/**
 * Pulsantiera con next reload prev buttons
 * @param props
 * @constructor
 */
export function NextReloadPrevButtons(props: {
  onReload: () => void
  registraId: number
  scope: NicScope
  requestId: number
  onNext: (id: number) => void
  onPrev: (id: number) => void
  showPrevNext?: boolean
}) {
  const { next, prev } = useNextPrevRequest(
    props.registraId,
    props.requestId,
    'pending',
    props.scope
  )
  const t = useTranslation()
  return (
    <div>
      <Button
        icon={<LeftOutlined />}
        disabled={prev === undefined}
        onClick={() => prev && props.onPrev(prev)}
      >
        {`${t('nextReloadPrevButtons.prev')}`}
      </Button>
      <Reload onReload={props.onReload} />
      <Button
        icon={<RightOutlined />}
        disabled={next === undefined}
        onClick={() => next && props.onPrev(next)}
      >
        {`${t('nextReloadPrevButtons.next')}`}
      </Button>
    </div>
  )
}

export function Reload(props: { onReload: () => void; label?: string } & ButtonProps) {
  return (
    <Button {...(props as ButtonProps)} icon={<ReloadOutlined />} onClick={props.onReload}>
      {props.label}
    </Button>
  )
}
