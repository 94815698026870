import React from 'react'
import { useRouter } from '@nic/commons'
import { RequestContext } from '../Contexts/RequestContext'
import {
  DarwinHelpers,
  getDocumentsInitialState,
  getDocumentsReducer,
  requestAnomaliesInitialState,
  requestAnomaliesReducer,
  requestContactsCountInitialState,
  requestContactsCountReducer,
  requestContactsInitialState,
  requestContactsReducer,
  requestDomainsCountInitialState,
  requestDomainsCountReducer,
  requestDomainsInitialState,
  requestDomainsReducer,
  requestEventsInitialState,
  requestEventsReducer,
  requestsByIdInitialState,
  requestsByIdReducer,
  requestsRequiredDocumentsInitialState,
  requestsRequiredDocumentsReducer,
} from '@nic/nic-api'
import { AuthContext } from '@nic/kc-auth'
import { Darwin, NicScope } from '@nic/nic-api/types'

export const RequestProvider: React.FC = ({ children }) => {
  const { match } = useRouter()
  const { keycloak } = React.useContext(AuthContext)
  const [id] = React.useState<number>((match.params as { id: number }).id)
  const scope = keycloak?.realm as NicScope
  const [stateReqById, dispatchReqById] = React.useReducer(
    requestsByIdReducer,
    requestsByIdInitialState
  )
  const [stateReqAnomalies, dispatchReqAnom] = React.useReducer(
    requestAnomaliesReducer,
    requestAnomaliesInitialState
  )

  const [stateReqEvents, dispatchReqEvents] = React.useReducer(
    requestEventsReducer,
    requestEventsInitialState
  )

  const [stateReqContacts, dispatchReqContacts] = React.useReducer(
    requestContactsReducer,
    requestContactsInitialState
  )

  const [stateReqDomains, dispatchReqDomains] = React.useReducer(
    requestDomainsReducer,
    requestDomainsInitialState
  )

  const [stateReqDomainsCount, dispatchReqDomainsCount] = React.useReducer(
    requestDomainsCountReducer,
    requestDomainsCountInitialState
  )

  const [stateReqContactsCount, dispatchReqContactsCount] = React.useReducer(
    requestContactsCountReducer,
    requestContactsCountInitialState
  )

  const [stateRequiredDocuments, dispatchRequiredDocuments] = React.useReducer(
    requestsRequiredDocumentsReducer,
    requestsRequiredDocumentsInitialState
  )
  const [stateReqDocuments, dispatchReqDocuments] = React.useReducer(
    getDocumentsReducer,
    getDocumentsInitialState
  )
  // const [stateReqExpireDays, dispatchReqExpireDays] = React.useReducer(
  //   requestExpirationDayReducer,
  //   requestExpirationDaysByIdInitialState
  // )

  function reload() {
    id && requestLoad(id)
  }

  function cleanRequiredDocument() {
    dispatchRequiredDocuments({ type: 'POST_REQUEST_REQUIRED_DOCUMENTS_CLEAN' })
  }

  function reloadDocs() {
    DarwinHelpers.documents(dispatchReqDocuments, { requestId: id }, scope)
  }

  function requestLoad(_id: number) {
    DarwinHelpers.request(dispatchReqById, _id, scope)
    DarwinHelpers.anomalies(dispatchReqAnom, _id, scope)
    DarwinHelpers.events(dispatchReqEvents, { requestId: _id, size: 5, page: 0 }, scope)
    DarwinHelpers.contacts(dispatchReqContacts, { requestId: _id, size: 5, page: 0 }, scope)
    DarwinHelpers.domains(dispatchReqDomains, { requestId: _id, size: 5, page: 0 }, scope)
    DarwinHelpers.domainsCount(dispatchReqDomainsCount, { requestId: _id }, scope)
    DarwinHelpers.contactsCount(dispatchReqContactsCount, { requestId: _id }, scope)
    DarwinHelpers.documents(dispatchReqDocuments, { requestId: _id }, scope)
    /* Chiama l'endpoint solo se la richiesta è nello stato 'pending' e 'registrarUpdate' */
    // if (stateReqById.data?.status && ['pending', 'registrarUpdated'].includes(stateReqById.data.status as string)){
    //   DarwinHelpers.getRequestExpirationDays(dispatchReqExpireDays, _id, scope)
    // }
  }

  function loadDomains(page: number, size: number) {
    DarwinHelpers.domains(dispatchReqDomains, { requestId: id, size, page }, scope)
  }

  function postRequiredDocuments(data: Darwin.RequiredDocumentsBody) {
    DarwinHelpers.postRequiredDocuments(dispatchRequiredDocuments, id, data)
  }

  React.useEffect(() => {
    console.debug('RequestProvider Render')
    const loadRequest = () => reload && reload()
    loadRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RequestContext.Provider
      value={{
        stateReqById,
        stateReqAnomalies,
        stateReqEvents,
        stateReqContacts,
        stateReqDomains,
        stateReqContactsCount,
        stateReqDomainsCount,
        stateReqDocuments,
        stateRequiredDocuments,
        dispatchReqDocuments,
        dispatchReqEvents,
        scope,
        id,
        reload: () => reload(),
        load: (reqId) => requestLoad(reqId),
        loadDomains: (page, size) => loadDomains(page, size),
        loadDocuments: () => reloadDocs(),
        postRequiredDocuments: (_data: Darwin.RequiredDocumentsBody) =>
          postRequiredDocuments(_data),
        cleanRequiredDocument: () => cleanRequiredDocument(),
      }}
    >
      {children}
    </RequestContext.Provider>
  )
}
