import { DetailCardBox, DetailCardRow, IDetailCard } from '@nic/ui-comps'
import React from 'react'
import { uuid } from '@nic/commons'
import { ContactReportType } from '../types'

export function ContactReportDetailBox(props: KeyValueBoxProps<ContactReportType>) {
  const KeyValueDetailBoxComp = KeyValueDetailBox<typeof props.data>()
  if (!props.data) {
    return <React.Fragment />
  }
  return (
    <KeyValueDetailBoxComp
      className={props.className}
      data={props.data}
      rows={props.rows}
      style={props.style}
      height={props.height}
      labelWidth={'150px'}
      valueWidth={'auto'}
      maxValueWidth={'300px'}
      rowGap={'0px'}
      labelBold={true}
      // mediaMaxWidthDirColumn={'1000px'}
      mediaMaxWidthAutoHeight={[
        // {
        //   value: '1500px',
        //   cssStyle: 'height:270px',
        // },
        // {
        //   value: '1400px',
        //   cssStyle: 'height:520px',
        // },
        {
          value: '1000px',
          cssStyle: 'height:auto',
        },
      ]}
    />
  )
}

/**
 * Stampa del dettaglio (chiave -> valore)
 * @param props
 * @constructor
 */
export function DetailBox(props: React.PropsWithChildren<IDetailCard>) {
  return (
    <DetailCardBox
      className={`detail-card-box ${props.className ? props.className : ''}`}
      mediaMaxWidthAutoHeight={props.mediaMaxWidthAutoHeight}
      style={props.style}
      height={props.height}
      columnGap={props.columnGap}
      rowGap={props.rowGap}
    >
      {props.children}
    </DetailCardBox>
  )
}

export interface KeyValueBoxProps<T> extends IDetailCard {
  /** oggetto da cui recupera i dati, del tipo Key:value **/
  data: T
  /** definzione di quali Keys stampare e come formattale **/
  rows: DefinitionRowType<T>[]
}

/***
 * Oggetto generico che crea un Detail Card e come contenuto
 * inserisce lo srotolamento delle proprietà dell'oggetto passato in data.
 * L'oggetto passato deve essere rigorosamente formato nel tipo Key:value
 * dove value può assumere il tipo che vuole.
 * Che cosa stampare e come sarà controllato tramite le definizione delle righe
 * fatta tramite la proprietà rows.
 * @constructor
 */
export const KeyValueDetailBox =
  <T,>(): React.FC<KeyValueBoxProps<T>> =>
  (props) => {
    return (
      <DetailBox {...props}>
        {props.data !== undefined &&
          rolloutData<typeof props.data>({
            ...props,
            data: props.data,
          }as any)}
      </DetailBox>
    )
  }

/**
 * Srotola le proprietà dell'oggetto per stamparle come righe
 * @param props
 */
export function rolloutData<T>(props: KeyValueBoxProps<T>) {
  const { data, rows } = props
  return rows?.map((row, index) => {
    const element = data[row.index as keyof T]
    if (element) {
      return renderRow<T>({
        objData: data,
        row,
        value: { ...element },
        ...props,
      })
    }
    return <></>
  })
}

export interface RenderRowProps<T> extends IDetailCard {
  /** contenuto di tutto l'oggetto **/
  objData: T
  /** contenuto della definizione della riga passata, identifica l'attuale chiave  */
  row: DefinitionRowType<T>
  /** valore che corrisponde al contenuto selezionato tramite la chiave proprietà presa da row ed estratto dell'oggetto **/
  value: T[keyof T]
}

/**
 * Stampa la riga chiave valore di una definizione di rows
 * @param props
 */
export function renderRow<T>(props: RenderRowProps<T>) {
  const { value, row, objData } = props
  // console.log(renderRow.name, props)

  if (row.hide) {
    return <React.Fragment key={uuid()} />
  }

  return (
    <DetailCardRow
      {...props}
      key={uuid()}
      label={row.label ? row.label : row.index}
      value={row.render ? row.render(objData, value, row.index) : JSON.stringify(value)}
    />
  )
}

export interface DefinitionRowType<T> {
  index: keyof T
  label?: string
  defaultStyle?: React.CSSProperties
  render?: (
    record: T,
    value: T[keyof T] | any,
    fieldKey: string | keyof T
  ) => React.ReactElement<any>
  hide?: boolean
}
