import { useContext } from 'react'
import { contactAnomaliesUpdatesBuilder } from './helpers'
import { CandidateRegistrantContext } from '../Providers/CandidateRegistrant'
import { RegistrantLoaderProps } from './types'

export const RegistrantCandidateData: React.FC<RegistrantLoaderProps> = ({ children }) => {
  const { state: stateCandidate } = useContext(CandidateRegistrantContext)

  const loading =
    stateCandidate.loading
  const error = stateCandidate.error

  const data = contactAnomaliesUpdatesBuilder(
    stateCandidate.data,
    undefined,
    undefined
  )

  return children({ data, loading, error })
}

/*** UDATED **/
// error={
//   stateContactAnomalies.error ||
//       (!noContactUpdateData && stateContactUpd.error) ||
//       stateReferenceDarwin.error
// }
// loading={
//   stateContactAnomalies.loading || stateReferenceDarwin.loading || stateContactUpd.loading
// }

/*** CANDIDATE **/
// error={stateContactAnomalies.error || stateCandidate.error}
// loading={loading}

/*** REFERENCE **/
// error={stateContactAnomalies.error || stateContactUpd.error || stateReferenceDarwin.error}
// loading={loading}

/** COMPLETED **/
// error={!noContactUpdateData && stateContactUpd.error}
// loading={loading}
