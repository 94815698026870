import React from "react";
import { useTranslation } from "@nic/commons";
import DirectoryTree from "antd/lib/tree/DirectoryTree";
import { Darwin, NicScope } from "@nic/nic-api/types";
import { buildDarwinDocumentUploadFileDataNode, openFile } from "../utils";
import { DownOutlined } from "@ant-design/icons";
import { Card, Empty, Spin } from "antd";
import { DocumentsCardProps } from "../../Cards/DocumentsCard";
import { Div } from "@nic/ui-comps";

export const DocumentList: React.FC<DocumentsCardProps> = ({
  scope,
  renderDocumentItem,
  uploadedDocumentList,
  loading,
}) => {
  const t = useTranslation();

  return (
    <Card title={<b>{t("cardDocuments.divider")}</b>}>
      {loading ? (
        <Div textAlign={"center"}>
          <Spin />
        </Div>
      ) : uploadedDocumentList && uploadedDocumentList.length > 0 ? (
        <DirectoryTree
          onSelect={(selectedKeys, info) => {
            const { node } = info;
            // @ts-ignore
            const fileResponse =
              node.response as Darwin.DarwinUploadedFileResponse;
            node &&
              openFile(
                scope as NicScope,
                fileResponse.id as string,
                fileResponse.fileType as "pdf" | "png",
                fileResponse.fileName
              );
          }}
          showIcon
          switcherIcon={<DownOutlined />}
          treeData={buildDarwinDocumentUploadFileDataNode(
            t,
            uploadedDocumentList || [],
            renderDocumentItem
          )}
        />
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("noDocs")} />
      )}
    </Card>
  );
};
