import { Alert, Popover, Tag } from 'antd'
import React from 'react'
import { AxiosError } from 'axios'
import { useTranslation } from '@nic/commons'

export const ErrorAlert: React.FC<{ error: AxiosError }> = ({ error }) => {
  const t = useTranslation()
  return (
    <Alert
      message="Errore"
      description={
        <>
          {printAxiosFetchError(error)}
          <Popover content={<pre>{JSON.stringify(error, null, 2)}</pre>}>
            <span style={{ float: 'right' }}>
              {' '}
              <Tag color={'error'}>{t('Details')}</Tag>
            </span>
          </Popover>
        </>
      }
      type={'error'}
      showIcon={true}
    />
  )
}

export function printAxiosFetchError(error: AxiosError): string {
  console.group('printAxiosFetchError')
  console.log('raw', JSON.stringify(error))
  console.log('response', JSON.stringify(error.response))
  if (error.response && error.response.data) {
    const { response } = error
    console.error(JSON.stringify(error.response))
    return `(${response.statusText}) - ${JSON.stringify(response.data)} ${
      response.data.errors ? JSON.stringify(response.data.errors) : ''
    }  `
  }
  console.groupEnd()

  return JSON.stringify(error)
}
