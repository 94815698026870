import { Darwin } from '@nic/nic-api/types'
import React, { useContext } from 'react'
import { Breadcrumb } from '../Breadcrumb/Breadcrumb'
import { useRouter, useTranslation } from '@nic/commons'
import { useScopeInfo } from './useScopeInfo'
import { LayoutContext } from '../../Context/LayoutContext'

export function useBreadcrumb() {
  const t = useTranslation()
  const { scope } = useScopeInfo()
  const router = useRouter()
  const { setLayoutElement } = useContext(LayoutContext)
  const anomalyQueryParams = router.params as Darwin.GetRequestAnomaliesParam

  React.useEffect(() => {
    setLayoutElement(
      'BREADCRUMB',
      <Breadcrumb
        elements={[
          { label: t('breadcrumb.dashboard'), path: `/${scope}/dashboard` },
          { label: t('breadcrumb.requests'), path: `/${scope}/requests?view=expirationDays,referenceContact` },
          {
            label: `${t('breadcrumb.request')}: ${anomalyQueryParams.id}`,
            path: `/${scope}/requests/${anomalyQueryParams.id}`,
          },
        ]}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope, anomalyQueryParams.id, t])
}
