import { Div, HorizontalLayout, PaperLayout } from '@nic/ui-comps'
import { camelToDash, Suspense, useTranslation } from '@nic/commons'

import React, { useContext } from 'react'
import { Redirect } from 'react-router'
import { Divider, Space } from 'antd'
import { RequestContext } from '../Contexts/RequestContext'
import { RequestDetail } from '../DataDisplay/RequestDetail/RequestDetail'
import { Reload } from '../Navigation/NextReloadPrevButtons/NextReloadPrevButtons'
import { AxiosError } from 'axios'
import { printAxiosFetchError } from '../ErrorAlert/ErrorAlert'
import { AssignmentRequestContent } from './AssignmentRequestContent'

/**
 * Box contenente le informazioni e i controlli della Richiesta:
 * - Dettagli Richiesta
 * - Navigatore (Prev - Reload - Next) Richiesta
 * - Note Richiesta
 * @constructor
 */
export function RequestBox(props: { onReload: () => void }) {
  const request = useContext(RequestContext)
  const { scope } = useContext(RequestContext)
  const t = useTranslation()

  const handleOnReload = () => {
    props.onReload()
    request.reload && request.reload()
  }

  /**
   * Condizione per poter visualizzare le note:
   * che sia un operatore Registry e che le note esistano
   **/
  const hasNote = (note?: string): boolean => {
    return !!(note && scope === 'registry')
  }
  const handleError = (e: AxiosError) => {
    switch (e.response?.status) {
      case 404:
        return <Redirect to={'/404'} />
      case 403:
        return <Redirect to={'/403'} />
      default:
        return <>{printAxiosFetchError(e)}</>
    }
  }
  return (
    <Div width={'100%'} className={camelToDash(RequestBox.name)} style={{ minWidth: '600px' }}>
      <Suspense
        renderError={handleError}
        state={request.stateReqById}
        render={(data) => (
          <>
            <PaperLayout asCard={true} width={'100%'}>
              <HorizontalLayout
                style={{
                  gap: 20,
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <RequestDetail data={data} scope={request.scope} />
                <Space>
                  <Reload onReload={handleOnReload} type={'primary'} label={t('Reload')} />
                </Space>
              </HorizontalLayout>
              <Divider />
              <AssignmentRequestContent labels />
            </PaperLayout>
            {hasNote(data.note) && (
              <PaperLayout asCard style={{ backgroundColor: '#fff6b5' }}>
                <b>{t('Note')}</b> {data.note}
              </PaperLayout>
            )}
          </>
        )}
      />
    </Div>
  )
}
