import { AxiosError } from 'axios'

export function printAssignmentRequestActionError(props: { error: AxiosError }) {
  let message: string
  if (props.error?.response?.data?.message) {
    message = props.error.response.data?.message
  } else if (props.error?.response?.status) {
    message = props.error.response.status.toString()
  } else if (props.error?.message) {
    message = props.error.message
  } else {
    message = 'Error'
  }

  return message
}
