import StatusView from '../Status/StatusView'
import {
  countRejectedRequests,
  RejectedRequestBadgedLabel,
} from '../Status/RejectedRequestBadgedLabel'
import { AssignmentRequestActions } from '../AssignmentRequests/ButtonsAssignmentRequestActions'
import { stdDateHelper } from '../../Utils/DateUtils'
import { RequestReferenceItem } from './ReferenceItem'
import { IRequestTableData } from './RequestsTable'
import { Link } from 'react-router-dom'
import { Darwin } from '@nic/nic-api/types'
import { IReferenceContact } from '@nic/nic-api/types/Darwin/types'
import { Typography } from 'antd'
import { Div } from '@nic/ui-comps'

const { Text } = Typography

export const requestsTableRegistrar = (router: any, data: any, keycloak: any) => {
  return {
    id: (id: number) => <Link to={`${router.pathname}/${id}`}>{id}</Link>,
    status: (status: Darwin.RequestStatus, record: IRequestTableData) => {
      return <StatusView status={status} />
    },
    extra: (status: Darwin.RequestStatus, record: IRequestTableData) => {
      if (['pending', 'registrarUpdated'].includes(status)) {
        const currentRow = data?.filter((d: any) => d.id === record.id)[0]
        return (
          <RejectedRequestBadgedLabel count={countRejectedRequests(currentRow?.events as any)} />
        )
      }
      return <></>
    },
    action: (text: any, record: IRequestTableData) => {
      const currentRow = data?.filter((d: any) => d.id === record.id)[0]
      if (currentRow?.status === 'pending' || currentRow?.status === 'registrarUpdated') {
        return (
          <div style={{ float: 'right' }}>
            <AssignmentRequestActions
              isAssignedUser={keycloak?.tokenParsed?.userId === currentRow?.registrarUser?.id}
              requestId={record.id}
            />
          </div>
        )
      } else {
        return <></>
      }
    },
    created: (created: string) => <span>{stdDateHelper(created)}</span>,
    referenceContact: (referenceContact: IReferenceContact) => {
      return (
        referenceContact && (
          <div style={{ minWidth: '200px' }}>{RequestReferenceItem(referenceContact)}</div>
        )
      )
    },
    domains: (value: number, record: IRequestTableData) => (
      <div style={{ textAlign: 'right' }}>
        <>{record.domains}</>
      </div>
    ),
    contacts: (value: number, record: IRequestTableData) => (
      <div style={{ textAlign: 'right' }}>
        <>{record.contacts}</>
      </div>
    ),
    registrarUser: (value: Darwin.RegistrarUser, record: IRequestTableData) => (
      <Div style={{ textAlign: 'center', fontSize: '0.8rem' }}>
        {value ? <Text strong>{value.username}</Text> : <></>}
      </Div>
    ),
  }
}
