import React, { useContext } from 'react'
import { FactoryContactCard } from '../../DataDisplay/RegistrantContactCards/FactoryContactCard'
import { NicScopeHandler } from '../../Various/NicScopeHandler/NicScopeHandler'
import { ContactUpdatedContext } from '../../Providers/ContactUpdatedProvider'
import { RegistrantReferenceData } from '../../RegistrantLoader/RegistrantReferenceData'
import { RegistrantUpdatedData } from '../../RegistrantLoader/RegistrantUpdatedData'
import { RegistrantUpdatedWithReadsFeedback } from './RegistrantUpdatedWithReadsFeedback'
import { useScopeInfo } from '../../Hooks/useScopeInfo'
import { useRequestInfo } from '../../Hooks/useRequestInfo'
import { camelToDash } from '@nic/commons'

/***
 *
 * @constructor
 */
export function RegistrantUpdatedHandler() {
  const { state: stateContactUpd } = useContext(ContactUpdatedContext)
  const { requestId, scope } = useScopeInfo()
  const { request } = useRequestInfo()
  const readsValidationStatus = stateContactUpd?.data?.readsValidation
  const hasValidationStatus = request?.status === 'registrarUpdated' && readsValidationStatus

  const RegistryContent = () => (
    <div style={{ width: '100%' }} className={camelToDash(RegistrantUpdatedHandler.name)}>
      {hasValidationStatus ? (
        <RegistrantUpdatedWithReadsFeedback
          readsValidationStatus={readsValidationStatus}
          scope={scope}
          requestId={requestId}
        />
      ) : (
        <RegistrantUpdatedData>
          {(data) => <FactoryContactCard type={'updated'} {...data} />}
        </RegistrantUpdatedData>
      )}
    </div>
  )

  return (
    <NicScopeHandler
      registry={<RegistryContent />}
      registrar={
        <RegistrantReferenceData>
          {(data) => <FactoryContactCard type={'reference'} {...data} />}
        </RegistrantReferenceData>
      }
    />
  )
}
