import { Modal } from 'antd'
import React, { useContext } from 'react'
import { RequestContext } from '../../Contexts/RequestContext'
import { RequestPostApproveService } from '@nic/nic-api'
import { useTranslation } from '@nic/commons'
import { ErrorAlert } from '../../ErrorAlert/ErrorAlert'

export function VariationProposalRegistryAcceptModal(props: {
  showModalAccept: boolean
  setShowModalAccept: (value: ((prevState: boolean) => boolean) | boolean) => void
  onOk: () => void
}) {
  const t = useTranslation()
  const { id } = useContext(RequestContext)
  const [loading, setLoading] = React.useState<boolean>()
  const [acceptSuccess, setAcceptSuccess] = React.useState()
  const [acceptFail, setAcceptFail] = React.useState<any>()

  React.useEffect(() => {
    setAcceptFail(undefined)
  }, [acceptSuccess])

  const handleOnSubmit = async () => {
    setLoading(true)
    try {
      const rej = await RequestPostApproveService(id)
      setAcceptSuccess(rej)
      setLoading(false)
      props.onOk()
    } catch (e) {
      setLoading(false)
      setAcceptFail(e)
    }
  }

  return (
    <Modal
      title={<b>{t('variationProposalRegistryAcceptModal.modalTitle')}</b>}
      visible={props.showModalAccept}
      destroyOnClose
      okButtonProps={{ loading, disabled: acceptFail }}
      onOk={handleOnSubmit}
      onCancel={() => {
        setAcceptFail(undefined)
        props.setShowModalAccept(!props.showModalAccept)
      }}
    >
      <p>
        {`${t('variationProposalRegistryAcceptModal.p1.p1')} `}
        <b>{`${t('variationProposalRegistryAcceptModal.p1.bold1')} `}</b>
        {`${t('variationProposalRegistryAcceptModal.p1.p2')} `}
      </p>

      <p>
        {`${t('variationProposalRegistryAcceptModal.p2.p1')} `}
        <b>{`${t('variationProposalRegistryAcceptModal.p2.bold1')} `}</b>
      </p>
      {acceptFail && (
        <div style={{ marginTop: 10 }}>
          <ErrorAlert error={acceptFail} />
        </div>
      )}
    </Modal>
  )
}
