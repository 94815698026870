import React from 'react'
import { Button, Result } from 'antd'
import { Link } from 'react-router-dom'
import { Div } from '@nic/ui-comps'
import { useTranslation } from '@nic/commons'

export const Page403: React.FC = () => {
  const t = useTranslation()
  return (
    <Div width={'100%'}>
      <Result
        status="403"
        title="403"
        subTitle={t('403message')}
        extra={
          <Button type="primary">
            <Link to={'/'}>Dashboard</Link>
          </Button>
        }
      />
    </Div>
  )
}

//Sorry, you are not authorized to access this page.
//Spiacente, l'utente non ha i permessi per accedere a questa pagina.
