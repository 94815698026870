import React from 'react'
import { Div, HorizontalLayout, PaperLayout } from '@nic/ui-comps'
import { Statistic, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import './Counter.css'
import { AxiosError } from 'axios'
import { useTranslation } from '@nic/commons'

interface ICounter {
  title: string
  icon?: React.ReactElement<any>
  redirect: string
  onClick?: () => void
  color?: string
  value?: number
  loading: boolean
  asCard?: boolean
  /** disabilità il link */
  disable?: boolean
  type?: 'noLink' | 'link'
  backgroundColor?: string
  className?: string
  error?: any
  tip?: string
}

export function Counter({
  redirect,
  onClick,
  icon,
  title,
  type,
  value,
  color,
  loading,
  disable,
  asCard = true,
  backgroundColor,
  className,
  error,
  tip,
}: ICounter) {
  const t = useTranslation()
  const Content = () => (
    <HorizontalLayout style={{ gap: 10 }}>
      {icon && <Div>{icon}</Div>}
      <Statistic
        className={className}
        title={title}
        value={error ? t('someThingWrong') + (error as AxiosError)?.response?.status : value}
        valueStyle={{ color: disable ? 'grey' : !error ? color : 'red' }}
        loading={loading}
      />
    </HorizontalLayout>
  )

  const LinkController = () => {
    if (type === 'noLink') {
      return (
        <Div
          color={'rgb(24, 144, 255)'}
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation()
            onClick && onClick()
          }}
        >
          <Content />
        </Div>
      )
    }

    return (
      <Link to={redirect}>
        <Content />
      </Link>
    )
  }

  return (
    <Tooltip title={tip}>
      <PaperLayout asCard={asCard} data-cy="counter" backgroundColor={backgroundColor}>
        {!disable ? <LinkController /> : <Content />}
      </PaperLayout>
    </Tooltip>
  )
}
