import {Button, Form, FormInstance, Input, Popover, Space} from 'antd'
import React, {ReactElement, useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAnglesRight} from '@fortawesome/free-solid-svg-icons'
import {IconProp} from '@fortawesome/fontawesome-svg-core'
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons'
import {ExtendedAnomaly} from '../../../../../../types'
import {FormItemProps} from 'antd/lib/form/FormItem'
import {NamePath} from 'antd/es/form/interface'
import {useTranslation} from '@nic/commons'
import {Div} from '@nic/ui-comps'
import {Darwin} from '@nic/nic-api/types'

export interface IFieldError {
    /** valore errato */
    value?: any
    /** messaggio errore */
    message?: string
    /** epp code */
    code?: number[]
    /** nome del campo errato */
    field?: string
}

export const INPUT_FIELD_WIDTH = '30vw'

/**
 * Funzione per la renderizzazione in caso di anomalia con stato interno per gestire l'errore
 * Quando c'è l'anomalia stampa un form
 * @param anomaly
 * @param form
 * @param error
 */
export function RenderAnomalyFieldInput(
    anomaly: ExtendedAnomaly,
    form: FormInstance,
    error?: IFieldError
) {
    return (
        <InputErrorHandler anomaly={anomaly} form={form} error={error}>
            {(resetError: () => void) => (
                <Input onChange={resetError} style={{width: INPUT_FIELD_WIDTH}}/>
            )}
        </InputErrorHandler>
    )
}

export const InputErrorHandler: React.FC<
    {
        form: FormInstance
        error?: IFieldError
        children: (resetError: () => void) => ReactElement
        name?: NamePath
        anomaly: ExtendedAnomaly
    } & FormItemProps
> = (props) => {
    const t = useTranslation()
    return (
        <>
            <FormItemWrapper name={props.name ? props.name : props.anomaly.originalField} {...props}>
                {(resetError: () => void) => props.children(resetError)}
            </FormItemWrapper>
            {showOriginalValue(props.anomaly.originalValue, props.anomaly, t)}
        </>
    )
}

export const FormItemWrapper: React.FC<
    {
        form: FormInstance
        error?: IFieldError
        children: (resetError: () => void) => ReactElement
        name?: NamePath
    } & FormItemProps
> = (props) => {
    const [localError, setLocalError] = useState(props.error)
    console.log('localError', localError)

    const resetError = () => {
        setLocalError({value: ''})
    }

    return (
        <Form.Item
            style={{margin: 0}}
            validateStatus={localError?.value ? 'error' : 'success'}
            help={localError?.value ? localError.message : undefined}
            hasFeedback={localError?.value}
            {...props}
        >
            {props.children(resetError)}
        </Form.Item>
    )
}

export function showOriginalValue(initialValue: React.ReactNode, anomaly: Darwin.Anomaly | undefined, t: any) {
    // console.log('showOriginalValuexx', initialValue, anomaly)
    return (
        <div>
            <Popover
                content={
                    <Space direction={'vertical'}>
                        <Div>{t('initial')}</Div>
                        <Div fontWeight={'700'}>{initialValue} </Div>
                        <Div>{t('issues')}</Div>
                        <ul>
                            {anomaly?.codes.map((a: any, i: number) => {
                                return (
                                    <li key={i}>
                                        <Div fontWeight={'700'}>{t(`anomalies.${a}`)}</Div>
                                    </li>
                                )
                            })}
                        </ul>
                    </Space>
                }
            >
                <div
                    style={{
                        display: 'inline-block',
                        color: anomaly && anomaly.codes.length > 0 ? 'darkgray' : 'darkgray',
                        fontStyle: 'italic',
                        paddingRight: 10,
                        marginTop:5,
                        backgroundColor: anomaly && anomaly.codes.length > 0 ? '#faeded' : '',
                        width: 'auto',
                    }}
                >
                    <FontAwesomeIcon
                        icon={faAnglesRight as IconProp}
                        style={{color: anomaly && anomaly.codes.length > 0 ? 'red' : 'lightgray', marginRight: 5}}
                    />
                    {initialValue}
                </div>
            </Popover>
        </div>
    )
}

/**
 * Funzione per la renderizzazione in caso di anomalia
 * Quando c'è l'anomalia stampa un form
 * @param anomaly
 * @param form
 * @param t
 * @param error
 * @param maxElems
 * @param required
 */
export function RenderAnomalyArrayFieldInput(
    anomaly: ExtendedAnomaly,
    form: FormInstance,
    t: (key: string, obj?: any) => string,
    error?: IFieldError,
    maxElems?: number,
    required?: boolean
) {
    const fieldName = t(`field.${anomaly.originalField}`)
    const maxElements = maxElems ?? 3
    const requiredField = required ?? false
    return (
        <Form.List
            name={anomaly.originalField}
            rules={[
                {
                    validator: async (a, fields) => {
                        console.log('renderAnomalyArrayFieldInput Validation.....', a, fields)
                        if (fields.includes(undefined)) {
                            return Promise.reject(new Error(t('error.invalidValue', {field: fieldName})))
                        }
                        // let found = false;
                        // let i=0;
                        // while (!found) {
                        //   const ff = fields[i] as string;
                        //   if ( ff !== undefined && ff.trim().length === 0) {
                        //     found = true;
                        //   }
                        //   i = i+1
                        // }
                        // if (found) {
                        //   return Promise.reject(new Error(t('error.invalidValue', {field: fieldName})))
                        // }
                    },
                },
            ]}
        >
            {(fields, {add, remove}, {errors}) => {
                console.log(' renderAnomalyArrayFieldInput Errors: ', errors)
                console.log(' renderAnomalyArrayFieldInput Error: ', JSON.stringify(error))
                return (
                    <Space direction={'vertical'}>
                        {fields.map((field: any, i: number) => {
                            console.log('renderAnomalyArrayFieldInput Field:> ', field, i)
                            console.log(
                                'renderAnomalyArrayFieldInput Error ',
                                JSON.stringify(error),
                                error?.field?.includes(`[${field.key}]`) ? error : undefined
                            )
                            return (
                                <Space key={field.key} direction={'horizontal'}>
                                    <FormItemWrapper
                                        form={form}
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: requiredField,
                                            },
                                        ]}
                                        error={error}
                                        // noStyle
                                    >
                                        {(resetError: () => void) => (
                                            <Input style={{width: INPUT_FIELD_WIDTH}} onChange={resetError}/>
                                        )}
                                    </FormItemWrapper>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                    ) : null}
                                </Space>
                            )
                        })}
                        <Space direction={'vertical'} size={'small'}>
                            <Space direction={'horizontal'}>
                                {showOriginalValue(anomaly.originalValue, anomaly, t)}
                            </Space>
                            <Form.Item>
                                {fields.length < maxElements && (
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{width: '60%'}}
                                        icon={<PlusOutlined/>}
                                    >
                                        {`${t(fieldName)}`}
                                    </Button>
                                )}
                                <Form.ErrorList errors={errors}/>
                            </Form.Item>
                        </Space>
                    </Space>
                )
            }}
        </Form.List>
    )
}
