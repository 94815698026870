import { Darwin, NicScope } from "@nic/nic-api/types";
import { HorizontalLayout } from "@nic/ui-comps";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React from "react";

/**
 * Metodo che permette la presentazione delle informazioni del Documento all'interno del componente Tree
 * Mostra anche il controllo per l'eliminazione del documento
 * @param uploadedFileResponse
 * @param scope
 * @param onDelete
 */
export const RenderDocumentItem = (
  uploadedFileResponse: Darwin.DarwinUploadedFileResponse,
  scope: NicScope,
  onDelete?: (docId: string) => void
) => {
  return (
    <HorizontalLayout style={{ display: "inline" }}>
      {uploadedFileResponse.fileName}{" "}
      {uploadedFileResponse.note && (
        <>
          - <i>{uploadedFileResponse.note}</i>
        </>
      )}
      {scope === "registrar" && uploadedFileResponse.id && onDelete && (
        <Button
          type={"link"}
          style={{ marginLeft: 50 }}
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            uploadedFileResponse.id && onDelete(uploadedFileResponse.id);
          }}
        />
      )}
    </HorizontalLayout>
  );
};
