import { NicScope } from '@nic/nic-api/types'
import { createActionedCtx } from '@nic/commons'
import React from 'react'
import { DomainService } from '@nic/nic-api'

// usage
interface InitialStateP {
  loading: boolean
  error: any
  data?: any
}

const initialState: InitialStateP = {
  loading: false,
  error: undefined,
  data: undefined,
}
type AppState = typeof initialState
type Action =
  | { type: 'LOAD_DOMAINS_DETAIL' }
  | { type: 'LOAD_SINGLE_DOMAIN_DETAIL'; payload: number }
  | { type: 'FETCH_SINGLE_DOMAIN_DETAIL_FAILURE'; payload: any }
  | { type: 'FETCH_SINGLE_DOMAIN_DETAIL_SUCCESS'; payload: any }
  | { type: 'CLEAR_DOMAINS_DETAIL' }

function reducer(state: AppState, action: Action): AppState {
  console.debug('Reducer**', action, state)
  switch (action.type) {
    case 'LOAD_DOMAINS_DETAIL':
      return { ...state, loading: true }
    case 'LOAD_SINGLE_DOMAIN_DETAIL':
      return { ...state, loading: true }
    case 'FETCH_SINGLE_DOMAIN_DETAIL_FAILURE':
      return { ...state, loading: false, error: action.payload }
    case 'FETCH_SINGLE_DOMAIN_DETAIL_SUCCESS':
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    case 'CLEAR_DOMAINS_DETAIL':
      return initialState
    default:
      throw new Error()
  }
}

export const actions = {
  getDomain: (dbnaId: number, scope: NicScope) => async (dispatch: React.Dispatch<Action>) => {
    dispatch({ type: 'LOAD_DOMAINS_DETAIL' })
    dispatch({ type: 'LOAD_SINGLE_DOMAIN_DETAIL', payload: dbnaId })
    DomainService(dbnaId, scope)
      .then((response) => {
        dispatch({
          type: 'FETCH_SINGLE_DOMAIN_DETAIL_SUCCESS',
          payload: response.data,
        })
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_SINGLE_DOMAIN_DETAIL_FAILURE', payload: err })
        console.error(err)
      })
  },
}

const [ctx, Provider] = createActionedCtx(reducer, initialState, actions)
export const DomainsDetailContext = ctx
export const DomainsDetailProvider = Provider
