import { ContactReportContent, ContactReportType } from '../types'
import { useTranslation } from '@nic/commons'
import { RenderAnomaly } from '../../../Anomalies/Anomaly/RenderAnomaly'
import { printAnomalyDescriptions } from '@nic/ui-antd'
import React from 'react'
import { anomalyFoundStyle } from '../styles'

/***
 * Metodo Render in caso di anomalia
 * @param obj
 * @param element
 * @param fieldKeyGroup
 * @param defaultRender
 * @constructor
 */
export function RenderAnomalies(
  obj: ContactReportType,
  element: ContactReportContent,
  fieldKeyGroup: string,
  defaultRender?: (obj: ContactReportType, value: any) => React.ReactNode
) {
  const t = useTranslation()
  console.log(RenderAnomaly.name, fieldKeyGroup, obj, element)
  const renderFn = defaultRender ? defaultRender(obj, element.defaultValue) : element.defaultValue
  if (element.anomalies) {
    return (
      <RenderAnomaly
        value={renderFn}
        field={fieldKeyGroup as string}
        anomalyCssStyle={{ ...anomalyFoundStyle, overflow: 'auto' }}
        popOverContent={printAnomalyDescriptions(element.anomalies, t)}
        popOverTitle={t(`anomalyField`, { field: fieldKeyGroup })}
      />
    )
  }

  return <>{renderFn}</>
}
