import { Div, Flex, PaperLayout } from '@nic/ui-comps'
import { Divider, Empty, Pagination, Spin, Timeline } from 'antd'
import React, { useContext } from 'react'
import { useTranslation, uuid } from '@nic/commons'

import { IRequestEvent } from '@nic/nic-api/types/Darwin/types'
import { PaperTitle } from '../../Layout/PaperTitle'
import { sortBy } from 'lodash'
import { EventTypeColor } from '../../Status/const'
import { DarwinEvent } from '../../Events/DarwinEvent'
import { DarwinHelpers } from '@nic/nic-api'
import { NicScope } from '@nic/nic-api/types'
import { RequestContext } from '../../Contexts/RequestContext'

const sortEvents = (events: IRequestEvent[]) => {
  // return events.sort(
  //   (a: IRequestEvent, b: IRequestEvent) =>
  //     new Date(b.created).getTime() - new Date(a.created).getTime()
  // );
  return sortBy(events, 'created').reverse()
}

export const EventListCard = (props: { created?: string }) => {
  const t = useTranslation()
  const { stateReqEvents, stateReqById, dispatchReqEvents, scope } = useContext(RequestContext)

  const { data, error, loading } = stateReqEvents
  const dataLen = data?.elements?.length ?? 0

  let component = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />

  if (error) {
    component = <span style={{ color: 'red' }}>{error && error.message}</span>
  }

  if (loading) {
    component = <Spin />
  }

  if (!loading && !error && data !== undefined) {
    if (dataLen === 0) {
      component = (
        <Flex direction={'row'} justify={'center'}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noEvents')} />
        </Flex>
      )
    } else {
      component = (
        <Flex direction={'column'} justify={'flex-start'}>
          <Timeline style={{ width: '100%' }}>
            {sortEvents(data.elements).map((event: IRequestEvent) => {
              // @ts-ignore
              const eColor = EventTypeColor[event.type] ?? 'gray'
              return (
                <Timeline.Item key={uuid()} color={eColor}>
                  <DarwinEvent event={event} />
                </Timeline.Item>
              )
            })}
          </Timeline>
        </Flex>
      )
    }
  }

  const handleOnPagechange = (page: number, pageSize: number) => {
    stateReqById.data?.id &&
      DarwinHelpers.events(
        dispatchReqEvents,
        { requestId: stateReqById.data?.id, size: pageSize, page: page - 1 },
        scope as NicScope
      )
  }
  const handleOnSizeChange = (current: number, pageSize: number) => {
    stateReqById.data?.id &&
      DarwinHelpers.events(
        dispatchReqEvents,
        { requestId: stateReqById.data?.id, size: pageSize, page: current - 1 },
        scope as NicScope
      )
  }

  return (
    <PaperLayout asCard={false} style={{ minWidth: '400px' }}>
      <PaperTitle
        style={{ minWidth: '200px', alignContent: 'baseline' }}
        showIcon
        title={t('cardReqTimeline.title', {
          eventsNumber: stateReqEvents.data?.page?.totalElements,
        })}
        actions={
          <div style={{ textAlign: 'right' }}>
            {data?.page && (
              <Pagination
                size="small"
                onChange={handleOnPagechange}
                onShowSizeChange={handleOnSizeChange}
                current={data?.page?.number + 1}
                total={data?.page?.totalElements}
                pageSize={data?.page?.size}
                pageSizeOptions={[5, 10, 20, 50]}
                showSizeChanger
              />
            )}
          </div>
        }
      />

      <Divider />
      <Div>{component}</Div>
    </PaperLayout>
  )
}
