import { Space, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import React from "react";

export const EventMessage = (props: {
  message?: string;
  color: string;
  t: any;
}) => {
  let component =
    props.message !== undefined ? (
      <div
        style={{
          display: "inline-block",
          padding: "5px 5px 0px 0px",
          // backgroundColor: "#ffffe5",
        }}
      >
        <Space>
          <MailOutlined />
          <strong>{props.t("Note")}:</strong>
        </Space>
        <Typography.Paragraph
          style={{
            display: "inline-block",
            marginLeft: 5,
            padding: 3,
            backgroundColor: "#ffffe5",
          }}
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "more",
          }}
        >
          {props.message}
        </Typography.Paragraph>
      </div>
    ) : (
      <></>
    );
  return <>{component}</>;
};
