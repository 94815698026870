import { Darwin, NicScope } from '@nic/nic-api/types'
import React, { useContext, useState } from 'react'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { Suspense, useRouter, useTranslation } from '@nic/commons'
import { RequestContext } from '../../../Contexts/RequestContext'
import { ContactDomainsTable } from '../../../DataDisplay/ContactDomainsTable/ContactDomainsTable'
import { TabTitle } from '../../../Various/TabTitle/TabTitle'
import { PaperTitle } from '../../../Layout/PaperTitle'
import { DomainDetail } from '../../../DataDisplay/DomainDetail/DomainDetail'
import { CustomTabPane } from '../CustomTabPane'
import { Div, PaperLayout } from '@nic/ui-comps'
import { Skeleton, TabPaneProps } from 'antd'
import { DomainsDetailContext } from '../../../Providers/DomainsDetailProvider'
import { DetailResultNotification } from '../../../Feedback/DetailResultNotification'

export function DomainsTab(props: TabPaneProps) {
  const t = useTranslation()
  const router = useRouter()
  const { scope, stateReqDomains, loadDomains, stateReqById } = useContext(RequestContext)
  const { state, actions, dispatch } = useContext(DomainsDetailContext)
  const [selectedDomain, setSelectedDomain] = useState<Darwin.Domain | undefined>()

  const handleOnClickContactRow = (domain: Darwin.Domain) => {
    setSelectedDomain(domain)
  }

  const onChangePageHandler = (page?: number, pageSize?: number) => {
    router.pushHelper({ page: page, size: pageSize })
    loadDomains && page && pageSize && loadDomains(page - 1, pageSize)
  }

  React.useEffect(() => {
    selectedDomain && actions.getDomain(selectedDomain.dbnaId, scope as NicScope)(dispatch)
  }, [selectedDomain, dispatch, scope, actions])

  return (
    <CustomTabPane
      divStyle={{ padding: 10 }}
      tab={
        <Suspense
          state={stateReqDomains}
          spinner={<Skeleton.Button />}
          render={(data) => (
            <TabTitle
              iconType={faGlobe}
              //`Domini ${props.domains?.length ? '(' + props.domains?.length + ')' : ''}`)
              text={t('tabDomains.tabTitle', { domains: data?.page.totalElements })}
            />
          )}
        />
      }
      key={props.tabKey}
    >
      <PaperLayout>
        <PaperTitle showIcon title={t('tabDomains.title')} />
        <p>{t('tabDomains.paragraph1')}</p>
        <ul>
          <li>
            <b>{t('tabDomains.locked.title')}</b>
            {t('tabDomains.locked.description')}
          </li>
          <li>
            <b>{t('tabDomains.incompatible.title')}</b>
            {t('tabDomains.incompatible.description')}
          </li>
          <li>
            <b>{t('tabDomains.rejected.title')}</b>
            {t('tabDomains.rejected.description')}
          </li>
          <li>
            <b>{t('tabDomains.processed.title')}</b>
            {t('tabDomains.processed.description')}
          </li>
        </ul>
        <ContactDomainsTable
          readonly={stateReqById?.data?.status === 'completed'}
          loading={false}
          data={stateReqDomains.data?.elements}
          scope={scope}
          onRowClick={handleOnClickContactRow}
          selectedDomainId={selectedDomain?.id}
          withSelector
          pagination={{
            current:
              stateReqDomains.data?.page.number !== undefined
                ? stateReqDomains.data?.page.number + 1
                : 0,
            pageSize: stateReqDomains.data?.page.size,
            total: stateReqDomains.data?.page.totalElements,
          }}
          onChangePagination={onChangePageHandler}
        />
        {
          <Div style={{ backgroundColor: '#fafafa', padding: '15px 15px 15px 15px' }}>
            {selectedDomain ? (
              <PaperLayout>
                <PaperTitle showIcon title={t('tabDomains.detailTitle')} />
                <DomainDetail
                  data={{ ...selectedDomain, ...state.data, darwinStatus: selectedDomain?.status }}
                  scope={scope}
                />
              </PaperLayout>
            ) : (
              stateReqById?.data?.status !== 'completed' && (
                <DetailResultNotification elements={stateReqDomains.data?.elements.length} />
              )
            )}
          </Div>
        }
      </PaperLayout>
    </CustomTabPane>
  )
}
