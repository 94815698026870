import React from 'react'
import { DarwinHelpers, requestDomainsInitialState, requestDomainsReducer } from '@nic/nic-api'
import { NicScope } from '@nic/nic-api/types'

/**
 * Ritorna una lista di domini dato un ContactId
 * @param args { domainId?: number; registrant?: string }
 * @param scope
 */
export function useGetDomainsFromContactId(
  args: { domainId?: number; registrantContactId?: string },
  scope?: 'registry' | 'registrar'
) {
  const [stateContact, dispatch] = React.useReducer(
    requestDomainsReducer,
    requestDomainsInitialState
  )

  React.useEffect(() => {
    args.registrantContactId &&
      DarwinHelpers.domains(
        dispatch,
        { registrarContactId: args.registrantContactId },
        scope as NicScope
      )
  }, [args.registrantContactId, scope])

  return stateContact
}
