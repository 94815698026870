import React from 'react'

/**
 * Stampa la lista delle anomalie decodificate
 * @param anomalies
 * @param t
 */
export function PrintAnomalyList(anomalies: Array<number>, t: any) {
  return (
    <ul>
      {anomalies.map((c, k) => (
        <li key={k}> {t(`anomalies.${c}`)}</li>
      ))}
    </ul>
  )
}
