import React from 'react'
import { VerticalLayout } from '@nic/ui-comps'
import { RegistryRegistrantCards } from '../../../../../Cards/RegistryRegistrantCards/RegistryRegistrantCards'
import { LinearValidityRequestCard } from '../../../../../Cards/ValidityRequestCard'
import { camelToDash } from '@nic/commons'

export type RegistryProfileProps = {
  isRegistrarUpdatedStatus: boolean
  hasExpirationDays?: boolean
  loading?: boolean
}

/***
 * Visualizzazione nel caso di profilo Registry
 * @param isRegistrarUpdated
 * @param hasExpirationDays
 * @constructor
 */
export const RegistryProfile: React.FC<RegistryProfileProps> = ({
  isRegistrarUpdatedStatus,
  hasExpirationDays,
}) => (
  <VerticalLayout
    className={camelToDash(RegistryProfile.name)}
    style={{
      padding: '0 10px 0 10px',
    }}
  >
    <RegistryRegistrantCards />
    {hasExpirationDays && <LinearValidityRequestCard paused={isRegistrarUpdatedStatus} />}
  </VerticalLayout>
)
