import React from 'react'
import { RegistryProfile, RegistryProfileProps } from './RegistryProfile/RegistryProfile'
import { RegistrarProfile } from './RegistrarProfile/RegistrarProfile'
import { SummaryRequestTabProp } from '../SummaryRequestTab'
import { NicScopeHandler } from '../../../../Various/NicScopeHandler/NicScopeHandler'

export type RegistrarsCardProps = RegistryProfileProps & SummaryRequestTabProp

export const RegistrarsCard: React.FC<RegistrarsCardProps> = ({
  isRegistrarUpdatedStatus,
  hasExpirationDays,
  onChangeTab,
  loading,
}) => {
  return (
    // <NicScopeHandler
    //   registry={
    //     <RegistryProfile
    //       isRegistrarUpdatedStatus={isRegistrarUpdatedStatus}
    //       hasExpirationDays={hasExpirationDays}
    //     />
    //   }
    //   registrar={
    //     <RegistrarProfile
    //       isRegistrarUpdatedStatus={isRegistrarUpdatedStatus}
    //       hasExpirationDays={hasExpirationDays}
    //       onChangeTab={onChangeTab}
    //     />
    //   }
    // />
    <NicScopeHandler
      registry={
        <RegistryProfile
          loading={loading}
          isRegistrarUpdatedStatus={isRegistrarUpdatedStatus}
          hasExpirationDays={hasExpirationDays}
        />
      }
      registrar={
        <RegistrarProfile
          isRegistrarUpdatedStatus={isRegistrarUpdatedStatus}
          hasExpirationDays={hasExpirationDays}
          onChangeTab={onChangeTab}
        />
      }
    />
  )
}
