import React from 'react'
import { AuthContext } from '@nic/kc-auth'

export interface ScopeHandlerProps {
  registry?: React.ReactNode
  registrar?: React.ReactNode
  none?: React.ReactNode
}

/**
 * Gestisce il profile da visualizzare tra Registry e Registrar
 * @param registry
 * @param registrar
 * @param none
 * @constructor
 */
export const NicScopeHandler: React.FC<ScopeHandlerProps> = ({ registry, registrar, none }) => {
  const { keycloak } = React.useContext(AuthContext)
  const realm = keycloak?.realm

  switch (realm) {
    case 'registry':
      return <>{registry}</>
    case 'registrar':
      return <>{registrar}</>
    default:
      return <>{none}</>
  }
}
