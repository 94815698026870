import { useRequestInfo } from '../Hooks/useRequestInfo'
import React, { Dispatch, useContext } from 'react'
import { RequestContext } from '../Contexts/RequestContext'
import { CandidateRegistrantContext } from '../Providers/CandidateRegistrant'
import { RequestExpirationDaysContext } from '../Providers/RequestExpirationDaysProvider'
import { VariationContext } from '../Providers/VariationProvider'
import { ContactUpdatedContext } from '../Providers/ContactUpdatedProvider'
import { useRouter, useTranslation } from '@nic/commons'
import { Darwin, IFetchState, NicScope } from '@nic/nic-api/types'
import { DarwinHelpers, getRequestContactUpdate } from '@nic/nic-api'
import { getRequestCandidateExtendedContactHelper } from '@nic/nic-api/build/Reducer/Darwin/Contact'
import { IRequest } from '@nic/nic-api/types/Darwin/types'
import { buildDarwinDocumentUploadFileDataNode, IDocumentUploadFile } from '../Documents/utils'
import { DocumentUploadFile } from '@nic/ui-antd'
import { useScopeInfo } from '../Hooks/useScopeInfo'
import { RequestTabType } from './index'

/**
 * In questa sezione si aggiungono eventuali processi di inizializzazione dei vari providers/contesti
 *  *
 * @constructor
 */
export function useRequestView() {
  const { request, hasExpirationDays } = useRequestInfo()
  const { scope, requestId } = useScopeInfo()
  const router = useRouter()
  const t = useTranslation()
  const { stateReqById, stateReqDocuments } = useContext(RequestContext)
  const { dispatch: dispatchVariation } = useContext(VariationContext)
  const { dispatch: dispatchContactUpd } = useContext(ContactUpdatedContext)
  const { stateReqDomains } = useContext(RequestContext)
  const [activeKeyTab, setActiveKyTab] = React.useState<RequestTabType>('summary')
  const { dispatch: dispatchCandidate, state: stateCandidate } = useContext(
    CandidateRegistrantContext
  )
  const { dispatch: dispatchContactExpDays, state: stateDays } = useContext(
    RequestExpirationDaysContext
  )
  const { data: reqData } = stateReqById
  const { status } = reqData || {}
  const loading = stateCandidate.loading || stateDays.loading

  /**
   * Recupera i dati modificati dal registrar (contactUpdate)
   */
  React.useEffect(() => {
    const fetchContactUpdatedData = async (_dispatch: any, id: number, _scope: NicScope) => {
      getRequestContactUpdate(_dispatch, { id: id }, _scope)
    }

    if (requestId && scope && (status === 'registrarUpdated' || status === 'completed')) {
      fetchContactUpdatedData(dispatchContactUpd, requestId, scope)
    }
  }, [requestId, status, scope, dispatchContactUpd])

  /**
   * Carica i documenti
   */
  React.useEffect(() => {
    loadDocument(stateReqDocuments, stateReqById, dispatchVariation, t)
  }, [stateReqDocuments, stateReqById, dispatchVariation, t])

  /**
   * Recupera i dati del Reference e Candidate
   */
  React.useEffect(() => {
    // Candidate
    if (requestId !== undefined && scope === 'registry') {
      // Non serve pù qui perchè vieni inizializzato altrove
      // getRegistrantData(dispatchCandidate, stateReqById.data?.candidate, scope)
      /** Punto in cui si inizializza il CandidateProvider e context per poi usarlo in giro **/
      getRequestCandidateExtendedContactHelper(dispatchCandidate, requestId)
    }
  }, [scope, requestId, dispatchCandidate])
  //
  /**
   * Opzione per la chiamata a RequestExpirationDays
   */
  React.useEffect(() => {
    hasExpirationDays &&
      DarwinHelpers.getRequestExpirationDays(
        dispatchContactExpDays,
        request?.id as number,
        scope as NicScope
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestId, scope, dispatchContactExpDays, hasExpirationDays])

  /***
   * Gestione dei TABS
   */
  React.useEffect(() => {
    if (router.query.hasOwnProperty('tab') && router.query.tab !== activeKeyTab) {
      console.log('rendere req', activeKeyTab, router.query)
      setActiveKyTab(router.query.tab as RequestTabType)
    }
  }, [activeKeyTab, router.query])

  const handleEditOn = () => {
    dispatchVariation({ type: 'EDIT_ON' })
    // message.success('Proposta di modifica processabile')
    handleOnChangeTab('edit')
  }

  const handleEditOff = () => {
    handleOnChangeTab('summary')
    dispatchVariation({ type: 'EDIT_OFF' })
    // message.info('Proposta di modifica in stato chiusa')
  }

  const handleOnChangeTab = (activeKey: string) => {
    //console.log('handleOnChangeTab', activeKey)
    setActiveKyTab(activeKey as RequestTabType)
    if ((activeKey as RequestTabType) === 'domains' && stateReqDomains.data?.page) {
      //console.log(' stateReqDomains.data?.page', stateReqDomains.data?.page)
      router.replaceHelper({
        page: stateReqDomains.data?.page.number,
        size: stateReqDomains.data?.page.size,
        tab: activeKey,
      })
    } else if ((activeKey as RequestTabType) === 'contacts') {
      // TODO implementare logica che recupera per il sottoTab aperto la paginazione come fatto nel caso di domains
      // al momento le pagine vengono impostate a undefined
      // Es. tab=contact&contactTab=toHold
      router.replaceHelper({
        page: undefined,
        size: undefined,
        tab: activeKey,
      })
    } else {
      router.replaceHelper({ tab: activeKey })
    }
  }

  // console.log(useRequestVisualizer.name, requestId, scope, refId)
  return { loading, handleOnChangeTab, handleEditOn, activeKeyTab, handleEditOff, requestId }
}

/***
 * Carica i documenti (Funzione da rivedere)
 * @param _stateReqDocuments
 * @param _stateReqById
 * @param _dispatchVariation
 * @param t
 */
export function loadDocument(
  _stateReqDocuments: IFetchState<Darwin.Documents, any>,
  _stateReqById: IFetchState<IRequest, any>,
  _dispatchVariation: Dispatch<any>,
  t: (key: string) => string
) {
  // documenti già inseriti e convertiti nel formato giusto
  const uploadedDocs = buildDarwinDocumentUploadFileDataNode(
    t,
    _stateReqDocuments.data?.elements || []
  )

  // documenti obbligatori presenti nella richiesta
  const requiredDocs: IDocumentUploadFile<Darwin.DarwinUploadedFileResponse>[] =
    _stateReqById.data?.requiredDocuments?.documents?.map((type) => {
      return {
        key: type,
        uid: type,
        name: type,
        docType: type,
        fileList: [],
      }
    }) || []

  // Merge dei documenti uplodati e quelli richiesti
  // Array.prototype.push.apply(uploadedDocs, requiredDocs)

  //console.log('requiredDocs', requiredDocs)
  //console.log('uploadedDocuments', uploadedDocs)

  // Contiene la lista di tutti i documenti da mostrare a video controllando i richiesti e aggiungendo i richiesti
  // già inseriti
  const finalDocumentsSeed = requiredDocs.map((rd) => {
    const obj = uploadedDocs.find((data) => data.key === rd.key)
    return {
      key: rd.key,
      children: obj ? obj.children : [],
    } as IDocumentUploadFile<Darwin.DarwinUploadedFileResponse>
  })

  const finalDocList: DocumentUploadFile<Darwin.DarwinUploadedFileResponse>[] =
    finalDocumentsSeed?.map((doc) => {
      return {
        docType: doc.key as Darwin.DocType,
        fileList: (doc.children as any) || [],
      }
    }) || []

  //console.log('finalList', finalDocList)

  if (finalDocList.length > 0) {
    _dispatchVariation({ type: 'INIT_DOCUMENTS', payload: finalDocList })
  } else {
    //console.log('Rimbalzata inizializzazione documenti')
  }
}
