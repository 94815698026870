import React, { useContext } from 'react'
import { AuthContext, AuthProvider } from '@nic/kc-auth'
import { BrowserRouter, Switch } from 'react-router-dom'
import { api } from '@nic/nic-api'
import { AbilityProvider } from '@nic/commons'
import { Routes } from './Routes'
import { Footer } from './Footer'
import { Header } from './Components/Layout/Header'
import { LayoutManager } from './Context/LayoutContext'
import { usersAbilities } from './setupAbilites'
import { StateOrProvinceProvider } from '@nic/ui-antd'
import { NicScope } from '@nic/nic-api/types'
import { SelectUserProvider } from './Components/Providers/SelectedUserContext'

interface AppI {
  conf: string
  locale?: string
}

const App: React.FC<AppI> = (props) => {
  const HeaderMemo = React.memo(Header)
  // console.log('INSPECTION ->', App.name)
  if (props.conf.length > 0) {
    //evita la doppia chiamata delle componenti sotto
    return (
      <AuthProvider keycloakJson={props.conf} onKeycloakInstance={(kc) => api(kc, props.locale)}>
        <AbilityProvider restrictions={usersAbilities} authContext={AuthContext}>
          <BrowserRouter>
            <Switch>
              <LayoutManager
                header={<HeaderMemo title={<span style={{ fontSize: '2.0rem' }}>darwin</span>} />}
                body={<Body />}
                footer={<Footer />}
              />
            </Switch>
          </BrowserRouter>
        </AbilityProvider>
      </AuthProvider>
    )
  }
  return <>Configuration not found</>
}

export default App

/**
 * Inserito questo componente perchè c'è da controllare che il token di KC
 * sia arrivato altrimenti StateOrProvince va in errore 401 perchè troppo veloce
 * e ancora senza token ...anche se in verità dovrebbe già averlo a bordo (da capire perchè
 * viene eseguito prima
 * TODO: C'è da capire come migliorare
 * */
function Body() {
  const kc = useContext(AuthContext)
  return kc?.keycloak?.tokenParsed ? (
    <StateOrProvinceProvider scope={kc.keycloak.realm as NicScope}>
      <SelectUserProvider>
        <Routes />
      </SelectUserProvider>
    </StateOrProvinceProvider>
  ) : (
    <></>
  )
}
