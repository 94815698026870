import { Button, Popover } from 'antd'
import React from 'react'
import { useTranslation } from '@nic/commons'

export function ActionButtonHold(props: { onClick: () => void; disabled?: boolean }) {
  const t = useTranslation()
  return (
    <Button type={'link'} key={'hold'} onClick={props.onClick} disabled={props.disabled}>
      <Popover content={t('actionButtons.hold.tip')}>{t('actionButtons.hold.title')}</Popover>
    </Button>
  )
}
export function ActionButtonDelete(props: { onClick: () => void; disabled?: boolean }) {
  const t = useTranslation()
  return (
    <Button type={'link'} key={'delete'} onClick={props.onClick} disabled={props.disabled}>
      <Popover content={t('actionButtons.delete.tip')}>{t('actionButtons.delete.title')}</Popover>
    </Button>
  )
}

export function ActionButtonUpdate(props: { onClick: () => void; disabled?: boolean }) {
  const t = useTranslation()
  return (
    <Button type={'link'} key={'update'} onClick={props.onClick} disabled={props.disabled}>
      <Popover content={t('actionButtons.update.tip')}>{t('actionButtons.update.title')}</Popover>
    </Button>
  )
}
