import { IReferenceContact } from '../../../../nic-react-monorepo/packages/nic-api/types/Darwin/types'
import React from 'react'
import { List } from 'antd'

export function RequestReferenceItem(item: IReferenceContact) {
  // let acronym =
  //   item?.org &&
  //   item?.org
  //     .split(/\s/)
  //     .reduce((response, word) => (response += word.slice(0, 1)), "");

  const handlePrintRegistrantInfo = () => {
    let res: React.ReactNode[] = []
    if (item?.regcode) {
      res.push(
        <div>
          <b>RegCode:</b> {item.regcode}
        </div>
      )
    }
    if (item?.registrarContactId) {
      res.push(
        <div>
          <b>ContactId:</b> {item.registrarContactId}
        </div>
      )
    }
    return res.map((e) => e)
  }

  return (
    <List.Item>
      <List.Item.Meta
        // avatar={
        //   <Avatar
        //     style={{
        //       color: "#f56a00",
        //       backgroundColor: "#fde3cf",
        //     }}
        //   >
        //     {acronym}
        //   </Avatar>
        // }
        title={item?.org}
        description={handlePrintRegistrantInfo()}
      />
    </List.Item>
  )
}
