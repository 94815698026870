import { Darwin, Domains } from "@nic/nic-api/types";
import React, { useContext, useState } from "react";
import { useTranslation } from "@nic/commons";
import { PaperLayout } from "@nic/ui-comps";
import {Button, Divider, notification, Space} from "antd";
import { CheckOutlined, DislikeOutlined } from "@ant-design/icons";
import { RequestContext } from "../../Contexts/RequestContext";
import { PaperTitle } from "../../Layout/PaperTitle";
import { VariationProposalRegistryAcceptModal } from "../../VariationProposal/Modals/VariationProposalRegistryAcceptModal";
import { VariationProposalRegistryRejectModal } from "../../VariationProposal/Modals/VariationProposalRegistryRejectModal";
import { RequiredDocumentsWarning } from "../../Documents/RequiredDocumentsWarning/RequiredDocumentsWarning";

export const VariationProposalAcceptanceCard = (props: {
  contacts?: Darwin.Contacts;
  anomalies?: Darwin.Anomalies;
  domains?: Domains.Domains;
  /** disabilità il solo bottone procedi **/
  edit?: boolean;
  /** gestisce l'evento del click del bottone Procedi **/
  onEdit?: (edit: boolean) => void;
  /** diabilità tutti i bottoni **/
  disabled?: boolean;
  disabledButtonReject?: boolean;
  disabledButtonDelete?: boolean;
}) => {
  const [showModalRegistryReject, setShowModalRegistryReject] = useState(false);
  const [showModalRegistryAccept, setShowModalRegistryAccept] = useState(false);

  const { reload } = useContext(RequestContext);
  const t = useTranslation();

  const handleOnReject = () => {
    setShowModalRegistryReject(true);
  };

  const handleOnAccept = () => {
    setShowModalRegistryAccept(true);
  };

  const handleOnRejectOk = () => {
    reload && reload();
    setShowModalRegistryReject(false);
    notification.success({
      message: t("cardVariationProposalRegistry.msgRejectedRequest"),
    });
  };

  const handleOnAcceptOk = () => {
    reload && reload();
    setShowModalRegistryAccept(false);
    notification.success({
      message: t("cardVariationProposalRegistry.msgAcceptedRequest"),
    });
  };

  return (
    <PaperLayout asCard={false} width={"100%"} style={{flex: 1}}>
      <PaperTitle showIcon title={t("cardVariationProposalRegistry.title")} />
      <Divider/>
      <div style={{ width: "100%",textAlign: 'center', padding:'20%'}}>
        <Space direction="vertical" align={"center"}>
          <RequiredDocumentsWarning>
            {({ hasWarning }) => {
              const disableAcceptButton =
                !!props.edit || props.disabled || hasWarning;
              const disableRejectButton =
                props.disabled || props.disabledButtonReject;
              return (
                <Space direction="horizontal" style={{ marginTop: 40 }}>
                  <Button
                    style={{
                      backgroundColor: disableAcceptButton ? "" : "#52c41a",
                      color: disableAcceptButton ? "" : "white",
                    }}
                    shape="round"
                    size={"large"}
                    icon={<CheckOutlined />}
                    disabled={disableAcceptButton}
                    onClick={handleOnAccept}
                  >
                    {t("Accept")}
                  </Button>

                  <Button
                    type="primary"
                    danger
                    shape="round"
                    size={"large"}
                    icon={<DislikeOutlined />}
                    disabled={disableRejectButton}
                    onClick={handleOnReject}
                  >
                    {t("Reject")}
                  </Button>
                </Space>
              );
            }}
          </RequiredDocumentsWarning>
        </Space>
      </div>
      <VariationProposalRegistryAcceptModal
        setShowModalAccept={setShowModalRegistryAccept}
        showModalAccept={showModalRegistryAccept}
        onOk={handleOnAcceptOk}
      />
      <VariationProposalRegistryRejectModal
        setShowModalReject={setShowModalRegistryReject}
        showModalReject={showModalRegistryReject}
        onOk={handleOnRejectOk}
      />
    </PaperLayout>
  );
};
