import React from 'react'
import { AuthContext } from '@nic/kc-auth'
import { useRouter } from '@nic/commons'
import { NicScope } from '@nic/nic-api/types'

/***
 * Ritorna le informazioni delle richiesta se già caricata
 */
export function useScopeInfo() {
  const { keycloak } = React.useContext(AuthContext)
  const router = useRouter()
  const { id: requestId } = router.match.params as { id: number }
  const scope = keycloak?.realm as NicScope
  return { scope, requestId }
}
