import { Darwin } from '../../../../nic-react-monorepo/packages/nic-api/types'
import { Badge, Popover } from 'antd'
import React from 'react'
import StatusView from './StatusView'
import { ExclamationCircleOutlined } from '@ant-design/icons'

/**
 * Filtra in lista eventi per il tipo 'registrarUpdateRejected'
 * @param value
 */
export function countRejectedRequests(value: Darwin.Event[]) {
  return value.filter((v) => v.type === 'registrarUpdateRejected').length
}

/**
 * Stampa un etichetta con 'registrarUpdateRejected' mostrando anche badge che conta quante volte è stata rifiutata
 * @param p
 * @constructor
 */
export function RejectedRequestBadgedLabel(p: { count: number }) {
  if (p.count > 0) {
    return (
      <Popover content={''}>
        <Badge
        //count={p.count}
        >
          <StatusView status={'toBeReworked'} icon={<ExclamationCircleOutlined />} />
        </Badge>
      </Popover>
    )
  } else {
    return <></>
  }
}
