import axios, { AxiosError } from 'axios'

export function printAxiosError(e: any) {
  if (e) {
    if (axios.isAxiosError(e)) {
      const axiosError = e as AxiosError
      return axiosError.response?.data?.message || e.message
    }
    return e.message
  } else return undefined
}
