import { PaperLayout } from '@nic/ui-comps'
import React from 'react'
import { Divider } from 'antd'

/**
 * Box che mostra una Card , un eventuale titolo e poi il children
 * @param props
 * @constructor
 */
export function PaperLayoutTitled(
  props: React.PropsWithChildren<{
    title?: string
    width?: string
    style?: React.CSSProperties
    className?: string
  }>
) {
  return (
    <PaperLayout asCard width={props.width} style={props.style} className={props.className}>
      {props.title && <b>{props.title}</b>}
      {props.title && <Divider type={'horizontal'} />}
      {props.children}
    </PaperLayout>
  )
}
