import { createCtx } from '@nic/commons'
import { RequestTabType } from '../RequestView'

interface RequestVisualizerInterface {
  tab: RequestTabType
}

const initialState: RequestVisualizerInterface = {
  tab: 'summary',
}

type Action = { type: 'CHANGE_TAB'; payload: RequestTabType }

type AppState = typeof initialState

function requestVisualizerReducer(state: AppState, action: Action): AppState {
  console.debug('requestVisualizerReducer', action)
  switch (action.type) {
    case 'CHANGE_TAB':
      return { ...state, tab: action.payload }
    default:
      throw new Error()
  }
}

const [ctx, Provider] = createCtx(requestVisualizerReducer, initialState)
export const RequestVisualizerContext = ctx
export const RequestVisualizerProvider = Provider
export const RequestVisualizerConsumer = ctx.Consumer
