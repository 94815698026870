import React from 'react'
import { Menu, Dropdown, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { LanguageContext } from '@nic/commons'
import { setAllAxiosInstancesAcceptLanguageHeader } from '@nic/nic-api'

interface LanguageSwitcherI {
  color?: string
}

export const LanguageSwitcher: React.FC<LanguageSwitcherI> = (props) => {
  const { locale, updateLocale } = React.useContext(LanguageContext)
  const clr = props.color ?? 'white'

  const handleChangeLocale = (element: any) => {
    updateLocale(element.key)
    setAllAxiosInstancesAcceptLanguageHeader(locale)
  }

  const menu = (
    <Menu onClick={handleChangeLocale}>
      <Menu.Item key={'it'}>IT</Menu.Item>
      <Menu.Item key={'en'}>EN</Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu}>
      <Button type={'primary'} ghost style={{ color: `${clr}`, borderColor: `${clr}` }}>
        <span>{locale.toUpperCase()}</span> <DownOutlined />
      </Button>
    </Dropdown>
  )
}
